import { Browser } from "@capacitor/browser";
import { PUBLIC_URL } from "@/configs/domains";

export default async (path, args = {}) => {
  let user = localStorage.getItem("user");
  
  let is_complete_url =
    path.startsWith("http://") || path.startsWith("https://");
  let url = is_complete_url ? path : `${PUBLIC_URL + path}`;

  try {
    user = JSON.parse(user);
  } catch (error) {
    user = null;
  }

  const query_params = [];
  if (user && user.id) {
    query_params.push(`user_id=${encodeURIComponent(user.id)}`);
  }

  for (const [key, value] of Object.entries(args)) {
    query_params.push(
      `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );
  }

  if (query_params.length > 0) {
    url += `${url.includes("?") ? "&" : "?"}${query_params.join("&")}`;
  }

  await Browser.open({ url });
};
