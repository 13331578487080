<template>
  <component-modal
    id="modal_moods"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="[]"
    :clean="false"
    :dark="false"
    :onopened="init"
    :permanent_mode="true"
    :with_close_button="false"
  >
    <div class="background p-4">
      <div class="content-modal">
        <div v-if="mode == 'advance' && !finished && !loading" class="blocks">
          <div class="block-top">
            <div class="block-header" v-if="!$route.query.from">
              <h4 class="title">¿Que estas sintiendo?</h4>
              <p class="description">
                Nos encantaría entender mejor cómo te encuentras ahora mismo. Así podremos
                adaptar el contenido para que te sea más útil y de apoyo en este momento.
              </p>
            </div>

            <div
              class="block-header"
              v-if="$route.query.from && $route.query.from == 'onboarding'"
            >
              <h4 class="title">¿Preparados?</h4>
              <p class="description">
                Comencemos cargando tus síntomas para poder afinar al 100% los resultados
                para ti!
              </p>
            </div>
          </div>
          <div class="block-center">
            <div class="block-title">
              <div class="emoji">
                <emoji size="72px" :file="symptom.emoji" />
              </div>
              <div class="category">{{ symptom.feeling }}</div>
              <div class="title">{{ symptom.question }}</div>
              <div class="description">
                {{ symptom.description }}
              </div>
            </div>
          </div>
          <div class="block-bottom">
            <div class="block-footer">
              <div class="not" @click="save_symptom({ answer: 'not', symptom })">
                <i class="fi fi-rr-cross"></i>
              </div>

              <div class="yes" @click="save_symptom({ answer: 'yes', symptom })">
                <i class="fi fi-rr-check"></i>
              </div>
            </div>

            <div class="block-progress d-fles flex-column dark">
              <div class="progress-bar">
                <div :style="{ width: progress + '%' }" class="progress"></div>
              </div>
              <div
                class="text-progress d-flex w-100 flex-column justify-content-center align-items-center"
              >
                <div v-if="symptoms.length >= get_percentage_based_on_symptoms(15)">
                  <span
                    class="text-muted mt-1 text-center d-flex justify-content-center align-items-center"
                    v-if="questions_answered == 0"
                  >
                    Empezemos!
                    <emoji file="relieved_face.png" />
                  </span>

                  <span
                    class="text-muted mt-1 text-center d-flex justify-content-center align-items-center"
                    v-if="
                      questions_answered > 0 &&
                      questions_answered < get_percentage_based_on_symptoms(5)
                    "
                  >
                    Aun te falta! vamos
                    <emoji file="smiling_face_with_halo.png" />
                  </span>

                  <span
                    class="text-muted mt-1 text-center d-flex justify-content-center align-items-center"
                    v-if="
                      questions_answered >= get_percentage_based_on_symptoms(5) &&
                      questions_answered < get_percentage_based_on_symptoms(10)
                    "
                  >
                    Aun falta algunas
                    <emoji file="hushed_face.png" />
                  </span>

                  <span
                    class="text-muted mt-1 text-center d-flex justify-content-center align-items-center gap-2"
                    v-if="questions_answered >= get_percentage_based_on_symptoms(10)"
                  >
                    Ya es suficiente
                    <emoji file="flexed_biceps.png" />
                  </span>
                </div>

                <div class="d-flex">
                  <b>
                    ({{ questions_answered }}/{{
                      questions_answered < get_percentage_based_on_symptoms(15)
                        ? get_percentage_based_on_symptoms(15)
                        : get_percentage_based_on_symptoms(100)
                    }})
                  </b>
                </div>
              </div>
            </div>

            <div class="block-notes">
              <p>
                <span class="text-bold"
                  >Con solo 7 preguntas ya podremos ponernos en marcha</span
                >, pero si decides responder todas ({{
                  get_percentage_based_on_symptoms(100)
                }}), ¡tus resultados serán aún más afinados y personalizados!
              </p>
            </div>

            <div class="block-later d-flex w-100 justify-content-end">
              <component-button
                :classes="['btn', 'btn-block', 'btn-small', 'btn-light', 'transparent']"
                @click="toggle()"
              >
                <i class="fi fi-rr-time-twenty-four"></i>
                <span>Continuar despues</span>
              </component-button>
            </div>
          </div>
        </div>

        <div
          v-if="mode == 'simple' && !finished && !loading"
          class="d-flex gap-2 flex-column"
        >
          <div v-if="!$route.query.from">
            <h4 class="title">¿Que estas sintiendo?</h4>
            <p class="description text-muted m-0">
              Nos encantaría entender mejor cómo te encuentras ahora mismo. Así podremos
              adaptar el contenido para que te sea más útil y de apoyo en este momento.
            </p>
          </div>

          <div v-if="$route.query.from && $route.query.from == 'onboarding'">
            <h4 class="title">¿Preparados?</h4>
            <p class="description text-muted m-0">
              Comencemos cargando tus síntomas para poder afinar al 100% los resultados
              para ti!
            </p>
          </div>

          <div class="categories mt-2" v-if="unselected_categories.length > 0">
            <div
              class="category"
              v-for="category in unselected_categories"
              @click="
                () => {
                  category.selected = true;
                  save_symptom({ category });
                }
              "
            >
              <div class="left">
                <div class="emoji-block">
                  <emoji size="32px" :file="category.emoji" />
                </div>

                <div class="content">
                  <div class="title">{{ category.name }}</div>
                  <div class="description text-muted">
                    {{ category.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="alert light w-100 m-0 mt-2" v-if="unselected_categories.length == 0">
            Ya terminaste por aquí, puedes continuar ;)
          </p>

          <div class="buttons d-flex gap-2 justify-content-between mt-2">
            <component-button
              :classes="['btn', 'btn-transparent', 'btn-nopadding', 'text-dark']"
              @click="reset()"
            >
              <i class="fi fi-rr-refresh"></i>
              <span>Resetear</span>
            </component-button>

            <component-button
              :disabled="!(open_categories > 3)"
              :classes="['btn', 'btn-transparent', 'btn-nopadding', 'text-dark']"
              @click="next_page()"
            >
              <i class="fi fi-rr-arrow-right"></i>
              <span>Siguiente </span>
            </component-button>
          </div>

          <p class="text-muted mb-0 mt-2">
            Selecciona <b>3 a 10 categorías</b> donde te sientas identificado/a
            <b class="badge dark">llevas {{ selected_categories.length }}</b>
          </p>

          <div class="buttons d-flex gap-2 mt-2 justify-content-between">
            <component-button
              :class="['d-flex', 'w-100']"
              :classes="['btn', 'btn-block', 'btn-light']"
              @click="this.mode = 'advance'"
            >
              <i class="fi fi-rr-magic-wand"></i>
              <span>Avanzado</span>
            </component-button>

            <component-button
              :class="['d-flex', 'w-100']"
              :classes="['btn', 'btn-transparent', 'text-dark']"
              @click="toggle()"
            >
              <i class="fi fi-rr-time-twenty-four"></i>
              <span>Continuar luego</span>
            </component-button>
          </div>
        </div>

        <div v-if="!loading && finished" class="blocks finished">
          <div class="block-header">
            <h4 class="title">
              ¡Perfecto!
              <emoji size="38px" file="right_facing_fist.png" />
            </h4>
            <p class="description">
              Parece que ya nos has contado todos los síntomas que estás experimentando.
              Esto nos ayudará a ofrecerte contenido mucho más adaptado a ti. ¡Gracias por
              compartir!
            </p>

            <div class="buttons d-flex flex-column gap-2 mt-3">
              <component-button
                :classes="['btn', 'btn-block', 'btn-primary']"
                @click="toggle()"
              >
                <i class="fi fi-rr-stars"></i>
                <span>Continua explorando</span>
              </component-button>

              <component-button
                :classes="['btn', 'btn-block', 'btn-light', 'transparent']"
                @click="open_browser(`/feedback`)"
              >
                <i class="fi fi-rr-comment-quote"></i>
                <span>Ayudanos a mejorar</span>
              </component-button>
            </div>
          </div>
        </div>

        <div v-if="loading" class="blocks">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

// Methods
import get_symptoms from "./methods/get_symptoms";
import get_symptoms_categories from "./methods/get_symptoms_categories";
import save_symptom from "./methods/save_symptom";
import toggle from "./methods/toggle";

export default defineComponent({
  data() {
    return {
      loading: true,
      questions_answered: 0,
      categories: [],
      symptoms: [],
      _symptoms: [],
      mode: "advance",
    };
  },

  computed: {
    finished() {
      return (
        this.symptoms.length == 0 ||
        this.questions_answered >= this._symptoms.length ||
        this.selected_categories.length >= 3
      );
    },

    symptom() {
      return this.symptoms.length > 0 ? this.symptoms[0] : null;
    },

    progress() {
      return (this.questions_answered / this._symptoms.length) * 100;
    },

    open_categories() {
      return (
        this.categories.length - this.categories.filter((x) => x.ignore === true).length
      );
    },

    unselected_categories() {
      return this.categories.filter((x) => !x.selected && !x.ignore).splice(0, 3);
    },

    selected_categories() {
      return this.categories.filter((x) => x.selected === true);
    },
  },

  methods: {
    toggle,
    get_symptoms,
    get_symptoms_categories,
    save_symptom,

    get_percentage_based_on_symptoms(x) {
      return Math.round(this._symptoms.length * (x / 100));
    },

    init() {
      this.loading = true;
      this.questions_answered = 0;
      this.symptoms = [];

      this.get_symptoms();
      this.get_symptoms_categories();
    },

    reset() {
      this.categories.forEach((x) => {
        x.ignore = false;
        x.selected = false;
      });
    },

    next_page() {
      this.unselected_categories.forEach((x) => {
        x.ignore = true;
      });
    },
  },
});
</script>
