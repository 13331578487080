<template>
  <div class="paywall paywall_3 d-flex flex-column gap-2">
    <div
      class="block title d-flex flex-column gap-1 justify-content-center align-items-center"
    >
      <emoji size="48px" file="seedling.png" />
      <h4 class="m-0 mt-2">Continua <span class="badge">adelante</span></h4>
      <p class="m-0 description">Con la asistencia de <b>psicólogos</b> certificados</p>
    </div>

    <component-problems />
    <component-social_proof />

    <template v-if="!subscription">
      <p class="text-dark text-center m-0">
        Chatea <b>con nuestro equipo de psicólogos,</b> para que tengas recomendaciones,
        trucos y claves para poder superar tu situación.
      </p>
    </template>

    <template class="block features" v-else>
      <p class="text-dark text-center m-0">
        <b>Con tu psicólogo personal,</b> quiere decir que estás listx para superar
        cualquier cosa, inicia sesiones 1-1 semanalmente para que avancemos mucho más
        rápido.
      </p>
    </template>

    <!-- Payment process -->
    <component-go_payment />
  </div>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import go_payment from "./components/gopayment.vue";
import problems from "./components/problems.vue";
import social_proof from "./components/social_proof.vue";

export default defineComponent({
  components: {
    "component-go_payment": go_payment,
    "component-problems": problems,
    "component-social_proof": social_proof,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription() {
      return this.$store?.state?.auth?.user?.subscription;
    },
  },
});
</script>
