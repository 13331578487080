export default function () {
  if (!this.is_playing || this.is_seeking) {
    return;
  }

  this.is_seeking = true;

  let current = this.seconds_played;
  let recommended_seconds = parseInt(this.duration * (15 / 100));
  let target = current + recommended_seconds;

  if (target > this.duration) {
    target = this.duration - 1;
  }

  this.player.seek(target);

  const interval = setInterval(() => {
    if (this.is_playing) {
      this.is_seeking = false;
      clearInterval(interval);
    }
  }, 500);
}
