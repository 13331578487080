<template>
  <div class="container p-0">
    <div class="row">
      <div class="col-12 d-flex flex-column gap-2">
        <div class="block-title">
          <h4 class="m-0 d-flex align-items-center gap-2">
            Hablemos
            <div v-if="loading" class="loader-simple dark"></div>
          </h4>
          <p class="description m-0 mt-1 text-muted">
            <b>Resuelve</b> las dudas que tengas escribiendonos.
          </p>
        </div>

        <div class="d-flex flex-column gap-2" v-if="!loading && tickets.length === 0">
          <div class="alert light m-0">
            No tenemos mensajes para mostrar aún, envía tu primer mensaje y estaremos
            atentos a responderte lo antes posible.
          </div>
        </div>

        <div class="d-flex flex-column gap-2" v-if="tickets.length > 0">
          <div class="d-flex gap-2 block-users">
            <div
              class="avatar"
              v-for="participant in participants"
              :style="{ backgroundImage: `url(${PUBLIC_URL}/${participant.avatar})` }"
            ></div>
          </div>

          <hr class="hr m-0" />

          <div class="block-chat">
            <div class="message" v-for="ticket in tickets" :key="ticket.id">
              <div
                v-if="ticket.from"
                class="avatar"
                :style="{ backgroundImage: `url(${PUBLIC_URL}/${ticket.from.avatar})` }"
              ></div>

              <div class="message">
                <div class="name" v-if="ticket.from">
                  <span>{{ ticket.from.name }}</span>

                  <span class="badge dark small" v-if="ticket.from.role == 1"
                    >psciologo</span
                  >

                  <span class="badge dark small" v-if="ticket.from.role == 2"
                    >administrador</span
                  >

                  <i
                    class="fi fi-rr-user-robot text-muted"
                    v-if="ticket.from.role == 7"
                  ></i>
                </div>

                <div class="d-block box bordered p-2">
                  <template v-for="x in ticket.message">
                    <!-- Normal text -->
                    <div
                      class="d-inline"
                      v-if="x.type === 'text'"
                      v-html="x.content"
                    ></div>

                    <!-- Badge -->
                    <div v-if="x.type === 'component-badge'" class="d-inline">
                      <component-badge
                        icon="fi fi-rr-link-alt"
                        :link="x.path"
                        :modal="x.modal"
                        :onclick="x.onclick"
                        :text="x.text || 'Enlace'"
                        :classes="[
                          'dark',
                          'text-dark',
                          'small',
                          'fs-small',
                          'py-1',
                          'px-2',
                        ]"
                      />
                    </div>
                  </template>
                </div>

                <div class="ticket-footer d-flex gap-2 align-items-center">
                  <i
                    class="fi fi-rr-check-double"
                    :class="[ticket.psychologist_read ? 'text-success' : 'text-muted']"
                  ></i>

                  <div class="date text-muted">
                    Hace {{ format_human_readable_date(ticket.created_at) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column gap-2">
          <p v-if="writing" class="m-0 text-muted text-italic">
            <span class="text-dark fw-semibold">Sofia</span> está escribiendo...
          </p>

          <component-input
            type="textarea"
            ref="input_message"
            placeholder="Desde que los problemas de pareja comenzaron, mi mundo se ha ido desmoronando. Cada discusión, cada malentendido, se siente como un peso que no puedo quitarme de encima. La ansiedad me consume, apretando mi pecho con cada pensamiento, cada recuerdo de lo que solíamos ser. Vivo en un constante estado de alerta, esperando el próximo desencuentro, el próximo conflicto. No veo salida, no encuentro solución. Es como si estuviera atrapado en un bucle sin fin de preocupación y miedo, incapaz de avanzar o retroceder."
            :onupdate="(x) => (message = x)"
            icon="fi fi-rr-paper-plane"
            :onclickicon="() => send_message()"
          />
        </div>

        <div class="text-muted" v-if="route">
          <b>Este chat está relacionado con el canal: </b>
          <span class="badge small dark">{{ route.title }}</span> si tienes dudas más
          generales o necesitas soporte o dirección, te invitamos a contactarnos
          <span @click="open_browser(`/contact`)">aquí</span> O si quieres ver todos tus
          canales haz clic <span @click="go_back()">aquí</span>.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PUBLIC_URL } from "@/configs/domains";
import route_replacer from "@/helpers/showdown/route_replacer";
import _ from "lodash";

export default {
  setup() {
    return {
      PUBLIC_URL,
      route_replacer,
      fake_indicator: "",
    };
  },

  props: ["check_and_move_to_module"],

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    route_id() {
      return this.$route?.query?.route_id;
    },
  },

  watch: {
    scroll_to: {
      handler: _.debounce(function () {
        const chat_block = document.querySelector(".block-chat");
        if (chat_block) {
          chat_block.scrollTo({
            top: chat_block.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 1000),
    },
  },

  data() {
    return {
      loading: true,
      route: null,
      channel: null,
      tickets: [],
      participants: [],
      message: "",
      locked: false,
      scroll_to: null,
      min_length_message: 3,
      writing: false,
      msg: "",
      apps: [],
      interval: null,
    };
  },

  mounted() {
    this.get_tickets();

    this.interval = setInterval(() => {
      this.get_tickets();
    }, 1000 * 30);
  },

  methods: {
    go_back() {
      this.check_and_move_to_module && this.check_and_move_to_module("channels");
    },

    async send_message() {
      if (this.loading) {
        this.alert.info("Espera un momento, estamos cargando todo ;)");
        return;
      }

      if (this.message.length < this.min_length_message) {
        this.alert.info(
          "El mensaje debe tener más de " + this.min_length_message + " caracteres."
        );
        this.loading = false;
        return;
      }

      if (this.locked) {
        this.loading = false;
        this.alert.info("Espera unos segundos para poder enviar tu siguiente mensaje");
        return;
      }

      this.loading = true;

      const ticket = new this.controllers.Ticket();

      const { status, response } = await ticket.store({
        channel_id: this.channel.id,
        route_id: this.route_id,
        message: this.message,
      });

      if (status != "SUCCESS") {
        if (response.errors) {
          Object.keys(response.errors).forEach((input) => {
            this.alert.info(response.errors[input][0]);
          });

          this.loading = false;
          return;
        }

        if (response.message) {
          this.alert.info(response.message);
          this.loading = false;
          return;
        }

        this.alert.info("Hubo un error al enviar el mensaje.");
        this.loading = false;
        return;
      }

      if (response.message) {
        this.alert.info(response.message);
      }

      this.message = "";

      try {
        this.$refs.input_message.value = "";
        this.$refs.input_message.$refs.textarea.style.height = "auto";
      } catch (e) {}

      this.loading = false;
      this.locked = true;

      if (this.route_id == "9629e287-5ae6-4daf-b27a-000000000000") {
        setTimeout(() => {
          this.writing = true;
        }, 1000 * 3);
      }

      setTimeout(() => {
        this.locked = false;
      }, 1000 * 5);

      setTimeout(() => {
        this.writing = false;
      }, 1000 * 30);

      this.get_tickets();
    },

    async get_tickets() {
      this.loading = true;
      const ticket = new this.controllers.Ticket();

      const { status, response } = await ticket.tickets({
        route_id: this.route_id,
        user_id: this.user.id,
        get_tickets_after_id:
          this.tickets.length > 0 ? this.tickets[this.tickets.length - 1].id : null,
      });

      if (status != "SUCCESS") {
        this.alert.error("Hubo un error al cargar los mensajes, abre y cierra la App.");
        this.loading = false;
        return;
      }

      if (response.tickets && response.tickets.length > 0) {
        this.tickets = this.tickets.concat(this.parse_tickets(response.tickets) || []);
      }

      if (response.participants) {
        this.participants = response.participants || [];
      }

      if (response.route) {
        this.route = response.route;
      }

      if (response.channel) {
        this.channel = response.channel;
      }

      if (response.tickets && response.tickets.length > 0) {
        const last = response.tickets[response.tickets.length - 1];

        if (last.from.name == "Sofia") {
          this.writing = false;
        }
      }

      this.loading = false;
      this.scroll_to = new Date().getTime();
    },

    parse_tickets(tickets) {
      let response = [];

      tickets.forEach((ticket) => {
        const original_message = ticket.message;
        const parts = [];
        let current_text = "";

        original_message.split(/(\s+)/).forEach((word) => {
          const route_match = word.match(/route:([0-9a-fA-F\-]+)/g);
          const go_match = word.match(/\[go:(\/[^\]]+)\]/);
          const go_link_match = word.match(/\[([^\]]+)\]\((go:[^\)]+)\)/);
          const modal_match = word.match(/\[modal:([^\]]+)\]/);
          const link_match = word.match(/\[([^\]]+)\]\((http[^\)]+)\)/);
          const email_match = word.match(/\[([^\]]+)\]\((mailto:[^\)]+)\)/);

          if (route_match) {
            if (current_text) {
              parts.push({ type: "text", content: current_text });
              current_text = "";
            }

            let route_id = null;

            try {
              route_id = route_match[0].split(":")[1];
            } catch (e) {}

            parts.push({ type: "component-cover", id: route_id });
          } else if (email_match) {
            if (current_text) {
              parts.push({ type: "text", content: current_text });
              current_text = "";
            }

            const text = email_match[1];
            const email = email_match[2];

            parts.push({
              type: "component-badge",
              text: text,
              onclick: () => {
                this.alert.info("Escribenos a: " + email.replace("mailto:", ""));
                window.open(email, "_blank");
              },
            });
          } else if (go_match) {
            if (current_text) {
              parts.push({ type: "text", content: current_text });
              current_text = "";
            }

            const path = go_match[1];

            parts.push({
              type: "component-badge",
              path: path,
              onclick: () => {
                this.$store.dispatch("modals/toggle", {
                  id: "modal_tickets",
                  force: "close",
                });
              },
            });
          } else if (go_link_match) {
            if (current_text) {
              parts.push({ type: "text", content: current_text });
              current_text = "";
            }

            const text = go_link_match[1];
            const path = go_link_match[2].substring(3);

            parts.push({
              type: "component-badge",
              text: text,
              path: path,
              onclick: () => {
                this.$store.dispatch("modals/toggle", {
                  id: "modal_tickets",
                  force: "close",
                });
              },
            });
          } else if (modal_match) {
            if (current_text) {
              parts.push({ type: "text", content: current_text });
              current_text = "";
            }

            const modal = modal_match[1];

            parts.push({ type: "component-badge", modal: modal });
          } else if (link_match) {
            if (current_text) {
              parts.push({ type: "text", content: current_text });
              current_text = "";
            }

            const text = link_match[1];
            const url = link_match[2];

            parts.push({
              type: "component-badge",
              text: text,
              onclick: () => {
                this.open_browser(url);
              },
            });
          } else {
            current_text += word;
          }
        });

        if (current_text) {
          current_text = current_text
            .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
            .replace(/\*(.*?)\*/g, "<b>$1</b>");

          parts.push({ type: "text", content: current_text });
        }

        response.push({
          ...ticket,
          message: parts,
        });
      });

      return response;
    },
  },

  unmounted() {
    clearInterval(this.interval);
  },
};
</script>
