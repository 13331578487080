export default async function ({ page = 1, limit = 7, callback = () => {} }) {
  const alert = this.alert;
  const route = new this.controllers.Route();

  this.loading = true;

  const { status, response } = await route.recommended({
    page,
    limit,
    mode: "suggestions",
    anonymous_user_id: this.id,
  });

  if (status != "SUCCESS" && response.message) {
    alert.error(response.message);
  }

  if (response.routes && response.routes.length > 0) {
    this.routes = response.routes;
  }

  setTimeout(() => {
    callback(response);
    this.loading = false;
  }, 1000);
}
