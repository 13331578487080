<template>
  <div
    class="block d-flex flex-column justify-content-center align-items-center need-subscription">
    <h4 class="m-0 text-muted-light">
      No pares...
      <emoji
        size="38px"
        file="flexed_biceps.png" />
    </h4>

    <p class="m-0 mt-2 text-muted text-center">
      ¡Hola! ya tenemos la siguiente ruta para ti: <b  class="text-muted-light">{{ route.title }}</b
      >...
      <b class="text-muted-light"
        >¿Qué tal si consideras adquirir una suscripción?</b
      >
      Podrás seguir disfrutando de todos los momentos especiales que tenemos
      preparados para ti.
    </p>

    <div class="d-flex w-100 mt-3 justify-content-end">
      <component-button
        :classes="[
          'btn',
          'btn-block',
          'btn-transparent',
          'btn-dark',
          'btn-nopadding',
        ]"
        @click="open_modal()">
        <i class="fi fi-rr-bolt"></i>
        <span>Suscribete!</span>
      </component-button>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["route"],

    methods: {
      open_modal() {
        this.$store.dispatch("modals/toggle", {
          id: "modal_subscription",
          callbacks: {
            onclose: () => {
              this.$store.dispatch("template/update_daemons", {
                index_continue: new Date(),
              });
            },
          },
        });
      },
    },
  };
</script>
