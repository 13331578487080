import moment from "moment-timezone";

export default async ({
  vm,
  form,
  email = null,
  password = null,
  name = null,
  google_id = null,
  google_image = null,
  apple_identity_token = null,
  anonymous_user_id,
  save_on_sendpulse,
  autologin,
}) => {
  const user = new vm.controllers.User();
  const timezone = moment.tz.guess();

  form.loading = true;

  const { status, response } = await user.store({
    email,
    password,
    name,
    anonymous_user_id,
    save_on_sendpulse,
    google_id,
    google_image,
    apple_identity_token,
    autologin,
    timezone,
  });

  Object.keys(form.inputs).forEach((key) => {
    form.inputs[key].error = null;
  });

  if (status == "SERVER_ERROR" || status == "ERROR") {
    if (response.errors) {
      Object.keys(response.errors).forEach((input) => {
        if (form.inputs[input]) {
          form.inputs[input].error = response.errors[input][0];

          if (google_id || apple_identity_token) {
            vm.alert.error(response.errors[input][0]);
          }
        }
      });
    }

    if (response.message) {
      form.error = response.message;

      if (google_id || apple_identity_token) {
        vm.alert.error(response.message);
      }
    }

    form.loading = false;
    return null;
  }

  form.loading = false;
  return response;
};
