import { Capacitor } from "@capacitor/core";

export default () => {
  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === "android") {
      return "android";
    } else if (Capacitor.getPlatform() === "ios") {
      return "ios";
    }
  }

  return "web";
};
