<template>
  <div class="content gap-2">
    <emoji
      size="72px"
      file="face_with_monocle.png" />

    <div class="d-flex flex-column justify-content-center align-items-center gap-0 w-100">
      <h3 class="title m-0">¿Comenzamos?</h3>

      <p class="subtitle m-0 text-muted">
        <b>Descubre el plan que tenemos para ti</b> y le digas "adios" a la
        ansiedad.
      </p>
    </div>

    <div class="d-flex gap-2">
      <component-button
        :classes="['btn', 'btn-block', 'btn-transparent', 'text-light']"
        @click="$router.push({ name: 'login' })"
        type="button">
        <i class="fi fi-rr-sign-in-alt"></i>
        <span>¿Ya tienes cuenta?</span>
      </component-button>

      <component-button
        :classes="['btn', 'btn-block', 'btn-light']"
        @click="change_step_to(1)"
        type="button">
        <i class="fi fi-rr-hammer-crash"></i>
        <span>Empecemos</span>
      </component-button>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["id", "step", "change_step_to"],
    mounted() {
      // Tracking
      const track = new this.controllers.Track();

      track.add({
        name: `onboarding_step_0`,
        category: ["events", "lifecycle", "page_view"].join(","),
        labels: `acquisition`,
        update: true,
      });
    },
  };
</script>
