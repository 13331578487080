export default async function () {
  const user = new this.controllers.User();
  const alert = this.alert;

  if (this.disabled) {
    alert.info("Selecciona una opción válida!");
    return;
  }

  this.forms.how_you_met_us.error = null;
  this.forms.how_you_met_us.loading = true;

  const body = {
    utm_source: this.forms.how_you_met_us.inputs.utm_source.value,
    utm_reffered: this.forms.how_you_met_us.inputs.utm_reffered.value,
  };

  const { status, response } = await user.update_utm_variables(body);

  if (status == "SERVER_ERROR" || status == "ERROR") {
    if (response.errors) {
      Object.keys(response.errors).forEach((input) => {
        alert.error(response.errors[input][0]);
      });
    }

    if (response.message) {
      alert.error(response.message);
    }

    this.forms.how_you_met_us.loading = false;
    return;
  }

  localStorage.removeItem("how_you_met_us_next_attempt");

  this.$store.dispatch("modals/toggle", {
    id: "modal_how_you_met_us",
    force: "close",
  });
}
