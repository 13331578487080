<template>
  <span class="badge d-inline-flex gap-1 cursor-pointer" :class="classes || []" @click="eventonclick()">
    <i v-if="icon" :class="['fs-inherit', icon]"></i>
    {{ text || "" }}
  </span>
</template>

<script>
export default {
  props: ["link", "modal", "text", "icon", "classes", "onclick"],

  methods: {
    eventonclick() {
      this.onclick && this.onclick();

      if (this.link) {
        this.$router.push(this.link);
      }

      if (this.modal) {
        this.$store.dispatch("modals/toggle", {
          id: this.modal,
          force: "open",
          close_others_modal: false,
        });
      }
    },
  },
};
</script>
