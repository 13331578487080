export default function login({ commit }, { user, authorization }) {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
    commit("SET_USER", user);
  }

  if (authorization) {
    const time = new Date();
    const expiration = time.setDate(time.getDate() + 6);

    localStorage.setItem("authorization", JSON.stringify(authorization));
    localStorage.setItem("authorization_expiration", expiration);

    commit("SET_AUTHORIZATION", authorization);
  }
}
