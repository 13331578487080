<template>
  <component-modal
    ref="modal"
    id="modal_update_profile"
    :dark="false"
    :clean="false"
    :permanent_mode="false"
    :with_close_button="true"
    :classes_modal="['center', 'p-2', 'very-small']"
    :classes_wrapper="['p-4']"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12 d-flex flex-column gap-2">
            <div class="block-title">
              <h4 class="m-0">Perfil</h4>
              <p class="description m-0 mt-1 text-muted">
                Hey, Necesitamos asegurarnos de que tu información esté al día.
                <b
                  >¿Podrías tomarte un momentito para revisar y actualizar tus datos y
                  claves de acceso?</b
                >
              </p>
            </div>

            <div class="d-flex w-100 gap-2 flex-column">
              <p class="m-0 text-muted font-weight-bold">Información basica</p>

              <component-input
                type="text"
                label="Email"
                placeholder="Email"
                :default="user.email || forms.profile.inputs.email.value"
                :error="forms.profile.inputs.email.error"
                :loading="forms.profile.loading"
                :onupdate="(x) => (forms.profile.inputs.email.value = x)"
              />

              <div class="d-flex w-100 gap-2">
                <component-input
                  type="text"
                  label="Nombre"
                  placeholder="Nombre"
                  :default="user.name || forms.profile.inputs.name.value"
                  :error="forms.profile.inputs.name.error"
                  :loading="forms.profile.loading"
                  :onupdate="(x) => (forms.profile.inputs.name.value = x)"
                />

                <component-input
                  type="password"
                  label="Clave"
                  placeholder="Clave"
                  :default="forms.profile.inputs.password.value"
                  :error="forms.profile.inputs.password.error"
                  :loading="forms.profile.loading"
                  :onupdate="(x) => (forms.profile.inputs.password.value = x)"
                />
              </div>

              <p class="m-0 text-muted font-weight-bold">Contacto</p>

              <component-input
                type="text"
                label="Whatsapp"
                placeholder="Whatsapp"
                :default="
                  (user.user_information && user.user_information.whatsapp) ||
                  forms.profile.inputs.whatsapp.value
                "
                :error="forms.profile.inputs.whatsapp.error"
                :loading="forms.profile.loading"
                :onupdate="(x) => (forms.profile.inputs.whatsapp.value = x)"
              />

              <p class="m-0 text-muted font-weight-bold" v-if="user.psychologist">
                Psicólogo asignado
              </p>

              <p class="m-0 mt-1 text-muted" v-if="user.psychologist">
                <span class="badge dark small">{{ user.psychologist.name }}</span> será el
                psicólogo que estará atendiendo tus consultas cuando envíes preguntas a
                través del botón de dudas dentro de las unidades.
              </p>

              <div class="d-flex w-100 gap-2">
                <component-button
                  :class="['w-100']"
                  :classes="['btn', 'btn-block', 'btn-primary']"
                  @click="submit"
                  :loading="forms.profile.loading"
                >
                  <i class="fi fi-rr-refresh"></i>
                  <span>Actualizar</span>
                </component-button>

                <component-button
                  :class="['w-100']"
                  :classes="['btn', 'btn-block', 'btn-light']"
                  @click="
                    open_browser('/notifications', {
                      email: user.email,
                    })
                  "
                >
                  <i class="fi fi-rr-bell"></i>
                  <span>Notificaciones</span>
                </component-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

// Methods
import submit from "./methods/submit";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  data() {
    return {
      forms: {
        profile: {
          loading: false,
          error: null,

          inputs: {
            email: {
              value: "",
              error: null,
            },

            name: {
              value: "",
              error: null,
            },

            whatsapp: {
              value: "",
              error: null,
            },

            password: {
              value: "",
              error: null,
            },
          },
        },
      },
    };
  },

  methods: {
    submit,
  },
});
</script>
