<template>
  <div
    class="units"
    v-if="is_active_on_desktop">
    <div
      v-for="_unit in units"
      :class="['unit', is_active(_unit) && 'active']"
      :key="_unit.id"
      @click="pagination_to_unit(_unit)">
      <div class="unit-status">
        <div class="icon">
          <i
            class="fi fi-rr-lock"
            v-if="is_blocked(_unit)"></i>

          <i
            class="fi fi-rr-unlock"
            v-if="is_in_progress(_unit)"></i>

          <i
            class="fi fi-rr-check"
            v-if="is_finished(_unit)"></i>
        </div>
      </div>
      <div class="unit-content">
        <div class="unit-detail">
          <div class="title">{{ _unit.title }}</div>
        </div>

        <div class="progress-bar">
          <div
            class="progress"
            :style="{ width: get_progress(_unit) + '%' }"></div>
        </div>
      </div>
    </div>

    <div class="pagination d-inline-flex gap-3 justify-content-end">
      <component-button
        :classes="[
          'btn',
          'btn-block',
          'btn-black',
          'transparent',
          'btn-nopadding',
        ]"
        type="button"
        :execute="{
          callback: pagination_prev_unit,
          arguments: {},
        }"
        :loading="false">
        <i class="fi fi-rr-angle-small-left"></i>
      </component-button>

      <component-button
        :classes="[
          'btn',
          'btn-block',
          'btn-black',
          'transparent',
          'btn-nopadding',
        ]"
        type="button"
        :execute="{
          callback: pagination_next_unit,
          arguments: {},
        }"
        :loading="false">
        <i class="fi fi-rr-angle-small-right"></i>
      </component-button>
    </div>
  </div>

  <div
    class="units-mobile"
    v-if="is_active_on_mobile && mode != 'player'">
    <div class="units">
      <div :class="['unit', 'active']">
        <div class="unit-status">
          <div class="icon">
            <i
              class="fi fi-rr-lock"
              v-if="is_blocked(unit)"></i>

            <i
              class="fi fi-rr-unlock"
              v-if="is_in_progress(unit)"></i>

            <i
              class="fi fi-rr-check"
              v-if="is_finished(unit)"></i>
          </div>
        </div>
        <div class="unit-content">
          <div class="unit-detail">
            <div class="title">{{ unit.title }}</div>
          </div>

          <div class="progress-bar">
            <div
              class="progress"
              :style="{ width: get_progress(unit) + '%' }"></div>
          </div>
        </div>
      </div>

      <div class="pagination d-inline-flex gap-3 justify-content-end">
        <component-button
          :classes="[
            'btn',
            'btn-block',
            'btn-black',
            'transparent',
            'btn-nopadding',
          ]"
          type="button"
          :execute="{
            callback: pagination_prev_unit,
            arguments: {},
          }"
          :loading="false">
          <i class="fi fi-rr-angle-small-left"></i>
        </component-button>

        <component-button
          :classes="[
            'btn',
            'btn-block',
            'btn-black',
            'transparent',
            'btn-nopadding',
          ]"
          type="button"
          :execute="{
            callback: pagination_next_unit,
            arguments: {},
          }"
          :loading="false">
          <i class="fi fi-rr-angle-small-right"></i>
        </component-button>
      </div>
    </div>

    <div
      class="progress-bar-content px-3"
      v-if="pagination_unit_progress">
      <div class="progress-bar">
        <div
          class="progress"
          :style="{
            width: pagination_unit_progress + '%',
          }"></div>
      </div>
    </div>
  </div>
</template>

<script>
  // Libs
  import { defineComponent } from "vue";

  export default defineComponent({
    watch: {},

    computed: {
      mode() {
        return this.$store.state.player.settings.mobile.mode;
      },

      pagination_unit_progress() {
        const units = this.$store.state.player.units;
        const unit_current = this.$store.state.player.unit;
        const index = units.findIndex((u) => u.id == unit_current.id);

        return ((index + 1) * 100) / units.length;
      },

      player() {
        return this.$store.state.player.player.instance;
      },

      is_active_on_desktop() {
        return (
          this.screen_resolution(this) == "desktop" &&
          this.units &&
          this.units.length > 0
        );
      },

      is_active_on_mobile() {
        return (
          this.screen_resolution(this) == "mobile" &&
          this.units &&
          this.units.length > 0
        );
      },

      route() {
        return this.$store.state.player.route;
      },

      unit() {
        return this.$store.state.player.unit;
      },

      units() {
        return this.$store.getters["player/units_carousel"];
      },
    },

    methods: {
      is_active(unit) {
        return this.$store.state.player.unit.id == unit.id;
      },

      is_in_progress(unit) {
        return unit.my_assignment && unit.my_assignment.status == "in_progress";
      },

      is_blocked(unit) {
        return unit.my_assignment && unit.my_assignment.status == "blocked";
      },

      is_finished(unit) {
        return unit.my_assignment && unit.my_assignment.status == "finished";
      },

      get_progress(unit) {
        return unit.my_assignment.progress;
      },

      pagination_to_unit(unit) {
        this.$store.dispatch("player/pagination_to_unit", unit);
      },

      pagination_next_unit() {
        this.$store.dispatch("player/pagination_next_unit");
      },

      pagination_prev_unit() {
        this.$store.dispatch("player/pagination_prev_unit");
      },

      change_screen() {
        this.$store.dispatch("player/update_mobile_mode", {
          mode: "player",
        });
      },
    },
  });
</script>
