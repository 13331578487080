export default async function ({
  page = 1,
  limit = 3,
  search_by = "[]",
  order_by = "created_at,desc",
  callback = () => {},
}) {
  const route = new this.controllers.Route();
  const alert = this.alert;

  this.loading = true;

  if (this.screen_resolution(this) == "mobile") {
    limit = 4;
  }

  const { status, response } = await route.get_public_routes({
    page,
    limit,
    search_by,
    order_by,
  });

  if (status != "SUCCESS" && response.message) {
    alert.error(response.message);
  }

  callback(response);

  this.loading = false;
}
