export default function (state, { action = null, unit = {} }) {
    switch (action) {
      case "update":
        state.unit = unit;
        break;

      case "destroy":
        state.unit = {};
        break;
    }
  }