export default async function ($route = null) {
  const route = $route || this.$route;
  const { modal, ...new_query } = route.query;

  if (route.name == "login") {
    return;
  }

  setTimeout(() => {
    if (!this.user) {
      return;
    }

    if (modal == "modal_player" && !route.query.route_id) {
      this.alert.error("Para abrir el reproductor necesitas el ID de la ruta.");
      return;
    }

    if (modal) {
      this.$store.dispatch("modals/toggle", {
        id: modal,
        force: "open",
      });

      this.$router.replace({
        query: new_query,
      });
    }
  }, 1500);
}
