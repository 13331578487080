<template>
  <component-modal
    id="modal_appointment"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="true"
    :onopened="init"
    :permanent_mode="false"
    :with_close_button="true"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12 d-flex flex-column gap-2">
            <div class="block-title">
              <h4 class="m-0">Hola <emoji file="clapping_hands.png" /></h4>
              <p class="description m-0 text-muted">
                Explora las fechas que tenemos para ti justo debajo para que así podamos
                continuar con nuestro proceso hacia tus objetivos.
              </p>
            </div>

            <div v-if="loading_spaces" class="block-loader">
              <div class="loader"></div>
            </div>

            <div v-if="!loading_spaces" class="d-flex gap-2 flex-column">
              <div class="block-help">
                <p class="description m-0 text-bold">Escucha esto...</p>

                <component-player-simple
                  :source="audio_explanation"
                  format="mp3"
                  name="appointment"
                />
              </div>

              <div class="block-dates d-flex gap-2 flex-column">
                <p class="description m-0 text-bold">
                  ¿Cuando podrias atender tu sesion?
                </p>

                <div class="block-top">
                  <div :class="['prev']" @click="get_page(-1, 'month')">
                    <i class="fi fi-rr-angle-small-left"></i>
                  </div>
                  <div
                    v-for="e in months"
                    :class="['month', this.selected_month == e.id && 'selected']"
                    @click="select_month(e.id)"
                  >
                    <span class="dayname">{{ e.day_name }}</span>
                    <span class="number">{{ e.day }}</span>
                    <span class="name">{{ e.month }}</span>
                  </div>
                  <div :class="['next']" @click="get_page(1, 'month')">
                    <i class="fi fi-rr-angle-small-right"></i>
                  </div>
                </div>

                <div v-if="hours.length > 0" class="block-button">
                  <div :class="['prev']" @click="get_page(-1, 'hour')">
                    <i class="fi fi-rr-angle-small-left"></i>
                  </div>
                  <div
                    v-for="hour in hours"
                    :class="['hour', this.selected_hour == hour && 'selected']"
                    @click="this.selected_hour = hour"
                  >
                    {{ hour.local.split("|")[1] }}
                  </div>
                  <div :class="['next']" @click="get_page(1, 'hour')">
                    <i class="fi fi-rr-angle-small-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <p class="m-0 text-muted" v-if="!loading_spaces">
              * Las fechas y horas mostradas a continuación son en tu zona horaria:
              <b>{{ timezone }}</b>
            </p>

            <div v-if="!loading_spaces" class="block-footer d-flex gap-2">
              <component-button
                :classes="['btn', 'btn-block', 'btn-dark']"
                :execute="{
                  callback: submit,
                  arguments: {},
                }"
                :loading="loading"
              >
                <i class="fi fi-rr-paper-plane"></i>
                <span>Procesar cita</span>
              </component-button>

              <component-button
                :classes="['btn', 'btn-block', 'btn-light', 'transparent']"
                @click="open_browser(`/contact`)"
              >
                <i class="fi fi-rr-headset"></i>
                <span>¿Necesitas ayuda?</span>
              </component-button>
            </div>

            <p class="m-0 text-muted" v-if="!loading_spaces">
              Tendrás una cita con
              <span class="badge dark small">{{
                user?.psychologist?.name || "uno de nuestros psicólogos certificados"
              }}</span>
              por {{ duration }} minutos, por lo que es necesario que entiendas que al
              asistir a este encuentro estás aceptando nuestros
              <a @click="open_browser(`/terms`)">términos y condiciones de uso</a>, Así
              como también el siguiente
              <a @click="open_browser(`/consent`)">consentimiento</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Importaciones
import { defineComponent } from "vue";
import get_spaces from "./methods/get_spaces";
import get_page from "./methods/get_page";
import get_months from "./methods/get_months";
import get_hours from "./methods/get_hours";
import submit from "./methods/submit";
import select_month from "./methods/select_month";

// Constants
import { URL_ASSETS } from "@/configs/domains.js";

// Componente
export default defineComponent({
  data() {
    const audios = [
      `${URL_ASSETS}/explicacion_sesion/1.mp3`,
      `${URL_ASSETS}/explicacion_sesion/2.mp3`,
      `${URL_ASSETS}/explicacion_sesion/3.mp3`,
    ];

    return {
      loading: false,
      need_subscription: false,
      loading_spaces: true,
      playing: false,
      psychologist: null,
      selected_month: null,
      selected_hour: null,
      duration: null,
      timezone: null,
      audio_explanation: audios[Math.floor(Math.random() * audios.length)],

      spaces: [],
      pagination: {
        month: {
          current_page: 0,
          per_page: 3,
        },

        hour: {
          current_page: 0,
          per_page: 3,
        },
      },
    };
  },

  computed: {
    months: get_months,
    hours: get_hours,
    user() {
      return this.$store.state.auth.user;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },

    subscription_level() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.level
      );
    },
  },

  methods: {
    init() {
      if (
        !this.subscription_status ||
        (this.subscription_status && this.subscription_level == "access")
      ) {
        this.$store.dispatch("modals/toggle", {
          id: "continue_to_full",
          close_others_modal: true,
          dark: true,
        });

        return;
      }

      this.get_spaces();
    },

    submit,
    select_month,
    get_spaces,
    get_page,
    open_subscription_modal() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_appointment",
        force: "close",
      });

      setTimeout(() => {
        this.$store.dispatch("modals/toggle", {
          id: "modal_subscription",
          force: "open",
        });
      }, 1000);
    },
  },
});
</script>
