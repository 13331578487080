export default function (e) {
  if (!this.is_playing || this.is_seeking) {
    return;
  }

  this.is_seeking = true;

  const { left, width } = e.target.getBoundingClientRect();
  var x = e.clientX - left;

  var percentage = (x / width) * 100;
  percentage = Math.round(percentage);

  let recommended_seconds = parseInt(this.duration * (percentage / 100));
  let target = recommended_seconds;

  this.player.seek(target);

  const interval = setInterval(() => {
    if (this.is_playing) {
      this.is_seeking = false;
      clearInterval(interval);
    }
  }, 500);
}
