<template>
  <div
    class="content-index"
    v-if="user">
    <div class="row">
      <div class="col-12">
        <div class="block-user">
          <div class="d-flex w-100 justify-content-center align-items-center">
            <emoji
              size="62px"
              file="star_struck.png" />
          </div>

          <div class="text-muted d-block user-name">
            Hola, <b>{{ user.name }}</b>
          </div>

          <div class="message">{{ random_message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    computed: {
      user() {
        return this.$store.state.auth.user;
      },

      subscription_status() {
        return (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.subscription &&
          this.$store.state.auth.user.subscription.status
        );
      },

      random_message() {
        return this.messages[Math.floor(Math.random() * this.messages.length)];
      },
    },

    data() {
      return {
        messages: ["¿Comenzamos?", "¿Iniciamos?", "¿Listos?", "¿Seguimos?"],
      };
    },
  };
</script>
