<template>
  <component-modal
    id="modal_update_app"
    ref="modal"
    :onopened="init"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="false"
    :permanent_mode="false"
    :with_close_button="true"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title text-center">
              <h4 class="m-0">
                Hey
                <emoji size="38px" file="alarm_clock.png" />
              </h4>

              <p class="description m-0 mt-1 text-muted">
                Como parte de nuestro compromiso por mejorar continuamente, actualizamos
                recurrentemente nuestra app! Y
                <b
                  >justo detectamos que estás usando una versión antigua. Te invitamos a
                  actualizar para por disfrutar de las últimas novedades.</b
                >
              </p>

              <hr class="hr m-0 mt-2" v-if="!loading" />

              <p class="description m-0 mt-2 text-muted" v-if="!loading">
                Tienes la versión:
                <span class="badge small dark">{{ current_version }}</span> actualiza la
                app en tu tienda favorita para obtener la última versión
                <span class="badge small dark">{{ cloud_version }}</span>
              </p>
            </div>

            <div class="d-flex w-100 gap-2 mt-2">
              <component-button
                v-if="current_device() == 'ios'"
                :classes="['btn', 'btn-block', 'btn-primary']"
                @click="open_browser(APPLE_STORE_LINK)"
              >
                <i class="fi fi-brands-apple"></i>
                <span>Apple Store</span>
              </component-button>

              <component-button
                v-if="current_device() == 'android'"
                :classes="['btn', 'btn-block', 'btn-primary']"
                @click="open_browser(GOOGLE_PLAY_LINK)"
              >
                <i class="fi fi-brands-google"></i>
                <span>Google Play</span>
              </component-button>

              <component-button
                :classes="['btn', 'btn-block', 'btn-transparent', 'text-dark']"
                :loading="false"
                type="button"
                @click="open_browser(`/contact`)"
              >
                <i class="fi fi-rr-headset"></i>
                <span>Necesito ayuda</span>
              </component-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import CONSTS from "@/configs/vars";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  data() {
    return {
      GOOGLE_PLAY_LINK: CONSTS.GOOGLE_PLAY_LINK,
      APPLE_STORE_LINK: CONSTS.APPLE_STORE_LINK,

      loading: true,
      current_version: 0,
      cloud_version: 0,
    };
  },

  mounted() {
    this.get_cloud_version();
  },

  methods: {
    init() {
      this.get_cloud_version();
    },

    async get_cloud_version() {
      const common = new this.controllers.Common();
      const utm_version = localStorage.getItem("utm_version");
      const utm_device = localStorage.getItem("utm_device");

      this.loading = true;
      this.current_version = utm_version;

      try {
        const { status, response } = await common.version();
        const version = response.version[utm_device];
        this.cloud_version = version;
        this.cloud_version = this.cloud_version[this.cloud_version.length - 1];
        this.cloud_version = this.cloud_version.version;
        this.loading = false;
      } catch (error) {}

      this.loading = false;
    },
  },
});
</script>
