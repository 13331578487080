<template>
  <div :class="['component__button', full_width ? 'full-width' : '']">
    <button
      :type="type"
      :class="[...classes]"
      :disabled="is_disabled"
      @click="onclick">
      <div :class="[is_loading && 'invisible', ...inner_classes]">
        <slot></slot>
      </div>
      <div :class="['btn-loader', !is_loading && 'invisible']">
        <div class="loader"></div>
      </div>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      inner_classes: {
        type: Array,
        default: () => [],
      },

      classes: {
        type: Array,
        default: () => [],
      },

      type: {
        default: "button",
        type: String,
      },

      loading: {
        default: false,
        type: Boolean,
      },

      disabled: {
        default: false,
        type: Boolean,
      },

      execute: {
        default: null,
      },

      full_width: {
        default: false,
      },
    },

    computed: {
      is_disabled() {
        return this.disabled;
      },

      is_loading() {
        return this.loading;
      },
    },

    methods: {
      onclick() {
        if (this.execute && this.execute.callback && !this.loading)
          this.execute.callback(this.execute.arguments);
      },
    },
  };
</script>
