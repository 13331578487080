export default function (state, { action = null, route = {} }) {
  switch (action) {
    case "update":
      state.route = route;
      break;

    case "destroy":
      state.route = null;
      break;
  }
}
