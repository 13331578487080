<template>
  <component-modal
    id="modal_onboarding_suggestions"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="false"
    :permanent_mode="false"
    :with_close_button="true">
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title">
              <h4 class="m-0">¿Como funciona?</h4>
              <p class="description m-0 mt-1 text-muted">
                Si enfrentas ansiedad o estrés, nuestro servicio te guía paso a
                paso hacia el bienestar. Identificamos tus emociones, te
                brindamos recursos prácticos y estrategias personalizadas para
                ayudarte a manejar eficazmente tus sentimientos.
              </p>
            </div>

            <p class="m-0 mt-2 text-bold">Aca te explicamos todo...</p>

            <component-player-simple
              ref="player"
              v-if="audio_onboarding"
              :source="audio_onboarding"
              class="mt-2"
              format="mp3"
              name="onboarding" />

            <component-button
              :classes="[
                'mt-2',
                'w-100',
                'btn',
                'btn-block',
                'btn-primary',
                'btn-block',
              ]"
              type="button"
              @click="close_modal">
              <i class="fi fi-rr-arrow-right"></i>
              <span>Continua</span>
            </component-button>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
  // Others elements
  import { defineComponent } from "vue";

  // Constants
  import { URL_ASSETS } from "@/configs/domains.js";

  export default defineComponent({
    computed: {
      user() {
        return this.$store.state.auth.user;
      },
    },

    data() {
      const audios = [`${URL_ASSETS}/onboarding/explora.mp3`];

      return {
        audio_onboarding: audios[Math.floor(Math.random() * audios.length)],
      };
    },

    mounted() {
      this.$refs.modal.set_callbacks({
        onclose: () => {
          if (this.$refs.player?.is_playing) {
            this.$refs.player?.toggle();
          }
        },
      });
    },

    methods: {
      async close_modal() {
        this.$store.dispatch("modals/toggle", {
          id: "modal_onboarding_suggestions",
        });
      },
    },
  });
</script>
