<template>
  <div class="footer d-flex flex-column gap-3 justify-content-start">
    <p v-if="screen_resolution(this) == 'mobile'" class="p-0 m-0 d-flex w-100 text-muted" style="font-weight: 600">
      Soporte y ayuda
    </p>

    <div class="left d-flex gap-3 flex-wrap justify-content-start w-100">
      <component-button :classes="['btn', 'btn-block', 'btn-black', 'transparent', 'btn-nopadding']"
        @click="send_a_tickets()">
        <i class="fi fi-rr-headset"></i>
        <span>{{ text_call_to_action_tickets }}</span>
      </component-button>

      <component-button :classes="['btn', 'btn-block', 'btn-black', 'transparent', 'btn-nopadding']"
        @click="open_browser(`/feedback`)">
        <i class="fi fi-rr-comment-quote"></i>
        <span>Deja un comentario</span>
      </component-button>
    </div>
  </div>
</template>

<script>
// Libs
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    text_call_to_action_tickets() {
      const texts = [
        "¿Dudas?",
        "¿Buscas orientación?",
        "¿Preguntas?",
        "¿Te ayudamos?",
        "¿Necesitas ayuda?",
      ];

      const index = Math.floor(Math.random() * texts.length);
      return texts[index];
    },
  },

  methods: {
    send_a_tickets() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_tickets",
        dark: true,
        close_others_modal: false,
      });
    },
  },
});
</script>
