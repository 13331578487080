<template>
  <div class="paywall paywall_3 d-flex flex-column gap-2">
    <div
      class="block title d-flex flex-column gap-1 justify-content-center align-items-center"
    >
      <emoji size="48px" file="trophy.png" />
      <h4 class="m-0 mt-2">Consigue <span class="badge">resultados</span></h4>
      <p class="m-0 description">Con la asistencia de <b>psicólogos</b> certificados</p>
    </div>

    <component-problems />

    <p class="m-0">
      <b>Consigue:</b>
    </p>
    <component-benefits />
    <component-social_proof />

    <p class="text-dark text-center m-0">
      ¿Qué te parece si empezamos a cuidar de tu bienestar desde hoy? No hay mejor momento
      que el presente, y
      <b
        >aquí estamos para acompañarte en cada paso del camino, durante y después de tu
        proceso.</b
      >
    </p>

    <!-- Payment process -->
    <component-go_payment />
  </div>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import go_payment from "./components/gopayment.vue";
import problems from "./components/problems.vue";
import benefits from "./components/benefits.vue";
import social_proof from "./components/social_proof.vue";

export default defineComponent({
  components: {
    "component-go_payment": go_payment,
    "component-problems": problems,
    "component-benefits": benefits,
    "component-social_proof": social_proof,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription() {
      return this.$store?.state?.auth?.user?.subscription;
    },
  },
});
</script>
