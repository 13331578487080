<template>
  <component-modal
    id="modal_player"
    ref="modal"
    :dark="true"
    :clean="true"
    :with_pattern="true"
    :with_close_button="false"
    :animation="'animate__fadeIn'"
    :classes_modal="['modal-player', 'large_x2', 'center', 'p-2']"
    :classes_wrapper="['p-4']"
  >
    <component-player-pro
      :settings="{
        with_close_button: true,
      }"
    />
  </component-modal>
</template>

<script>
export default {};
</script>
