export default async function () {
  const toast = this.alert;
  const user = new this.controllers.User();
  let token = this.$route.query.token || null;
  let status, response;

  this.forms.recover.error = null;
  this.forms.recover.loading = true;

  if (!token) {
    ({ status, response } = await user.recover_password_get_link({
      email: this.forms.recover.inputs.email.value,
    }));
  }

  if (token) {
    ({ status, response } = await user.recover_password_set_password({
      password: this.forms.recover.inputs.password.value,
      token,
    }));
  }

  if (status == "SERVER_ERROR" || status == "ERROR") {
    if (response.errors) {
      Object.keys(response.errors).forEach((input) => {
        if (this.forms.recover.inputs[input]) {
          this.forms.recover.inputs[input].error =
            this.forms.recover.inputs[input] && response.errors[input][0];
        }
      });
    }

    if (response.message) {
      this.forms.recover.error = response.message;
    }

    this.forms.recover.loading = false;
    return;
  }

  if (status == "SUCCESS" && response.message) {
    toast.success(response.message);
    this.$router.push("/login");
  }

  this.forms.recover.loading = false;
}
