<template>
  <component-modal
    ref="modal"
    id="modal_menu"
    :dark="false"
    :clean="false"
    :permanent_mode="false"
    :with_close_button="true"
    :onopened="init"
    :classes_modal="['center', 'p-2', 'very-small']"
    :classes_wrapper="['p-4']"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center align-items-center" v-if="user">
              <div class="avatar" :style="{ backgroundImage: `url(${user.avatar})` }">
                <div
                  class="icon-loader animate__animated animate__fadeIn"
                  v-if="loading_avatar"
                >
                  <div class="loader"></div>
                </div>

                <div
                  class="icon-change-avatar animate__animated animate__fadeIn"
                  @click="select_profile_avatar()"
                  v-if="!loading_avatar"
                >
                  <i class="fi fi-rr-refresh"></i>
                </div>

                <div
                  class="icon-premium animate__animated animate__fadeIn"
                  v-if="subscription_status"
                >
                  <i
                    class="fi fi-sr-bolt"
                    :class="[
                      subscription_level === 'access' ? 'text-light' : 'text-info',
                    ]"
                  ></i>
                </div>
              </div>
            </div>

            <div
              v-if="user"
              class="d-flex flex-column justify-content-center align-items-center mt-3"
            >
              <h4 class="lh-1">{{ user.name }}</h4>

              <div class="d-flex gap-2 mt-0">
                <div class="d-flex justify-content-center badgets gap-2" v-if="sandbox">
                  <div class="badge dark">sandbox</div>
                  <div class="badge dark" v-if="utm_testing">{{ utm_testing }}</div>
                </div>
              </div>
            </div>

            <input
              :style="{
                display: 'none',
              }"
              type="file"
              ref="avatar"
              @change="
                (event) => {
                  this.avatar = event.target.files[0];
                  this.update_profile_avatar();
                }
              "
            />

            <div class="d-flex flex-column gap-3 mt-2">
              <component-button
                v-if="$route.name !== 'index'"
                :classes="[
                  'btn',
                  'btn-block',
                  'btn-left',
                  'btn-nopadding',
                  'btn-transparent',
                  'text-dark',
                ]"
                @click="
                  $router.push({ name: 'index' });
                  $refs.modal.toggle();
                "
              >
                <i class="fi fi-rr-house-chimney"></i>
                <span>Inicio</span>
              </component-button>

              <component-button
                :classes="[
                  'btn',
                  'btn-block',
                  'btn-left',
                  'btn-nopadding',
                  'btn-transparent',
                  'text-dark',
                ]"
                @click="
                  $store.dispatch('modals/toggle', {
                    id: 'modal_update_profile',
                  });

                  $refs.modal.toggle();
                "
              >
                <i class="fi fi-rr-user-pen"></i>
                <span>Ajustes</span>
              </component-button>

              <component-button
                v-if="subscription_status"
                class="d-block w-100"
                :classes="[
                  'btn',
                  'btn-block',
                  'btn-left',
                  'btn-nopadding',
                  'btn-transparent',
                  'text-dark',
                ]"
                @click="
                  $store.dispatch('modals/toggle', {
                    id: 'modal_subscription',
                  });

                  $refs.modal.toggle();
                "
              >
                <i class="fi fi-rr-bolt"></i>
                <span>Suscripcion</span>
              </component-button>

              <component-button
                class="d-block w-100"
                :classes="[
                  'btn',
                  'btn-block',
                  'btn-left',
                  'btn-nopadding',
                  'btn-transparent',
                  'text-dark',
                  'w-100',
                ]"
                @click="
                  $store.dispatch('modals/toggle', {
                    id: 'modal_tickets',
                    force: 'open',
                    close_others_modal: false,
                    callbacks: {
                      onclose: () => {
                        this.get_pending_messages();
                      },
                    },
                  })
                "
              >
                <i class="fi fi-rr-comments"></i>
                <span v-if="pending_to_be_read > 0" class="badge dark small">
                  {{ pending_to_be_read }}
                </span>
                <span>Mensajes</span>
              </component-button>

              <component-button
                :classes="[
                  'btn',
                  'btn-block',
                  'btn-left',
                  'btn-nopadding',
                  'btn-transparent',
                  'text-danger',
                ]"
                @click="delete_account()"
              >
                <i class="fi fi-rr-trash-xmark"></i>
                <span>Eliminar mi cuenta</span>
              </component-button>
            </div>

            <label class="text-muted mt-3">Encuentranos</label>
            <div class="d-flex flex-column gap-3 mt-2">
              <component-button
                :classes="[
                  'btn',
                  'btn-block',
                  'btn-left',
                  'btn-nopadding',
                  'btn-transparent',
                  'text-dark',
                ]"
                @click="open_browser(`/contact`)"
              >
                <i class="fi fi-rr-headset"></i>
                <span>Necesito ayuda</span>
              </component-button>

              <component-button
                :classes="[
                  'btn',
                  'btn-block',
                  'btn-left',
                  'btn-nopadding',
                  'btn-transparent',
                  'text-dark',
                ]"
                @click="open_browser(`/feedback`)"
              >
                <i class="fi fi-rr-comment-quote"></i>
                <span>Deja un comentario</span>
              </component-button>
            </div>

            <div class="d-flex gap-2 flex-column">
              <component-footer
                class="mt-1"
                :address="true"
                :terms="true"
                :version="true"
                :terms_onclick="$refs.modal.toggle"
              />

              <component-button
                v-if="!subscription_status || subscription_level === 'access'"
                class="d-block w-100"
                :classes="['btn', 'btn-block', 'btn-dark', 'w-100']"
                @click="
                  $store.dispatch('modals/toggle', {
                    id: 'modal_subscription',
                  });

                  $refs.modal.toggle();
                "
              >
                <i class="fi fi-rr-bolt"></i>

                <span class="d-flex align-items-center gap-2" v-if="!subscription_status">
                  Suscribete
                </span>

                <span
                  class="d-flex gap-2align-items-center"
                  v-if="subscription_level === 'access'"
                >
                  Sube de nivel
                </span>
              </component-button>

              <component-button
                :classes="['btn', 'btn-block', 'btn-danger', 'w-100']"
                @click="logout"
              >
                <i class="fi fi-rr-exit"></i>
                <span>Cerrar mi sesion</span>
              </component-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

// Methods
import logout from "./methods/logout";
import select_profile_avatar from "./methods/select_profile_avatar";
import update_profile_avatar from "./methods/update_profile_avatar";
import delete_account from "./methods/delete_account";

export default defineComponent({
  computed: {
    darkmode() {
      return this.$store.state.template.darkmode;
    },

    user() {
      return this.$store.state.auth.user;
    },

    sandbox() {
      return this.$store.state.template.testing_mode;
    },

    utm_testing() {
      return localStorage.getItem("utm_testing");
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },

    subscription_level() {
      return this.$store?.state?.auth?.user?.subscription?.level;
    },
  },

  data() {
    return {
      avatar: null,
      loading_avatar: false,
      pending_to_be_read: 0,
    };
  },

  methods: {
    async init() {
      this.get_pending_messages();
    },
    logout,
    select_profile_avatar,
    update_profile_avatar,
    delete_account,
    async get_pending_messages() {
      const ticket = new this.controllers.Ticket();
      const { status, response } = await ticket.pending_to_be_read();
      this.pending_to_be_read = response.pending_to_be_read || 0;
    },
  },
});
</script>
