<template>
  <div class="component__player">
    <div
      class="content-loading"
      v-if="is_loading">
      <span class="loader"></span>
    </div>

    <div
      class="d-flex flex-column gap-3"
      v-if="!is_loading">
      <component-header />
      <component-units />

      <div class="content-player">
        <component-fullalert />

        <div class="d-flex">
          <div class="player">
            <component-audio />
          </div>

          <div class="side">
            <component-unit />
            <component-questions />
          </div>
        </div>
      </div>

      <component-footer />
    </div>
  </div>
</template>

<script>
  // Components
  import questions from "./components/questions.vue";
  import audio from "./components/audio.vue";
  import video from "./components/video.vue";
  import header from "./components/header.vue";
  import units from "./components/units.vue";
  import unit from "./components/unit.vue";
  import footer from "./components/footer.vue";
  import fullalert from "./components/fullalert.vue";

  export default {
    components: {
      "component-questions": questions,
      "component-audio": audio,
      "component-video": video,
      "component-header": header,
      "component-unit": unit,
      "component-units": units,
      "component-footer": footer,
      "component-fullalert": fullalert,
    },

    computed: {
      is_loading() {
        return (
          this.$store.state.player.loading ||
          !this.$store.state.player.route ||
          this.$store.state.player.units.length == 0 ||
          !this.$store.state.player.unit
        );
      },
    },
  };
</script>
