<template>
  <component-modal
    ref="modal"
    id="modal_how_you_met_us"
    :dark="false"
    :clean="false"
    :permanent_mode="false"
    :with_close_button="true"
    :classes_modal="['center', 'p-2', 'very-small']"
    :classes_wrapper="['p-4']"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title">
              <h4 class="m-0">¿Cómo nos conociste?</h4>
              <p class="description m-0 mt-1 text-muted">
                Por favor cuéntanos como nos conociste para poder personalizar tu
                experiencia, nos ayudaría un mundo.
              </p>
            </div>

            <div class="d-flex w-100 gap-2 flex-column mt-2">
              <component-input
                type="select"
                label="¿Por dónde nos conociste?"
                :options="[
                  {
                    value: 'selecciona',
                    text: 'Selecciona una opción',
                  },
                  {
                    value: 'influencer',
                    text: 'Por un influencer',
                  },
                  {
                    value: 'seo',
                    text: 'Navegando en Google',
                  },
                  {
                    value: 'ads_facebook',
                    text: 'Una publicidad en Facebook',
                  },
                  {
                    value: 'ads_instagram',
                    text: 'Una publicidad en Instagram',
                  },
                  {
                    value: 'ads_tiktok',
                    text: 'Una publicidad en Tiktok',
                  },
                  {
                    value: 'friend',
                    text: 'Un amigo(a)',
                  },
                  {
                    value: 'other_method',
                    text: 'Otro método?',
                  },
                ]"
                :default="forms.how_you_met_us.inputs.utm_source.value"
                :error="forms.how_you_met_us.inputs.utm_source.error"
                :loading="forms.how_you_met_us.loading"
                :onupdate="(x) => (forms.how_you_met_us.inputs.utm_source.value = x)"
              />

              <component-input
                v-if="forms.how_you_met_us.inputs.utm_source.value == 'influencer'"
                type="text"
                label="¿Tienes un código de referido?"
                placeholder="XYZ000"
                :default="forms.how_you_met_us.inputs.utm_reffered.value"
                :error="forms.how_you_met_us.inputs.utm_reffered.error"
                :loading="forms.how_you_met_us.loading"
                :onupdate="(x) => (forms.how_you_met_us.inputs.utm_reffered.value = x)"
              />

              <p class="text-muted m-0">
                ¡Con esta información nos ayudas muchísimo a mejorar tu experiencia,
                también ayudas a saber donde debemos enfocar nuestras fuerzas para ayudar
                a más personas!
              </p>

              <div class="d-flex gap-2">
                <component-button
                  :classes="['btn', 'btn-block', 'btn-primary']"
                  :disabled="disabled"
                  :loading="forms.how_you_met_us.loading"
                  @click="submit"
                >
                  <i class="fi fi-rr-bullseye-arrow"></i>
                  <span>Continuar</span>
                </component-button>

                <component-button
                  :classes="['btn', 'btn-block', 'btn-transparent', 'text-dark']"
                  @click="close_modal"
                >
                  <i class="fi fi-rr-time-past"></i>
                  <span>En otro momento</span>
                </component-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import moment from "moment";

// Methods
import submit from "./methods/submit";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    disabled() {
      return this.forms.how_you_met_us.inputs.utm_source.value == "selecciona";
    },
  },

  data() {
    return {
      forms: {
        how_you_met_us: {
          loading: false,
          error: null,

          inputs: {
            utm_source: {
              value: "selecciona",
              error: null,
            },

            utm_reffered: {
              value: "",
              error: null,
            },
          },
        },
      },
    };
  },

  methods: {
    submit,
    close_modal() {
      localStorage.setItem(
        "how_you_met_us_next_attempt",
        moment().add(24, "hours").toISOString()
      );

      this.$store.dispatch("modals/toggle", {
        id: "modal_how_you_met_us",
        force: "close",
      });
    },
  },
});
</script>
