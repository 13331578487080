import moment from "moment";

export default function ({ units, unit }) {
  if (!unit) {
    return "¡Vaya, parece que no podemos encontrar la página que estás buscando! Si sigues teniendo problemas, no dudes en enviarnos un mensaje.";
  }

  // Validamos si la unidad que intentamos acceder esta bloqueada
  if (unit.my_assignment.status == "blocked") {
    return "¡Oye! Recuerda que tienes que finalizar la actividad anterior antes de lanzarte a la siguiente. Estamos seguras de que lo harás genial. ¡Vamos, tú puedes!";
  }

  // Validación de tiempo de bloqueo
  const unit_index = units.findIndex((u) => u.id == unit.id);
  const prev_unit = units[unit_index - 1];

  if (prev_unit && prev_unit.blocked_by_time > 0) {
    const time = moment();
    const finished_at = prev_unit.my_assignment.finished_at;
    const end_time = moment(finished_at, "YYYY-MM-DD HH:mm:ss");
    const target_time = end_time.add(prev_unit.blocked_by_time, "days");

    if (time.isBefore(target_time)) {
      const hours_left = target_time.diff(time, "hours");
      let hours_left_for_human = "";

      if (hours_left <= 1) {
        hours_left_for_human = "unos minutos a una hora";
      }

      if (hours_left > 1 && hours_left <= 24) {
        hours_left_for_human = "aproximadamente 1 dia";
      }

      if (hours_left > 24) {
        hours_left_for_human = "unos dias.";
      }

      return `Sabemos que tienes emocion por continuar con tu próxima ruta, pero el proceso amerita que reflexiones en cada unidad que vez, puedes continuar en ${hours_left_for_human}.`;
    }
  }

  return false;
}
