<template>
  <div class="block features v3">
    <div class="feature" v-for="problem in items">
      <emoji size="32px" :file="problem.emoji" />
      <div class="d-block content">
        <span class="title">{{ problem.title }}</span>
        <span class="description">{{ problem.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["problems"],

  computed: {
    items() {
      return this.problems && this.problems.length > 0 ? this.problems : this.default;
    },
  },

  data() {
    return {
      default: [
        {
          emoji: "microbe.png",
          title: "Dile adiós al miedo que parece no tener origen, ",
          description: "a ese que te sorprende sin saber por qué. ",
        },
        {
          emoji: "skull_and_crossbones.png",
          title: "Olvídate de los latidos que se aceleran ante situaciones que,",
          description: "en el fondo, sabes que puedes manejar. ",
        },
        {
          emoji: "person_bowing.png",
          title: "No más noches en vela, ",
          description:
            "dándole vueltas a pensamientos que se niegan a dejarte descansar.",
        },
      ],
    };
  },
};
</script>
