export default (state) => {
  const unit = state.unit;

  if (!unit) {
    return [];
  }

  if (!unit) {
    return [];
  }

  if (!unit.triggers) {
    return [];
  }

  if (!unit.triggers.questions) {
    return [];
  }

  if (unit.triggers.questions.length == 0) {
    return [];
  }

  return unit.triggers.questions;
};
