<template>
  <div class="block d-flex flex-column gap-2 go_payment">
    <div class="block-buttons d-flex flex-column gap-2">
      <component-button :class="['w-100']" :classes="['btn', 'btn-block', 'btn-primary']"
        @click="open_browser(get_ws_link())">
        <i class="fi fi-rr-headset"></i>
        <span> Habla con Soporte </span>
      </component-button>

      <div class="w-100 text-muted text-center" v-if="user_subscription?.status">
        Actualmente tienes la suscripción:
        <span class="badge dark small">{{ user_subscription?.level }}</span>
        activa, si quieres <b>cancelar</b> tu suscripción te invitamos a hacerlo a
        <b @click="open_browser(get_ws_link())">través de nuestro soporte.</b>

        <hr class="hr m-0 mt-2" />
      </div>


      <div class="w-100 mt-2 text-muted text-center terms">
        <div class="d-flex flex-wrap justify-content-center align-items-center gap-1">
          <span class="text-bold" @click="open_browser(`/terms`)">
            Términos y condiciones
          </span>
          /
          <span class="text-bold" @click="open_browser(`/privacy`)">
            Políticas de privacidad
          </span>
        </div>
      </div>

      <hr class="hr m-0 mt-2" />

      <div class="buttons d-flex gap-2 justify-content-between">
        <component-button :classes="[
          'btn',
          'btn-block',
          'btn-nopadding',
          'btn-left',
          'btn-transparent',
          'text-dark',
        ]" type="button" @click="go_explore()">
          Continuar explorando
        </component-button>
      </div>
    </div>
  </div>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

//
import go_explore from "../../../methods/go_explore.js";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    user_subscription() {
      return this.$store?.state?.auth?.user?.subscription;
    },
  },

  watch: {
    "$store.state.auth.user"() {
      this.init();
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    go_explore,
    async init() {
      const track = new this.controllers.Track();

      if (!this.user_subscription?.status) {
        await track.add({
          user_id: this.$store.state.auth.user.id,
          name: `subscription`,
          category: ["events", "lifecycle", "subscription_opened"].join(","),
          labels: `sale`,
          update: true,
        });
      }
    },

    get_ws_link() {
      const app_settings = this.$store.state.template.app_settings;
      const url = `https://wa.me/${app_settings.app_whatsapp}`
      return url
    }
  },
});
</script>
