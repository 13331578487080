<template>
  <div
    :class="['diagnosis', 'animate__animated', 'wh-100', ...classes]"
    @mouseenter="remove_classes_on_hover()"
  >
    <div class="block bg-image" :style="{ backgroundImage: `url(${route.image})` }">
      <div v-if="!subscription_is_necessary" class="block content">
        <div class="block title">
          <div class="t_continue">Continua</div>
          <div class="t_title">
            {{ route.title }}
          </div>
          <div class="t_description">
            {{ route.description }}
          </div>
          <div v-if="route.my_progress.misssing_units > 0" class="t_remain">
            Tienes
            <span>{{ route.my_progress.misssing_units }} unidades</span> por finalizar
          </div>
          <div v-if="route.my_progress.misssing_units == 0" class="t_remain">
            Genial, has terminado 🩵
          </div>
        </div>

        <div class="block goal">
          <div class="progress-bar">
            <div
              :style="{ width: `${route.my_progress.percent}%` }"
              class="progress"
            ></div>
          </div>

          <div
            v-if="route.my_progress.percent < 100"
            class="b_continue cursor-pointer"
            @click="open_modal_route()"
          >
            <span v-if="route.my_progress.percent == 0" class="text-muted">inicia</span>
            <span
              v-if="route.my_progress.percent > 0 && route.my_progress.percent < 100"
              class="text-muted"
              >continua</span
            >
            <i class="fi fi-rr-angle-small-right"></i>
          </div>
        </div>

        <div class="block buttons d-flex justify-content-between">
          <component-button
            :classes="['btn', 'btn-block', 'btn-nopadding', 'btn-black', 'transparent']"
            @click="open_modal_route()"
          >
            <i class="fi fi-rr-play"></i>
            <span v-if="route.my_progress.percent == 0">iniciar ruta</span>
            <span v-if="route.my_progress.percent > 0 && route.my_progress.percent < 100"
              >continuar</span
            >
            <span v-if="route.my_progress.percent == 100">ver ruta</span>
          </component-button>

          <component-button
            :classes="['btn', 'btn-block', 'btn-nopadding', 'btn-black', 'transparent']"
            @click="open_browser(`/contact`)"
          >
            <i class="fi fi-rr-headset"></i>
          </component-button>
        </div>

        <div class="block buttons d-flex justify-content-between">
          <component-button
            :classes="['btn', 'btn-nopadding', 'btn-transparent', 'btn-black']"
            type="button"
            @click="
              () => {
                $store.dispatch('modals/toggle', {
                  id: 'modal_onboarding_diagnosis',
                });
              }
            "
          >
            <i class="fi fi-rr-bulb"></i>
            <span>¿Como funciona?</span>
          </component-button>
        </div>
      </div>

      <div class="block notifications mt-2" v-if="screen_resolution(this) == 'mobile'">
        <component-appointments />
      </div>

      <component-need_subscription :route="route" v-if="subscription_is_necessary" />
    </div>
  </div>
</template>

<script>
// Components
import component_appointments from "../../../components/appointments/index.vue";
import need_subscription from "./need_subscription.vue";

export default {
  props: ["route"],

  components: {
    "component-appointments": component_appointments,
    "component-need_subscription": need_subscription,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription_is_necessary() {
      const user = this.$store.state.auth.user;
      const route = this.route;

      return (
        route &&
        route.is_public == 0 &&
        route.primary == 0 &&
        user &&
        user.subscription == null
      );
    },
  },

  data() {
    return {
      classes: [],
    };
  },

  mounted() {
    this.check_if_we_should_apply_any_effect();
  },

  methods: {
    check_if_we_should_apply_any_effect() {
      const onboarding_processed_02 = localStorage.getItem("onboarding_processed_02");

      if (!onboarding_processed_02 && this.user?.auth_counts == 1) {
        localStorage.setItem("onboarding_processed_02", true);
      } else {
        this.classes.push("animate__fadeIn");
      }
    },

    remove_classes_on_hover() {
      this.classes = this.classes.map((x) => {});
    },

    open_modal_route() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_player",
        update: {
          route: {
            $route: this.$route,
            $router: this.$router,
            query: {
              route_id: this.route.id,
            },
          },
        },
        callbacks: {
          onclose: () => {
            this.$store.dispatch("template/update_daemons", {
              index_continue: new Date(),
              index_notifications: new Date(),
            });
          },
        },
      });
    },
  },
};
</script>
