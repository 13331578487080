export default function go_explore() {
  this.$store.dispatch("modals/toggle", {
    id: "modal_subscription",
    force: "close",
  });

  setTimeout(() => {
    this.$router.push({
      name: "index",
      query: { tab: "suggestions" },
    });
  }, 500);
}
