<template>
  <div class="d-flex flex-column gap-3 suggestions">
    <component-101 v-if="is_block_101_visible" />
    <component-suggestions />
    <component-continue />

    <modal-suggestions_filters />
  </div>
</template>

<script>
// Methods
import component_continue from "./components/continue.vue";
import component_suggestions from "./components/suggestions.vue";
import component_101 from "./components/101.vue";

// Modals
import modal_suggestions_filters from "./modals/suggestions_filters/index.vue";

export default {
  components: {
    "component-continue": component_continue,
    "component-suggestions": component_suggestions,
    "component-101": component_101,

    "modal-suggestions_filters": modal_suggestions_filters,
  },

  computed: {
    is_block_101_visible() {
      const x = this.$store.state.template.app_settings?.[this.$route.name];
      return !x?.blocks?.["101"] === null || x?.blocks?.["101"]?.enabled == 1;
    },
  },
};
</script>
