<template>
  <div
    class="unit-description d-flex flex-column gap-2 animate__animated animate__fadeIn">
    <div class="d-flex flex-column gap-2 w-100">
      <h4 class="title">
        {{ unit.title }}
      </h4>

      <div class="description">
        {{ unit.description }}
      </div>

      <span
        class="badgets align-items-center"
        v-if="unit.recorded_by_user">
        <span :class="['badge']"> Por: {{ unit.recorded_by_user.name }} </span>
      </span>
    </div>

    <component-next_step />

    <component-box_cta_full_access
      v-if="!subscription_status || subscription_level === 'access'" />
  </div>
</template>

<script>
  import next_step from "./next_step.vue";

  export default {
    components: {
      "component-next_step": next_step,
    },

    computed: {
      unit() {
        return this.$store.state.player.unit;
      },

      subscription_level() {
        return (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.subscription &&
          this.$store.state.auth.user.subscription.level
        );
      },

      subscription_status() {
        return (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.subscription &&
          this.$store.state.auth.user.subscription.status
        );
      },
    },

    watch: {},
  };
</script>
