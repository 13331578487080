// Actions
import add_notification from "./actions/add_notification";
import update_settings from "./actions/update_settings";
import update_route from "./actions/update_route";
import update_units from "./actions/update_units";
import update_unit from "./actions/update_unit";
import update_mobile_mode from "./actions/update_mobile_mode";
import update_error from "./actions/update_error";
import pagination_next_unit from "./actions/pagination_next_unit";
import pagination_prev_unit from "./actions/pagination_prev_unit";
import pagination_to_unit from "./actions/pagination_to_unit";
import load_player from "./actions/load_player";
import update_player_callbacks from "./actions/update_player_callbacks";
import update_player_loading from "./actions/update_player_loading";
import update_player_info from "./actions/update_player_info";
import update_player_interval from "./actions/update_player_interval";
import update_player_component from "./actions/update_player_component";
import add_questions_answered from "./actions/add_questions_answered";
import add_error from "./actions/add_error";

// Mutations
import SET_MOBILE_MODE from "./mutations/set_mobile_mode";
import SET_ROUTE from "./mutations/set_route";
import SET_SETTINGS from "./mutations/set_settings";
import SET_UNIT from "./mutations/set_unit";
import SET_UNITS from "./mutations/set_units";
import ADD_ERRORS from "./mutations/add_errors";
import SET_ERROR from "./mutations/set_error";
import ADD_NOTIFICATION from "./mutations/add_notification";
import SET_PLAYER from "./mutations/set_player";
import SET_PLAYER_CALLBACKS from "./mutations/set_player_callbacks";
import SET_PLAYER_LOADING from "./mutations/set_player_loading";
import SET_PLAYER_INFO from "./mutations/set_player_info";
import SET_PLAYER_INTERVAL from "./mutations/set_player_interval";
import SET_PLAYER_COMPONENT from "./mutations/set_player_component";
import ADD_QUESTIONS_ANSWERED from "./mutations/add_questions_answered";

// Getters
import visibility from "./getters/visibility";
import unit_questions from "./getters/unit_questions";
import unit_visible_questions from "./getters/unit_visible_questions";
import units_carousel from "./getters/units_carousel";

const _state = {
  loading: true,
  route: null,
  units: [],
  unit: null,
  errors: [],
  notifications: [],
  questions_answered: [],
  player: {
    component: null,
    instance: null,
    intervals: {},
    info: {
      progress: 0,
      seconds_played: 0,
      duration: 0,
      is_playing: 0,
    },
    callbacks: {
      onplay: () => {},
      onplaying: () => {},
      onpause: () => {},
      onstop: () => {},
    },
  },

  settings: {
    mobile: {
      breakpoint: 768,
      mode: null,
    },
  },
};

export default {
  namespaced: true,

  state: () => {
    return { ..._state };
  },

  mutations: {
    SET_MOBILE_MODE,
    SET_ROUTE,
    SET_SETTINGS,
    SET_UNIT,
    SET_UNITS,
    ADD_ERRORS,
    ADD_NOTIFICATION,
    SET_ERROR,
    SET_PLAYER,
    SET_PLAYER_CALLBACKS,
    SET_PLAYER_INFO,
    SET_PLAYER_INTERVAL,
    SET_PLAYER_COMPONENT,
    SET_PLAYER_LOADING,
    ADD_QUESTIONS_ANSWERED,
    RESET: (state) => {
      Object.assign(state, _state);
    },
    SOFT_RESET: (state) => {
      Object.assign(state, {
        errors: [],
        notifications: [],
        questions_answered: [],
      });
    },
  },

  actions: {
    add_notification,
    update_settings,
    update_route,
    update_units,
    update_unit,
    update_mobile_mode,
    update_error,
    pagination_next_unit,
    pagination_prev_unit,
    pagination_to_unit,
    update_player_callbacks,
    update_player_info,
    load_player,
    update_player_interval,
    update_player_component,
    add_error,
    update_player_loading,
    add_questions_answered,
  },

  getters: {
    visibility,
    unit_questions,
    units_carousel,
    unit_visible_questions,
  },
};
