import Request from "../helpers/request";

export default class {
  #request = new Request();

  async server_time(body) {
    return await this.#request.post({
      path: "/api/time/now",
      body,
    });
  }
}
