<template>
  <component-modal
    ref="modal"
    id="modal_tickets"
    :dark="false"
    :clean="false"
    :permanent_mode="false"
    :with_close_button="true"
    :classes_modal="['center', 'p-2']"
    :classes_wrapper="['p-4']"
    :onopened="init"
  >
    <div class="content-modal">
      <div class="row" v-if="loading">
        <div class="col-12">
          <div class="box p-4 w-100 d-flex justify-content-center align-items-center">
            <div class="loader"></div>
          </div>
        </div>
      </div>

      <module-channels
        ref="module-channels"
        v-show="!loading && module == 'channels'"
        :check_and_move_to_module="check_and_move_to_module"
      />

      <module-chat
        ref="module-chat"
        v-if="!loading && module == 'chat'"
        :check_and_move_to_module="check_and_move_to_module"
      />
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

// components
import module_chat from "./components/chat/index.vue";
import module_channels from "./components/channels/index.vue";

export default defineComponent({
  components: {
    "module-chat": module_chat,
    "module-channels": module_channels,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    route_id() {
      return this.$route?.query?.route_id;
    },
  },

  data() {
    return {
      loading: true,
      module: "channels",
    };
  },

  methods: {
    init() {
      this.loading = true;

      setTimeout(() => {
        this.check_and_move_to_module();
        this.loading = false;
      }, 1000);
    },

    check_and_move_to_module(force = null) {
      if (force) {
        this.module = force;
      } else {
        if (this.route_id) {
          this.module = "chat";
        } else {
          this.module = "channels";
        }
      }

      if (this.module == "channels") {
        this.$refs["module-channels"]?.init();
      }
    },
  },
});
</script>
