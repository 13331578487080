export default async function () {
  const user = new this.controllers.User();
  const alert = this.alert;
  this.loading_avatar = true;

  const { status, response } = await user.update_profile({
    image: this.avatar,
  });

  if (response.user) {
    this.$store.dispatch("auth/login", {
      user: response.user,
    });
  }

  alert.success("Tu foto de perfil luce genial ahora.");

  setTimeout(() => {
    this.loading_avatar = false;
  }, 1500);
}
