<template>
  <div class="container p-0">
    <div class="row">
      <div class="col-12 d-flex flex-column gap-2">
        <div class="block-title">
          <h4 class="m-0 d-flex align-items-center gap-2">Canales</h4>
          <p class="description m-0 mt-1 text-muted">
            <b>Explora</b> todos los canales que tienes disponibles para que puedas
            continuar tus conversaciones.
          </p>
        </div>

        <div
          class="box p-4 w-100 d-flex justify-content-center align-items-center"
          v-if="loading"
        >
          <div class="loader"></div>
        </div>

        <div class="row" v-if="!loading && channels.length === 0">
          <div class="col-12">
            <div class="alert dark m-0">
              Aún no tienes canales creados, estos se crean en automático cuando envías
              una duda a través del botón de dudas dentro de las unidades.
            </div>
          </div>
        </div>

        <div class="channels" v-if="!loading && channels.length >= 1">
          <div class="title text-muted">Todos</div>

          <div
            class="channel"
            v-for="channel in channels"
            v-if="!loading"
            @click="go_channel(channel)"
          >
            <div class="left">
              <span class="hashtag">#</span>
              <span class="name">{{ channel.title }}</span>
            </div>
            <span class="notifications">
              <span
                class="badge dark small"
                v-if="
                  channel.pending_messages_to_read &&
                  channel.pending_messages_to_read >= 1
                "
                >{{ channel.pending_messages_to_read }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["check_and_move_to_module"],

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  data() {
    return {
      loading: true,
      channels: [],
    };
  },

  methods: {
    async init() {
      this.loading = true;

      const ticket = new this.controllers.Ticket();
      const { status, response } = await ticket.channels();

      this.channels = response.channels || [];
      this.loading = false;
    },

    go_channel(channel) {
      this.$router.replace({
        query: {
          route_id: channel.route_id,
        },
      });

      setTimeout(() => {
        this.check_and_move_to_module && this.check_and_move_to_module();
      }, 500);
    },
  },
};
</script>
