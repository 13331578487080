<template>
  <div :class="['audio']">
    <div class="audio-loader" v-if="is_loading">
      <span class="loader"></span>
    </div>

    <div v-if="!is_loading" class="cover" :style="{ backgroundImage: `url(${cover})` }">
      <div :class="['background', !playing && 'hard']">
        <div
          :class="[
            'back-navigation',
            'animate__animated',
            !playing && 'animate__fadeIn',
            playing && 'animate__fadeOutUp',
          ]"
          v-if="!playing && screen_resolution(this) == 'mobile'"
        >
          <component-button
            :classes="['btn', 'btn-block', 'btn-black', 'transparent', 'btn-nopadding']"
            type="button"
            @click="go_back()"
            :loading="false"
          >
            <i class="fi fi-rr-angle-left"></i>
          </component-button>
        </div>

        <div v-if="playing && psychologist" :class="['avatar']">
          <div class="left">
            <div
              class="image"
              :style="{ backgroundImage: `url(${psychologist.avatar})` }"
            ></div>
            <div class="profile">
              <div class="name">
                {{ psychologist.name }}
              </div>

              <div class="title">
                <span>
                  {{ psychologist.title }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="screen_resolution(this) == 'mobile' && !playing"
          class="d-flex flex-column gap-3 w-100"
        >
          <component-unit />
          <component-questions />
        </div>

        <div
          v-if="screen_resolution(this) == 'mobile' && playing"
          class="d-flex flex-column gap-3 w-100"
        >
          <component-questions />
          <component-subtitles />
        </div>

        <div
          :class="[
            'controls',
            'animate__animated',
            'animate__faster',
            'animate__fadeIn',
            playing && controls_and_elements_is_hidden && 'animate__fadeOut',
          ]"
        >
          <div class="block justify-content-start">
            <div v-if="route.is_public" class="button next" @click="share_route()">
              <i class="fi fi-rr-share"></i>
            </div>
          </div>

          <div class="block">
            <div
              :class="['button', 'prev', (!is_playing || is_seeking) && 'paused']"
              @click="rewind()"
            >
              <i class="fi fi-rr-rewind"></i>
            </div>

            <div
              class="button play"
              :class="is_seeking ? 'disabled' : ''"
              v-if="!is_playing"
              @click="toggle_play"
            >
              <i class="fi fi-rr-play"></i>
            </div>

            <div
              class="button pause"
              :class="is_seeking ? 'disabled' : ''"
              v-if="is_playing"
              @click="toggle_play"
            >
              <i class="fi fi-rr-pause"></i>
            </div>

            <div
              :class="['button', 'next', (!is_playing || is_seeking) && 'paused']"
              @click="forward()"
            >
              <i class="fi fi-rr-forward"></i>
            </div>
          </div>

          <div class="block justify-content-end">
            <div
              class="button next d-flex justify-content-center align-items-center gap-2"
              style="width: fit-content"
              @click="send_a_tickets()"
              v-if="seconds_played > 5"
            >
              <span class="text-bold text-light">{{ text_call_to_action_tickets }}</span>
              <i class="fi fi-rr-tickets-dots"></i>
            </div>
          </div>
        </div>

        <div :class="['progress-bar']">
          <div @click="move_progress_by_click" class="tracker"></div>

          <div class="progress" :style="{ width: `${progress}%` }"></div>
        </div>

        <div
          class="d-flex w-100 justify-content-end"
          v-if="unit.is_introduction || unit.is_conclusion"
        >
          <component-button
            :class="['d-flex']"
            :classes="['btn', 'btn-transparent', 'text-light', 'btn-nopadding']"
            type="button"
            :disabled="progress <= 5"
            @click="this.skip_unit()"
          >
            <i class="fi fi-rr-rotate-right"></i>
            <span>Saltar</span>
          </component-button>
        </div>

        <div
          class="finish_button d-flex w-100"
          v-if="!question && !is_playing && progress >= 95 && unit_next"
        >
          <component-button
            :class="['d-flex', 'w-100']"
            :classes="['btn', 'btn-block', 'btn-light']"
            type="button"
            @click="this.finish_unit()"
          >
            <i class="fi fi-rr-airplay"></i>
            <span>Siguiente unidad</span>
          </component-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Methods
import { useBackButton } from "@ionic/vue";
import get_units from "../methods/get_units";
import get_route from "../methods/get_route";
import save_progress from "../methods/save_progress";
import save_progress_finished from "../methods/save_progress_finished";
import track_onload from "../methods/track_onload.js";
import track_play from "../methods/track_play.js";
import track_finished from "../methods/track_finished.js";
import track_loadtime from "../methods/track_loadtime.js";
import move_progress_by_click from "../methods/move_progress_by_click.js";

// Components
import questions from "./questions.vue";
import subtitles from "./subtitles.vue";
import unit from "./unit.vue";

export default {
  components: {
    "component-subtitles": subtitles,
    "component-questions": questions,
    "component-unit": unit,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    text_call_to_action_tickets() {
      const texts = ["¿Dudas?", "¿Preguntas?"];
      const index = Math.floor(Math.random() * texts.length);
      return texts[index];
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },

    subscription_level() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.level
      );
    },

    unit() {
      return this.$store.state.player.unit;
    },

    units() {
      return this.$store.state.player.units;
    },

    unit_next() {
      const unit_index = this.$store.state.player.units.findIndex(
        (unit) => unit.id == this.$store.state.player.unit.id
      );

      return this.$store.state.player.units[unit_index + 1];
    },

    route() {
      return this.$store.state.player.route;
    },

    cover() {
      return this.unit.image;
    },

    media() {
      return this.unit.media;
    },

    format() {
      return this.unit.format;
    },

    psychologist() {
      return this.unit.recorded_by_user && this.unit.recorded_by_user;
    },

    player() {
      return this.$store.state.player.player.instance;
    },

    is_playing() {
      return this.$store.state.player.player.info.is_playing;
    },

    playing() {
      return this.$store.state.player.player.info.is_playing;
    },

    progress() {
      return this.$store.state.player.player.info.progress;
    },

    seconds_played() {
      return this.$store.state.player.player.info.seconds_played;
    },

    duration() {
      return this.$store.state.player.player.info.duration;
    },

    is_loading() {
      return this.$store.state.player.player.info.duration == 0;
    },

    controls_and_elements_is_hidden() {
      return this.seconds_played >= 3 && this.hover == false;
    },

    questions() {
      return this.$store.getters["player/unit_questions"];
    },

    question() {
      const questions = this.$store.getters["player/unit_visible_questions"];
      return questions.length > 0 ? questions[0] : null;
    },

    questions_answered() {
      return this.$store.state.player.questions_answered;
    },
  },

  watch: {
    "$store.state.player.unit": {
      handler(new_unit, old_unit) {
        if (!old_unit || !new_unit) {
          return;
        }

        if (new_unit.id != old_unit.id) {
          this.load_player();
        }
      },

      deep: true,
    },

    "$store.state.player.player.info.progress": {
      handler() {
        if (this.is_playing) {
          this.save_progress();
        }
      },

      deep: true,
    },
  },

  data() {
    return {
      timetoload: null,
      is_seeking: false,
      hover: true,
      intervals: {},
      finishing: false,
    };
  },

  mounted() {
    this.timetoload = new Date().getTime();
    this.track_onload();
    this.load_player();

    //
    useBackButton(10, () => {
      this.go_back();
    });
  },

  methods: {
    get_route,
    get_units,
    move_progress_by_click,
    save_progress_finished,
    save_progress,
    track_onload,
    track_play,
    track_finished,
    track_loadtime,
    go_back() {
      this.player.pause();
      this.$store.dispatch("player/update_mobile_mode", {
        mode: "route",
      });
    },

    load_player() {
      const cache_token = `player-unit-${this.unit.id}`;
      let cache = localStorage.getItem(cache_token) || null;
      cache = JSON.parse(cache);

      this.$store.dispatch("player/load_player", {
        callbacks: {
          onload: () => {
            this.timetoload = new Date().getTime() - this.timetoload;
            this.track_loadtime();

            if (cache && cache.seconds_played) {
              this.player.seek(cache.seconds_played);
            }

            if (this.$route.query.auto_play === "1") {
              this.player.play();

              this.$router.replace({
                query: {
                  ...this.$route.query,
                  auto_play: 0,
                },
              });
            }
          },

          onplay: () => {
            this.track_play();
          },

          onpause: () => {},
          onseek: () => {},

          onend: async () => {
            await this.finish_unit();
          },

          onstop: async () => {
            await this.finish_unit();
          },
        },
      });
    },

    async finish_unit() {
      if (this.finishing) {
        return;
      }

      this.finishing = true;

      this.track_finished();
      await this.save_progress_finished();

      this.finishing = false;
    },

    async skip_unit() {
      this.forward(99);
      await this.finish_unit();
    },

    toggle_play() {
      if (this.is_seeking) {
        return;
      }

      if (this.player && this.is_playing) {
        this.player.pause();
      } else {
        this.player.seek(this.seconds_played);
        this.player.play();
      }
    },

    forward(target_second = null) {
      if (!this.is_playing || this.is_seeking) {
        return;
      }

      this.is_seeking = true;

      let current = this.seconds_played;
      let recommended_seconds = parseInt(this.duration * (15 / 100));

      if (target_second) {
        recommended_seconds = parseInt(this.duration * (target_second / 100));
      }

      let target = current + recommended_seconds;

      if (target > this.duration) {
        target = this.duration - 1;
      }

      this.player.seek(target);

      const interval = setInterval(() => {
        if (this.is_playing) {
          this.is_seeking = false;
          clearInterval(interval);
        }
      }, 500);
    },

    send_a_tickets() {
      if (this.player && this.is_playing) {
        this.player.pause();
      }

      this.$store.dispatch("modals/toggle", {
        id: "modal_tickets",
        dark: true,
        close_others_modal: false,
      });
    },

    share_route() {
      if (this.player && this.is_playing) {
        this.player.pause();
      }

      this.$store.dispatch("modals/toggle", {
        id: "modal_share",
        dark: true,
        close_others_modal: false,
      });
    },

    rewind() {
      if (!this.is_playing) {
        return;
      }

      let current = this.seconds_played;
      let recommended_seconds = parseInt(this.duration * (15 / 100));

      if (current - recommended_seconds < 0) {
        this.player.seek(0);
      } else {
        this.player.seek(current - recommended_seconds);
      }
    },
  },
};
</script>
