export default (state) => {
  const unit = state.unit;
  const player = state.player.info;

  if (!player) {
    return [];
  }

  if (!unit) {
    return [];
  }

  if (!unit) {
    return [];
  }

  if (!unit.triggers) {
    return [];
  }

  if (!unit.triggers.questions) {
    return [];
  }

  if (unit.triggers.questions.length == 0) {
    return [];
  }

  const questions = unit.triggers.questions;
  const player_current_time = player.seconds_played;
  const questions_answered = state.questions_answered;

  return questions.filter(
    (q) =>
      q.trigger_settings.show_on_time < player_current_time &&
      !questions_answered.some((a) => a == q.id)
  );
};
