import Request from "../helpers/request";

export default class {
  #request = new Request();

  async store(body) {
    return await this.#request.post({
      path: "/api/appointment",
      body,
    });
  }

  async get_notifications(body) {
    return await this.#request.post({
      path: "/api/appointment/notifications",
      body,
    });
  }

  async best_spaces_to_reserve(body) {
    return await this.#request.post({
      path: "/api/appointment/best_spaces_to_reserve",
      body,
    });
  }

  async confirm(body) {
    return await this.#request.post({
      path: "/api/appointment/confirm",
      body,
    });
  }

  async cancel(body) {
    return await this.#request.post({
      path: "/api/appointment/cancel",
      body,
    });
  }
}
