import { API } from "../configs/domains";
import Repository from "../repositories/subscription";

export default class {
  #repository = new Repository();

  async get_subscriptions(body) {
    return await this.#repository.get_subscriptions(body);
  }

  async store(body) {
    const { status, response } = await this.#repository.store(body);

    if (response.user) {
      response.user = this.serialize({
        by: "user",
        input: response.user,
      });
    }

    return { status, response };
  }

  async cancel(body) {
    const { status, response } = await this.#repository.cancel(body);

    if (response.user) {
      response.user = this.serialize({
        by: "user",
        input: response.user,
      });
    }

    return { status, response };
  }

  serialize({ by, input = {} }) {
    switch (by) {
      case "user":
        input.avatar = `${API}/${input.avatar}`;
        break;
    }

    return input;
  }
}
