<template>
  <div :class="['component__input', error && 'error', icon && 'with_icon', ...classes]">
    <div class="input_content w-100">
      <label :for="id" class="m-0 mb-1" v-if="label">
        <span>
          {{ label }}
        </span>
      </label>

      <div class="input_incontent w-100">
        <div v-if="left_label" class="left_label">
          {{ left_label }}
        </div>

        <input
          v-if="
            type == 'text' || type == 'password' || type == 'email' || type == 'phone'
          "
          :type="type"
          :placeholder="placeholder"
          :disabled="is_disabled"
          v-model="value"
          :name="id"
          :id="id"
          v-on:keyup.enter="on_enter"
          :class="[
            'form-control',
            `input__${name}`,
            left_label && 'has-left_label',
            icon && 'has-icon',
          ]"
          autocomplete="off"
        />

        <textarea
          ref="textarea"
          v-if="type == 'textarea'"
          :placeholder="placeholder"
          :disabled="is_disabled"
          v-model="value"
          :name="id"
          :id="id"
          :class="[`textarea__${name}`]"
          autocomplete="off"
          v-on:keyup.enter="on_enter"
          rows="1"
          style="height: auto; overflow-y: hidden"
          @input="on_input"
        ></textarea>

        <select
          v-if="type == 'select'"
          :placeholder="placeholder"
          :disabled="is_disabled"
          v-model="value"
          :name="id"
          :id="id"
          :class="[`select__${name}`]"
          autocomplete="off"
        >
          <option
            v-for="option in options"
            :value="option.value"
            :selected="option.selected === true ? true : false"
          >
            {{ option.text }}
          </option>
        </select>

        <div
          v-if="type != 'password' && icon"
          class="icon"
          @click="
            () => {
              this.onclickicon && this.onclickicon();
            }
          "
        >
          <i :class="icon"></i>
        </div>

        <div
          v-if="type == 'password'"
          class="icon"
          @click="
            () => {
              this.onclickicon && this.onclickicon();
            }
          "
        >
          <i
            class="fi fi-rr-eye-crossed"
            v-if="show_password == true"
            @click="this.show_password = false"
          ></i>

          <i
            class="fi fi-rr-eye"
            v-if="show_password == false"
            @click="this.show_password = true"
          ></i>
        </div>
      </div>
    </div>

    <p
      class="input_password m-0 mt-1 text-muted d-flex justify-content-end"
      v-if="type == 'password' && value.length >= 0 && show_password == true"
    >
      <i class="fi fi-rr-caret-right"></i> {{ value }}
    </p>

    <p class="input_error m-0" v-if="error && error.length > 0">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    onupdate: {
      default: null,
    },

    onclickicon: {
      default: null,
    },

    classes: {
      default: () => [],
      type: Array,
    },

    options: {
      default: () => [],
      type: Array,
    },

    icon: {
      default: null,
      type: String,
    },

    type: {
      default: "text",
      type: String,
    },

    default: {
      default: "",
    },

    error: {
      default: "",
      type: String,
    },

    placeholder: {
      default: "",
      type: String,
    },

    label: {
      default: "",
      type: String,
    },

    name: {
      default: "",
      type: String,
    },

    left_label: {
      default: null,
      type: String,
    },

    onenter: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    disabled: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      id: uuidv4(),
      value: "",
      show_password: false,
    };
  },

  mounted() {
    this.value = this.default || "";
  },

  computed: {
    is_disabled() {
      return this.loading || this.disabled;
    },
  },

  watch: {
    value() {
      if (this.onupdate) {
        this.onupdate(this.value);
      }
    },
  },

  methods: {
    on_enter() {
      this.onenter && this.onenter();
    },

    on_input() {
      const textarea = this.$refs.textarea;

      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
  },
};
</script>
