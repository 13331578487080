<template>
  <div>
    <div class="d-flex gap-3 flex-column">
      <div class="title d-flex flex-column gap-1">
        <h4 class="m-0 d-flex gap-2 align-items-center">
          Continua
          <emoji size="38px" file="nerd_face.png" />
        </h4>
        <p class="text-muted m-0">
          ¿Buscas una ruta que ya exploraste? Echa un vistazo a las rutas que has empezado
          o que ya recorriste.
        </p>
      </div>

      <div
        v-if="loading"
        class="box p-4 w-100 d-flex justify-content-center align-items-center"
        style="min-height: 360px"
      >
        <div class="loader"></div>
      </div>

      <div class="block-routes" v-show="!loading && routes_in_progress.length > 0">
        <div
          v-if="loading"
          class="box p-4 w-100 d-flex justify-content-center align-items-center"
        >
          <div class="loader"></div>
        </div>

        <div class="d-flex flex-column">
          <component-carousels-routes
            name="suggestions_in_progress"
            :key="'suggestions_in_progress'"
            :lockable="true"
            :lockable_callbacks="open_modal_subscription"
            :from="get_routes_in_progress"
            :preview="true"
            :slider="false"
            :pagination="true"
          />
        </div>
      </div>

      <div class="block-routes r101" v-show="!loading && routes_in_progress.length === 0">
        <div class="alert dark m-0">
          No tenemos informacion para mostrar aqui por el momento
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Methods
import get_routes_in_progress from "../methods/get_routes_in_progress";

export default {
  data() {
    return {
      loading: true,
      routes_in_progress: [],
    };
  },

  methods: {
    get_routes_in_progress,
    open_modal_subscription() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_subscription",
      });
    },
  },
};
</script>
