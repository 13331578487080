
export default async function () {
  const user = new this.controllers.User();
  const testing = localStorage.getItem("testing");
  const alert = this.alert;
  const vm = this;

  if (!confirm("¿Estás seguro que quieres borrar tu cuenta?")) {
    return;
  }

  const { status, response } = await user.delete_account({
    user_id: this.user.id,
  });

  if (status == "SERVER_ERROR" || status == "ERROR") {
    if (response.errors) {
      Object.keys(response.errors).forEach((input) => {
        alert.info(response.errors[input][0]);
      });

      return;
    }

    if (response.message) {
      alert.error(response.message);
    }

    return;
  }

  localStorage.clear();
  sessionStorage.clear();
  testing && localStorage.setItem("testing", "1");

  alert.info(
    "Tu cuenta y todos los datos asociados han sido eliminados con éxito. Nos importa tu opinión. ¿Te gustaría compartir tus comentarios antes de despedirte? (Haz clic en este mensaje)",
    {
      onClick: () => {
        vm.open_browser("/feedback");
      },
    }
  );

  this.logout();
}
