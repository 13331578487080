<template>
  <div class="progress-indicators d-flex gap-2 flex-column">
    <div class="progress-labels d-flex justify-content-between gap-2">
      <div
        class="label d-flex justify-content-center align-items-center gap-2 w-100"
        :class="[step == 0 && 'active']"
        @click="change_step_to(0)">
        <span class="icon">
          <i class="fi fi-rr-flag"></i>
        </span>
        <span class="title">Inicia</span>
      </div>

      <div
        class="label d-flex justify-content-center align-items-center gap-2 w-100"
        :class="[step == 1 && 'active']">
        <span class="icon">
          <i class="fi fi-rr-cloud-upload"></i>
        </span>
        <span class="title">Configura</span>
      </div>

      <div
        class="label d-flex justify-content-center align-items-center gap-2 w-100"
        :class="[step == 2 && 'active']">
        <span class="icon">
          <i class="fi fi-rr-route"></i>
        </span>
        <span class="title">El plan</span>
      </div>
    </div>

    <div :class="['progress-bar', 'dark', 'd-flex', 'w-100']">
      <div
        :style="{ width: `${progress}%` }"
        class="progress"></div>
    </div>

    <div class="d-none justify-content-between">
      <component-button
        :classes="[
          'btn',
          'btn-block',
          'btn-transparent',
          'text-light',
          'btn-nopadding',
        ]"
        @click="$router.push({ name: 'login' })"
        type="button">
        <span>Inicia sesion</span>
      </component-button>

      <component-button
        :classes="[
          'btn',
          'btn-block',
          'btn-transparent',
          'text-light',
          'btn-nopadding',
        ]"
        @click="$router.push({ name: 'register' })"
        type="button">
        <span>Registrate</span>
      </component-button>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["step", "change_step_to"],

    computed: {
      progress() {
        return (this.step / 3) * 100 || 5;
      },
    },
  };
</script>
