import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import {
  GOOGLE_AUTH_IOSCLIENTID,
  GOOGLE_AUTH_CLIENTID,
  GOOGLE_AUTH_SCOPES,
  GOOGLE_AUTH_GRANTOFFLINEACCESS,
  GOOGLE_AUTH_ANDROIDCLIENTID,
} from "@/configs/googleauth";

export default async function () {
  const track = new this.controllers.Track();
  this.loading = true;

  GoogleAuth.initialize({
    iosClientId: GOOGLE_AUTH_IOSCLIENTID,
    clientId: GOOGLE_AUTH_CLIENTID,
    androidClientId: GOOGLE_AUTH_ANDROIDCLIENTID,
    scopes: GOOGLE_AUTH_SCOPES,
    grantOfflineAccess: GOOGLE_AUTH_GRANTOFFLINEACCESS,
  });

  await track.add({
    name: `register`,
    category: ["events", "lifecycle", "page_view"].join(","),
    labels: `acquisition`,
    update: true,
  });

  if (
    this.$route.query.imageUrl &&
    this.$route.query.name &&
    this.$route.query.email &&
    this.$route.query.id
  ) {
    this.with_google();
  }

  setTimeout(() => {
    this.loading = false;
  }, 1000);
}
