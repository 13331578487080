<template>
  <div class="d-flex flex-column gap-3 suggestions">
    <div class="d-flex gap-3 flex-column">
      <div class="title d-flex flex-column gap-1">
        <h4 class="m-0">
          Crisis?
          <emoji size="38px" file="face_with_spiral_eyes.png" />
        </h4>
        <p class="text-muted m-0" style="max-width: 500px">
          Entendemos que puedas estar experimentando una situación difícil como un ataque
          de pánico, te podemos ayudar!
        </p>
      </div>

      <div class="alert primary" v-if="current_device() == 'web'">
        En la versión móvil de nuestra app puedes encontrar este módulo desbloqueado,
        <a :href="`${PUBLIC_URL}/join`" target="_blank">descárgala ahora! </a>
      </div>
    </div>
  </div>
</template>

<script>
import { PUBLIC_URL } from "@/configs/domains.js";

export default {
  data() {
    return {
      PUBLIC_URL,
    };
  },
};
</script>
