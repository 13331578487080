<template>
  <div v-for="x in get_social_proof" class="content d-flex flex-column gap-2">
    <div class="d-flex w-100 gap-2 align-items-center justify-content-start box dark p-3">
      <emoji @click="open_modal()" size="38px" :file="`${x.emoji}.png`" />

      <p
        class="m-0 text-muted text-left description"
        @click="open_modal()"
        v-html="x.description"
      ></p>

      <div @click="alert.info(x.description.replace(/<[^>]*>/g, ''))">
        <i class="fi fi-rr-information"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    get_social_proof() {
      let temp_array = [...this.social_proof];

      for (let i = temp_array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [temp_array[i], temp_array[j]] = [temp_array[j], temp_array[i]];
      }

      return temp_array.slice(0, 1);
    },
  },

  data() {
    return {
      social_proof: [
        {
          emoji: "sparkles",
          description:
            "Supera la ansiedad con consultas psicológicas, como el <b>90% de nuestros usuarios</b>.",
        },
        {
          emoji: "thinking_face",
          description:
            "Descubre el poder de la terapia personalizada dando un paso hacia tu bienestar hoy.",
        },
        {
          emoji: "gear",
          description:
            "Implementa técnicas psicológicas avanzadas con nuestros psicólogos.",
        },
        {
          emoji: "face_with_spiral_eyes",
          description:
            "Controla el estrés y pánico de la mano de expertos durante el proceso.",
        },
        {
          emoji: "ladder",
          description:
            "Alcanza tus metas de superación personal más rápido con nuestra guía.",
        },
        {
          emoji: "open_book",
          description:
            "Únete a quienes han encontrado soluciones con nuestras terapias avanzadas.",
        },
        {
          emoji: "white_heart",
          description:
            "Camina hacia el bienestar emocional con el apoyo de especialistas en psicología.",
        },
        {
          emoji: "dizzy",
          description:
            "Explora técnicas que mejoran sueño, concentración y serenidad con nuestro acompañamiento.",
        },
        {
          emoji: "chart_increasing",
          description:
            "Ve un antes y después en tu salud mental con nuestro apoyo continuo.",
        },
      ],
    };
  },

  methods: {
    open_modal() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_subscription",
        force: "open",
        close_others_modal: false,
      });
    },
  },
};
</script>

<style scoped>
.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
