import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import set_push_notifications from "@/helpers/push_notifications";
import restore from "../../../modals/subscription/methods/restore";

let options = {
  clientId: "io.ionic.memeonlyme",
  scopes: "email name",
};
export default async function () {
  const vm = this;
  const user = new vm.controllers.User();
  const track = new this.controllers.Track();

  vm.with_apple_loading = true;
  vm.forms.auth.inputs.email.value = "";
  vm.forms.auth.inputs.password.value = "";

  SignInWithApple.authorize(options)
    .then(async (res) => {
      const apple_response = res.response;

      if (!apple_response) {
        vm.alert.error("Tenemos un problema con la autenticación.");
        vm.with_apple_loading = false;
      }

      const identity_token = apple_response.user;

      // Login
      const { status, response } = await user.authenticate({
        apple_identity_token: identity_token,
      });

      if (status == "SERVER_ERROR" || status == "ERROR") {
        vm.alert.info(
          "El email que ingresastes para iniciar sesión no está registrado. "
        );

        vm.with_apple_loading = false;
        return;
      }

      vm.with_apple_loading = false;

      vm.sentry_set_user(response.user);
      vm.ga_event("login", {});

      vm.$store.dispatch("auth/login", {
        user: response.user,
        authorization: response.authorization,
      });

      track.add({
        name: `login`,
        category: ["events", "new_login"].join(","),
      });

      // Push
      set_push_notifications({ vm });

      // Redirection
      const redirect_url = localStorage.getItem("redirect_after_login");

      if (redirect_url) {
        vm.$router.push(redirect_url);
        localStorage.removeItem("redirect_after_login");
        return;
      } else {
        vm.$router.push({
          name: "index",
          query: {
            tab: "index",
          },
        });
      }
    })
    .catch((error) => {
      console.error(error);
      vm.sentry_track_error(error);
      vm.alert.error(
        "Tenemos un problema para conseguir la autorización para iniciar sesión con Apple ID. "
      );
      vm.with_apple_loading = false;
    });
}
