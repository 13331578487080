export default function () {
  if (this.player.playing()) {
    this.is_playing = false;
    this.player.pause();
  } else {
    this.is_playing = true;

    Object.values(this.$store.state.cover.players).forEach((player) => {
      player.pause();
    });

    this.player.seek(0);
    this.player.play();
  }
}
