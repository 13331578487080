<template>
  <component-modal
    id="modal_relogin"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="false"
    :permanent_mode="true"
    :with_close_button="false"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title text-center">
              <h4 class="m-0">
                Ocurrio un problema
                <emoji size="38px" file="fearful_face.png" />
              </h4>
              <p class="description m-0 mt-1 text-muted" v-if="error_type == 0">
                ¡Ups! Parece que tu sesión se ha cerrado automáticamente para mantener tus
                datos seguros. <b>¿Podrías intentar iniciar sesión de nuevo?</b> Si sigues
                teniendo problemas, no dudes en decirnos. ¡Estamos aquí para ayudarte!
              </p>

              <p class="description m-0 mt-1 text-muted" v-if="error_type == 1">
                <b
                  >Parece que no hay conexión a internet en este momento. No te preocupes,
                  una vez que vuelvas a estar en línea, todo seguirá fluyendo.</b
                >
                ¡Mantén la calma!
              </p>
            </div>

            <div class="d-flex w-100 gap-2 flex-column mt-2">
              <component-button
                :classes="['btn', 'btn-block', 'btn-primary']"
                :disabled="loading"
                :loading="loading"
                @click="relogin()"
              >
                <i class="fi fi-rr-refresh"></i>
                <span>Iniciar sesion nuevamente</span>
              </component-button>

              <component-button
                :classes="['btn', 'btn-block', 'btn-transparent', 'text-dark']"
                :loading="false"
                type="button"
                @click="open_browser(`/contact`)"
              >
                <i class="fi fi-rr-headset"></i>
                <span>Necesito ayuda</span>
              </component-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

// Methods
import check_connectivity from "./methods/check_connectivity.js";
import check_auth from "./methods/check_auth.js";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  data() {
    return {
      loading: false,
      error_type: 0,
      interval: null,
    };
  },

  mounted() {
    this.check_connectivity();
    this.check_auth();

    this.interval = setInterval(() => {
      this.check_connectivity();
    }, 1000 * 15);
  },

  methods: {
    check_auth,
    check_connectivity,

    async relogin() {
      this.loading = true;

      const auth_refreshtoken = this.user.auth_refreshtoken;
      const user = new this.controllers.User();

      if (auth_refreshtoken) {
        const { status, response } = await user.authenticate({
          auth_refreshtoken,
        });

        if (status == "SERVER_ERROR" || status == "ERROR") {
          this.close_session_and_redirect_to_login();
          this.loading = false;
          return;
        }

        this.$store.dispatch("auth/login", {
          user: response.user,
          authorization: response.authorization,
        });

        this.close_modal_and_redirect_to_index();
        this.loading = false;
        return;
      }

      this.close_session_and_redirect_to_login();
      this.loading = false;
    },

    close_modal_and_redirect_to_index() {
      this.$refs.modal && this.$refs.modal.close();
      this.$router.push("/index?tab=index");
    },

    close_session_and_redirect_to_login() {
      this.$store.dispatch("auth/logout");
      this.$refs.modal && this.$refs.modal.close();
      this.$router.push("/login");
    },
  },
});
</script>
