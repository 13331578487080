export default async function () {
  const track = new this.controllers.Track();
  const unit = this.$store.state.player.unit;
  const route = this.$store.state.player.route;

  await track.add({
    user_id: this.user.id,
    unit_id: unit ? unit.id : null,
    route_id: route ? route.id : null,
    name: `share`,
    value: "",
    category: "events",
    labels: "route, sharing",
  });
}
