<template>
  <div class="content gap-2 step2">
    <emoji
      size="72px"
      file="fire.png" />

    <div
      class="d-flex flex-column justify-content-center align-items-center gap-0 w-100">
      <h3 class="title m-0">El Plan</h3>

      <p class="subtitle m-0 text-muted">
        <b>Tenemos un plan para ti,</b> mira aquí solo las primeras rutas que
        tenemos para ti.
      </p>
    </div>

    <div
      class="content-loading mt-4"
      v-if="loading">
      <span class="loader"></span>
    </div>

    <component-carousels-routes
      class="mt-2"
      v-show="!loading"
      ref="carousels-routes"
      name="suggestions"
      :key="'suggestions'"
      :from="get_routes"
      :slider="true"
      :preview="true"
      :pagination="false" />

    <p class="subtitle m-0 mt-2 text-muted">
      Tienes el plan, ahora <b>¿como quieres empezar a retomar el control?</b>
    </p>

    <component-button
      :classes="['btn', 'btn-block', 'btn-light']"
      @click="$router.push({ name: 'register' })"
      type="button">
      <i class="fi fi-rr-arrow-right"></i>
      <span>Empecemos</span>
    </component-button>
  </div>
</template>

<script>
  import get_routes from "../methods/get_routes";

  export default {
    props: ["id", "step", "change_step_to"],

    data() {
      return {
        loading: true,
      };
    },

    mounted() {
      // Tracking
      const track = new this.controllers.Track();

      track.add({
        name: `onboarding_step_2`,
        category: ["events", "lifecycle", "page_view"].join(","),
        labels: `acquisition`,
        update: true,
      });
    },

    methods: {
      get_routes,
    },
  };
</script>
