export default function (page, type) {
  let data =
    type == "month"
      ? Object.keys(this.spaces)
      : this.spaces[this.selected_month];

  const go_page = this.pagination[type].current_page + page;

  if (
    go_page < 0 ||
    go_page >= Math.ceil(data.length / this.pagination[type].per_page)
  ) {
    return;
  }

  this.pagination[type].current_page = go_page;
}
