export default async function ({ next = false }) {
  const { Route } = this.controllers;
  const route = new Route();
  const { params, query } = this.$route;
  const route_id = params.route_id || query.route_id;
  const unit_id = this.unit?.id;
  let unit_index = 0;
  const { status, response } = await route.get_units({ route_id });

  if (response.units) {
    this.$store.dispatch("player/update_units", {
      action: "update",
      units: response.units.sort((a, b) => a.priority - b.priority),
    });
  }

  if (unit_id && next) {
    unit_index = this.units.findIndex((unit) => unit.id === unit_id);
  }

  const next_unit_index = next ? unit_index + 1 : unit_index;

  if (next_unit_index === this.units.length) {
    this.$store.dispatch("player/update_player_loading", false);
    return;
  }

  try {
    const next_unit = this.units[next_unit_index];

    this.$router.replace({
      query: {
        ...query,
        unit_id: next_unit.id,
        auto_play: 1,
      },
    });

    this.$store.dispatch("player/update_unit", { by_default: next_unit.id });
  } catch (error) {}

  this.$store.dispatch("player/update_player_loading", false);
}
