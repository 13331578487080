<template>
  <div class="section-title mb-2">
    <h3 class="m-0">Accede</h3>
    <p class="text-muted m-0">
      ¡Sigamos avanzando hacia tus objetivos! Deja atrás la ansiedad, la
      depresión y cualquier cosa que te detenga.
    </p>
  </div>
</template>

<script>
  export default {};
</script>
