import Repository from "../repositories/common";

export default class {
  #repository = new Repository();

  async version(body = {}) {
    return await this.#repository.version(body);
  }

  async get_settings(body) {
    return await this.#repository.get_settings(body);
  }
}
