export default async function () {
  const common = new this.controllers.Common();
  const utm_device = localStorage.getItem("utm_device");
  const utm_version = localStorage.getItem("utm_version");
  const utm_testing = localStorage.getItem("utm_testing");

  const { status, response } = await common.get_settings({
    utm_device,
    utm_testing,
    utm_version,
  });

  if (status == "ERROR" || response.status == "ERROR") {
    return;
  }

  if (!localStorage.getItem("utm_testing")) {
    localStorage.setItem("utm_testing", response.utm_testing);
  }

  this.$store.dispatch("template/update_app_settings", {
    ...response.config,
    ...response.env
  });
}
