export default async function ({ page = 1, limit = 1, callback = () => {} }) {
  const alert = this.alert;
  const route = new this.controllers.Route();

  this.loading = true;

  if (this.screen_resolution(this) == "mobile") {
    limit = 2;
  }

  const { status, response } = await route.search_by({
    page,
    limit,
    search_by: JSON.stringify([
      {
        column: "is_101",
        value: 1,
      },
    ]),
  });

  if (status != "SUCCESS" && response.message) {
    alert.error(response.message);
  }

  this.routes_101 = response.routes || [];

  setTimeout(() => {
    callback(response);
    this.loading = false;
  }, 1000);
}
