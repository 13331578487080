// Actions
import login from "./actions/login";
import logout from "./actions/logout";
import restore from "./actions/restore";

// Mutations
import { SET_USER } from "./mutations/user";
import { SET_AUTHORIZATION } from "./mutations/authorization";

export default {
  namespaced: true,

  state: () => {
    return {
      user: null,
      authorization: null,
    };
  },

  mutations: {
    SET_USER,
    SET_AUTHORIZATION,
  },

  actions: {
    login,
    logout,
    restore,
  },
};
