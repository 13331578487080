<template>
  <div class="subtitles d-none">
    <div class="content">Sub titulo aqui</div>
  </div>
</template>

<script>
  // Libs
  import { defineComponent } from "vue";

  export default defineComponent({});
</script>
