<template>
  <div
    class="block-chart"
    v-if="
      visibility == 'mobile' &&
      !loading_chart_data &&
      chart_data.datasets[0].data.length > 0
    "
  >
    <Line :data="chart_data" :options="chart_options" />
  </div>

  <div
    class="block-chart mt-2"
    v-if="
      (visibility == 'mobile' && loading_chart_data) ||
      (!loading_chart_data && chart_data.datasets[0].data.length == 0)
    "
  >
    <div class="d-flex justify-content-end align-items-center mt-2 mb-2">
      <img src="/assets/illustrations/chartbg.png" style="width: 100%; opacity: 0.1" />
    </div>
  </div>

  <div v-if="loading" class="content-index" :class="visibility == 'desktop' && 'p-0'">
    <div class="row">
      <div class="col-12">
        <div
          class="block-score mt-3 d-flex justify-content-center align-items-center"
          style="height: 150px; padding: 0"
        >
          <span class="loader"></span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!loading" class="content-index" :class="visibility == 'desktop' && 'p-0'">
    <div class="row">
      <div class="col-12">
        <div class="block-score mt-2">
          <div class="top">
            <div class="left" @click="show_more_info_about_chart()">
              <span class="point"> {{ parseFloat(points.score).toFixed(0) }}</span>
            </div>
            <div class="right">
              <span class="title">Puntos de salud</span>
              <span class="description"
                >Basado en tu psicólogo y uso de las distintas variables de la
                aplicación.</span
              >
            </div>
          </div>
          <div class="down">
            <div class="block">
              <span class="a">
                <emoji size="28px" file="chequered_flag.png" />
              </span>
              <span class="a">tu meta</span>
              <span class="b">{{ parseFloat(points.target).toFixed(0) }}</span>
            </div>
            <div class="block">
              <span class="a">
                <emoji size="28px" file="flexed_biceps.png" />
              </span>
              <span class="a">tu puntaje</span>
              <span class="b">{{ parseFloat(points.points).toFixed(0) }}</span>
            </div>
            <div class="block">
              <span class="a">
                <emoji size="28px" file="eyes.png" />
              </span>
              <span class="a">te falta</span>
              <span class="b">{{ parseFloat(points.remain).toFixed(0) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  components: {
    Line,
  },

  computed: {
    visibility() {
      return this.$windowWidth >= 768 ? "desktop" : "mobile";
    },
  },

  data() {
    return {
      loading: true,
      loading_chart_data: true,
      points: {},

      chart_data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "#eff6f4",
            borderColor: "#03181f",
            borderWidth: 3,
            fill: false,
          },
        ],
      },

      chart_options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0,
          },
        },

        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },

        plugins: {
          legend: {
            display: false,
          },
        },

        elements: {
          line: {
            tension: 0.5,
          },

          point: {
            radius: 0,
          },
        },

        animation: {
          duration: 0,
        },
      },
    };
  },

  mounted() {
    this.get_my_points();
    this.get_my_points_progress();
  },

  methods: {
    async get_my_points() {
      const user = new this.controllers.User();

      this.loading = true;

      const { status, response } = await user.get_my_points({});

      if (response.points) {
        this.points = response.points;
        this.loading = false;
      }
    },

    async get_my_points_progress() {
      const user = new this.controllers.User();

      this.loading_chart_data = true;
      this.chart_data.labels = [];
      this.chart_data.datasets[0].data = [];

      const { status, response } = await user.get_my_points_progress({});

      if (response.history && response.history.length > 0) {
        response.history.forEach((h, index) => {
          this.chart_data.labels.push(h.time);
          this.chart_data.datasets[0].data.push(h.point);
        });
      }

      this.loading_chart_data = false;
    },

    show_more_info_about_chart() {
      const alert = this.alert;
      let description = `Tu «puntuación de salud» es un número entre 0 y 100, diseñado
          para darte una idea clara de tu progreso en la plataforma. Calculamos
          esta puntuación basándonos en tu actividad en la plataforma, los
          cambios en tus síntomas y, si tienes una suscripción activa, el
          feedback de tu psicólogo asignado.`;

      description = description.replace(/\n/g, " ");
      description = description.replace(/\s+/g, " ");

      alert.info(description.replace("\n", " "), {
        timeout: 25000,
      });
    },
  },
};
</script>
