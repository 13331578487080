<template>
  <div class="d-flex gap-3 flex-column">
    <div class="title d-flex flex-column gap-1">
      <h4 class="m-0 d-flex gap-2 align-items-center">
        101
        <emoji size="38px" file="writing_hand.png" />
      </h4>
      <p class="text-muted m-0">
        Todo inicia con un síntoma, siempre es mejor saber cuáles son y como llegan antes
        de pasar a la acción en busca de una solución.
      </p>
    </div>

    <div
      v-if="loading"
      class="box p-4 w-100 d-flex justify-content-center align-items-center"
      style="min-height: 360px"
    >
      <div class="loader"></div>
    </div>

    <div class="d-flex" v-show="!loading && routes_101.length > 0">
      <component-carousels-routes
        ref="carousels-routes-101"
        name="suggestions_101"
        :key="'suggestions_101'"
        :from="get_routes_101"
        :preview="true"
        :slider="false"
        :pagination="true"
      />
    </div>

    <div class="block-routes r101" v-show="!loading && routes_101.length === 0">
      <div class="alert dark m-0">
        No tenemos informacion para mostrar aqui por el momento
      </div>
    </div>
  </div>
</template>

<script>
// Methods
import get_routes_101 from "../methods/get_routes_101";

export default {
  data() {
    return {
      loading: true,
      routes_101: [],
    };
  },

  methods: {
    get_routes_101,
  },
};
</script>
