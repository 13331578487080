import Track from "@/controllers/track";
import Appointment from "@/controllers/appointment";
import Notification from "@/controllers/notification";
import Route from "@/controllers/route";
import subscription from "@/controllers/subscription";
import Time from "@/controllers/time";
import User from "@/controllers/user";
import Common from "@/controllers/common";
import Ticket from "@/controllers/ticket";

export default {
  install: (app) => {
    app.config.globalProperties.controllers = {
      Track,
      Appointment,
      Notification,
      Route,
      subscription,
      Time,
      User,
      Common,
      Ticket,
    };
  },
};
