export default async function () {
  const sandbox = this.$store.state.template.testing_mode;

  if (!sandbox && this.current_device() == "web") {
    this.$store.dispatch("modals/toggle", {
      id: "modal_onlyweb",
      force: "open",
    });
  }
}
