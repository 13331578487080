export default async function () {
  const track = new this.controllers.Track();
  const route_id = this.route ? this.route.id : null;
  const unit_id = this.unit ? this.unit.id : null;

  track.add({
    name: `route_flow`,
    category: ["events", "route", "started"].join(","),
    labels: `acquisition`,
    update: true,
    route_id,
    unit_id,
  });
}
