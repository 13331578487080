<template>
  <div class="component__player">
    <div
      v-if="is_loading"
      class="loader-mobile animate__animated animate__fadeIn">
      <span class="loader"></span>
    </div>

    <component-fullalert v-if="!is_loading" />

    <div
      class="block-initial-route"
      :style="{
        backgroundImage: `url(${unit && unit.image})`,
      }"
      v-if="!is_loading && unit && mode == 'route'">
      <div class="background d-flex flex-column gap-3">
        <component-header />
        <component-footer />
      </div>
    </div>

    <div
      class="player-content-videoaudio d-flex flex-column gap-3 text-light"
      v-if="!is_loading && unit && mode == 'player'">
      <div class="content-player">
        <div class="player">
          <component-audio />
        </div>
      </div>
    </div>

    <component-units />
  </div>
</template>

<script>
  // Components
  import audio from "./components/audio.vue";
  import video from "./components/video.vue";
  import header from "./components/header.vue";
  import units from "./components/units.vue";
  import unit from "./components/unit.vue";
  import footer from "./components/footer.vue";
  import fullalert from "./components/fullalert.vue";
  import next_step from "./components/next_step.vue";

  export default {
    components: {
      "component-audio": audio,
      "component-video": video,
      "component-header": header,
      "component-unit": unit,
      "component-units": units,
      "component-footer": footer,
      "component-fullalert": fullalert,
      "component-next_step": next_step,
    },

    computed: {
      is_loading() {
        return (
          this.$store.state.player.loading ||
          !this.$store.state.player.route ||
          this.$store.state.player.units.length == 0 ||
          !this.$store.state.player.unit
        );
      },

      unit() {
        return this.$store.state.player.unit;
      },

      mode() {
        return this.$store.state.player.settings.mobile.mode;
      },

      subscription_level() {
        return (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.subscription &&
          this.$store.state.auth.user.subscription.level
        );
      },

      subscription_status() {
        return (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.subscription &&
          this.$store.state.auth.user.subscription.status
        );
      },
    },

    mounted() {
      this.$store.dispatch("player/update_mobile_mode", {
        mode: "route",
      });
    },
  };
</script>
