import { createStore } from "vuex";

import auth from "./auth/index";
import template from "./template/index";
import modals from "./modal/index";
import player from "./player/index";
import cover from "./cover/index";

export default createStore({
  modules: {
    template,
    auth,
    modals,
    player,
    cover,
  },
});
