<template>
  <component-modal
    id="continue_to_full"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="false"
    :permanent_mode="true"
    :with_close_button="false"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12 d-flex gap-2 flex-column">
            <div
              class="block-title d-flex gap-1 flex-column justify-content-center align-items-center"
            >
              <emoji size="48px" file="clapping_hands.png" />
              <h4 class="m-0">Genial</h4>
            </div>

            <p class="description m-0 text-muted text-center">
              Es genial que quieras seguir en este proceso hacia tu bienestar emocional,
              pero un momento,
              <b>necesitamos que tengas una subscripción activa para poder continuar</b> y
              dar inicio a toda la aventura que está por venir.
            </p>

            <div class="d-flex w-100 gap-2">
              <component-button
                class="w-100"
                :classes="['btn', 'btn-block', 'btn-light']"
                @click="go_subscription()"
              >
                <i class="fi fi-rr-bolt"></i>
                <span>Vamos</span>
              </component-button>

              <component-button
                class="w-100"
                :classes="['btn', 'btn-block', 'btn-transparent', 'text-light']"
                @click="go_explora()"
              >
                <i class="fi fi-rr-exit"></i>
                <span>Continuar explorando</span>
              </component-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    go_subscription() {
      this.$store.dispatch("modals/toggle", {
        id: "continue_to_full",
        force: "close",
      });

      this.$store.dispatch("modals/toggle", {
        id: "modal_subscription",
        force: "open",
      });

      this.$router.push({
        name: "index",
        query: { tab: "suggestions" },
      });
    },

    go_explora() {
      this.$store.dispatch("modals/toggle", {
        id: "continue_to_full",
        force: "close",
        close_others_modal: true,
      });

      setTimeout(() => {
        this.$router.push({
          name: "index",
          query: { tab: "suggestions" },
        });
      }, 500);
    },
  },
});
</script>
