// Libs
import { Howl } from "howler";

export default function ({ commit, state, dispatch }, { callbacks = {} }) {
  const unit = state.unit;
  const player = state.player;
  const intervals = state.player.intervals;

  if (player.instance) {
    player.instance.pause();
    player.instance.unload();

    commit("SET_PLAYER_INFO", {
      progress: 0,
      seconds_played: 0,
      duration: 0,
    });

    Object.values(intervals).forEach((interval) => {
      clearInterval(interval);
    });
  }

  const instance = new Howl({
    src: [unit.media],
    format: [unit.format],
    html5: true,
    buffer: true,
    preload: true,
    xhr: {
      withCredentials: false,
    },
    ...callbacks,
  });

  commit("SET_PLAYER", instance);

  const interval = setInterval(() => {
    dispatch("update_player_info");
  }, 1000);

  commit("SET_PLAYER_INTERVAL", {
    id: "onplaying",
    interval,
  });
}
