export default function () {
  if (!this.from) return;

  this.loading = true;

  this.from({
    page: this.page,
    search_by: this.search_by,
    order_by: this.order_by,
    callback: (items) => {
      this.items = items.routes || items.units || [];
      this.pages = parseInt(items.pages) || 0;
      this.page = parseInt(items.page) || 1;

      this.$forceUpdate();
      this.loading = false;
    },
  });
}
