export default function () {
  if (this.is_seeking) {
    return;
  }

  if (this.is_playing) {
    this.player.pause();
  } else {
    this.player.play();
  }
}
