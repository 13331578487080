export default function () {
  if (!this.is_playing) {
    return;
  }

  let current = this.seconds_played;
  let recommended_seconds = parseInt(this.duration * (15 / 100));

  if (current - recommended_seconds < 0) {
    this.player.seek(0);
  } else {
    this.player.seek(current - recommended_seconds);
  }
}
