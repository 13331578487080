export default ({ vm, fn, rt = null, cb = null }) => {
  const app_settings = vm.$store.state.template.app_settings;
  const route_name = rt || vm.$route.name;
  const page_settings = app_settings?.[route_name];
  const route = vm.$route;
  const router = vm.$router;
  const update = page_settings?.update;
  const events = page_settings?.events;

  if (events?.[fn]?.redirect) {
    setTimeout(() => {
      router.push(events?.[fn]?.redirect);
    }, 1000);
  }

  if (events?.[fn]?.open?.modal) {
    const modal_name = events?.[fn]?.open?.modal?.name || null;
    const args = events?.[fn]?.open?.modal?.args || {};
    const query = events?.[fn]?.open?.modal?.query || {};

    if (modal_name) {
      setTimeout(() => {
        if (query) {
          router.replace({
            query: {
              ...route.query,
              ...query,
            },
          });
        }

        vm.$store.dispatch("modals/toggle", {
          id: modal_name,
          ...args,
        });
      }, 1000);
    }
  }

  if (update) {
    cb &&
      cb({
        event: "update",
        properties: update,
      });
  }
};
