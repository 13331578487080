import check_if_the_unit_is_valid from "../helpers/check_if_the_unit_is_valid";

export default function ({ commit, state }) {
  const units = state.units;
  const unit_current = state.unit;
  const index = units.findIndex((u) => u.id == unit_current.id);
  const unit_next = units[index + 1];

  const error = check_if_the_unit_is_valid({
    units,
    unit: unit_next,
  });

  if (error) {
    commit("ADD_ERRORS", {
      message: error,
      type: "error",
    });

    return;
  }

  commit("SET_UNIT", {
    action: "update",
    unit: unit_next,
  });
}
