// Actions
import update_daemons from "./actions/update_daemons";
import update_darkmode from "./actions/update_darkmode";
import update_testingmode from "./actions/update_testingmode";
import update_app_settings from "./actions/update_app_settings";

// Mutations
import { SET_DAEMONS } from "./mutations/daemons";
import { SET_DARKMODE } from "./mutations/darkmode";
import { SET_TESTINGMODE } from "./mutations/testing_mode";
import { SET_APP_SETTINGS } from "./mutations/app_settings";

export default {
  namespaced: true,

  state: () => {
    return {
      darkmode: false,
      testing_mode: false,
      app_settings: {},
      daemons: {},
    };
  },

  mutations: {
    SET_DAEMONS,
    SET_DARKMODE,
    SET_TESTINGMODE,
    SET_APP_SETTINGS,
  },

  actions: {
    update_daemons,
    update_darkmode,
    update_testingmode,
    update_app_settings,
  },
};
