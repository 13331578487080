import store from "./helpers/store.js";
import login from "./helpers/login.js";

export default async function () {
  const temporal_id = localStorage.getItem("temporal_id");
  const anonymous_user_id = localStorage.getItem("anonymous_user_id");

  if (!this.i_have_18) {
    this.alert.error(
      "Confirma que eres mayor de edad y que has leído y aceptado los términos y condiciones de uso."
    );
    return;
  }

  const response = await store({
    vm: this,
    form: this.forms.register,
    email: this.forms.register.inputs.email.value,
    password: this.forms.register.inputs.password.value,
    name: this.forms.register.inputs.name.value,
    anonymous_user_id,
    temporal_id,
    save_on_sendpulse: 1,
    autologin: 1,
  });

  if (response) {
    login({
      vm: this,
      user: response.user,
      authorization: response.authorization,
    });

    this.$router.push({
      name: "index",
      query: {
        tab: "index",
        anonymous_user: anonymous_user_id ? 1 : 0,
      },
    });

    this.ab({
      vm: this,
      rt: "register",
      fn: "onregister",
    });
  }
}
