export default function (state, { action = null, units = {} }) {
    switch (action) {
      case "update":
        state.units = units;
        break;

      case "destroy":
        state.units = null;
        break;
    }
  }