export default function () {
  this.track_onload();
  this.loading = true;
  this.timetoload = new Date().getTime();

  this.player = new Howl({
    src: [this.source],
    format: [this.format],
    html5: true,
    buffer: true,
    preload: true,

    xhr: {
      withCredentials: false,
    },

    onload: () => {
      this.duration = parseInt(this.player.duration());
      this.loading = false;
      this.timetoload = new Date().getTime() - this.timetoload;
      this.track_loadtime();
    },

    onplay: () => {
      this.is_playing = true;
      this.track_play();

      this.intervals.push(
        setInterval(() => {
          this.onprogress();
        }, 1000)
      );
    },

    onpause: () => {
      this.is_playing = false;
    },

    onstop: () => {
      this.is_playing = false;
    },

    onend: () => {
      this.is_playing = false;
      this.track_finished();

      if (this.onend) {
        this.onend();
      }
    },
  });
}
