export default async function () {
  const route = new this.controllers.Route();
  const route_id = this.$route.params.route_id || this.$route.query.route_id;

  const { status, response } = await route.get_by_id({
    id: route_id,
  });

  this.$store.dispatch("player/update_route", {
    action: "update",
    route: response.route,
  });
}
