export default async function () {
  const user = new this.controllers.User();
  this.loading = true;

  const tab = this.$route.query.tab;
  const mode = tab === "emergency" ? tab : "suggestions";

  const { status, response } = await user.get_symptoms({
    mode,
  });

  this.symptoms = response.symptoms || [];
  this._symptoms = response.symptoms || [];
  
  setTimeout(() => {
    this.loading = false;
  }, 1000);
}
