<template>
  <div class="d-block w-100">
    <div class="d-block" v-if="!loading && items.length == 0">
      <div class="alert primary m-0">
        Parece que aún no tenemos rutas por aquí.
        <b>sigue explorando y verás que pronto aparecerán nuevas aventuras.</b>
      </div>
    </div>

    <div class="component__list_of_items">
      <div v-if="!loading && items.length > 0" class="two-column d-flex flex-column">
        <div class="block" v-if="!slider">
          <component-cover
            v-for="(item, index) in items"
            :key="item.id"
            :blocked="is_blocked(item)"
            :blocked_callbacks="lockable_callbacks"
            :index="index"
            :route="item"
            :preview="true"
            :open_player="open_player_modal"
          />
        </div>

        <swiper
          v-if="slider"
          class="swiper-container"
          :modules="[Navigation, Autoplay]"
          @swiper="onSwiper"
          :autoplay="{
            delay: this.autoplay ? 2500 : 999 * 999,
            disableOnInteraction: true,
            stopOnLastSlide: false,
          }"
          :slidesPerView="2"
          :spaceBetween="10"
        >
          <swiper-slide class="slide" v-for="(item, index) in items">
            <component-cover
              :key="item.id"
              :blocked="is_blocked(item)"
              :blocked_callbacks="lockable_callbacks"
              :index="index"
              :route="item"
              :preview="this.preview"
              :open_player="open_player_modal"
            />
          </swiper-slide>
        </swiper>

        <div
          class="block_bottom mt-3 d-flex gap-3"
          v-if="pagination && items.length >= 2"
        >
          <div class="pagination d-flex gap-3">
            <component-button
              :classes="['btn', 'btn-block', 'btn-secondary', 'btn-nopadding']"
              :disabled="page == 1"
              :execute="{
                callback: prev_page,
                arguments: {},
              }"
              :loading="false"
              type="button"
            >
              <i class="fi fi-rr-angle-small-left"></i>
              <span>Anterior</span>
            </component-button>

            <component-button
              :classes="['btn', 'btn-block', 'btn-secondary', 'btn-nopadding']"
              :disabled="page + 1 > pages"
              :execute="{
                callback: next_page,
                arguments: {},
              }"
              :loading="false"
              type="button"
            >
              <span>Siguiente </span>
              <i class="fi fi-rr-angle-small-right"></i>
            </component-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper/modules";

// Methods
import get_items from "./mehods/get_items";
import prev_page from "./mehods/prev_page";
import next_page from "./mehods/next_page";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    return {
      Pagination,
      Navigation,
      Autoplay,
      EffectFade,
    };
  },

  props: {
    name: "",

    on_open_player_modal: {
      default: null,
    },

    from: {
      default: null,
    },

    autoplay: {
      default: true,
    },

    pagination: {
      default: true,
    },

    lockable_callbacks: {
      default: null,
    },

    lockable: {
      default: false,
    },

    preview: {
      default: false,
    },

    slider: {
      default: false,
    },

    with_search: {
      default: false,
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },
  },

  watch: {
    "$store.state.template.daemons.index_emergency": {
      handler() {
        if (this.name == "emergency_routes") {
          this.get_items();
        }
      },

      deep: true,
    },

    "$store.state.template.daemons.index_suggestions": {
      handler() {
        if (this.name == "suggestions" || this.name == "suggestions_in_progress") {
          this.get_items();
        }
      },

      deep: true,
    },

    "$store.state.template.daemons.index_explore": {
      handler() {
        if (this.name == "explore") {
          this.get_items();
        }
      },

      deep: true,
    },
  },

  data() {
    return {
      loading: true,
      items: [],
      page: 1,
      pages: 0,
      limit: 3,
      search_by: "[]",
      search_by_name: "",
      order_by: "created_at,desc",
    };
  },

  mounted() {
    this.get_items();
  },

  methods: {
    get_items,
    prev_page,
    next_page,

    is_blocked(route) {
      return route.is_necessary_a_subscription && !this.subscription_status;
    },

    open_modal(item) {
      this.$store.dispatch("modals/toggle", {
        id: "modal_player",
        force: "close",
      });

      setTimeout(() => {
        this.$store.dispatch("modals/toggle", {
          id: "modal_player",
          force: "open",

          callbacks: {
            onclose: () => {
              this.$store.dispatch("template/update_daemons", {
                index_emergency: new Date(),
                index_suggestions: new Date(),
                index_explore: new Date(),
              });
            },
          },

          update: {
            route: {
              $route: this.$route,
              $router: this.$router,
              query: {
                route_id: item.id,
              },
            },
          },
        });
      }, 500);
    },

    open_player_modal(item) {
      if (this.lockable && this.is_blocked(item)) return;
      if (!this.user) return;

      this.on_open_player_modal &&
        this.on_open_player_modal(() => {
          this.open_modal(item);
        });

      if (!this.on_open_player_modal) {
        this.open_modal(item);
      }
    },

    onSwiper(x) {
      this.swiper = x;
    },
  },
};
</script>
