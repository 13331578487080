export default async function () {
  window.addEventListener("RequestEvent", (event) => {
    const { status, response } = event.detail;
    if (status === "AUTH_ERROR") {
      this.$store.dispatch("modals/toggle", {
        id: "modal_relogin",
        force: "open",
        close_others_modal: true,
      });

      this.error_type = 0;
      return;
    }
  });
}
