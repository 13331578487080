<template>
  <div class="content-tasks">
    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column">
          <div
            class="tasks mt-2"
            v-if="next_task">
            <div
              class="task"
              @click="go(next_task)">
              <div class="left">
                <div class="title">
                  <div
                    class="title d-flex gap-1 align-items-center justify-content-between">
                    <span>{{ next_task.title }}</span>
                    <i
                      class="fi fi-ss-bullet animate__animated animate__infinite animate__heartBeat"></i>
                  </div>
                  <div class="description text-muted">
                    {{ next_task.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="progress-bar dark mt-2"
            v-if="pending_tasks > 0"
            @click="show_more_info_about_chart_points()">
            <div
              :style="{ width: `${finished_tasks_percentage}%` }"
              class="progress"></div>
          </div>

          <div
            v-if="pending_tasks > 0"
            class="text-muted d-flex w-100 justify-content-end mt-1 font-weight-semibold">
            {{ finished_tasks }}/{{ total_tasks }} tareas
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        finished_tasks_percentage: 0,
        next_task: null,
        total_tasks: 0,
        finished_tasks: 0,
        pending_tasks: 0,
      };
    },

    mounted() {
      this.get_status();
    },

    methods: {
      async get_status() {
        const user = new this.controllers.User();
        const { status, response } = await user.get_status_tasks({});

        let total_tasks = 0;
        let finished_tasks = 0;
        let next_task = null;

        if (response.tasks) {
          response.tasks.forEach((task) => {
            total_tasks += 1;

            if (task.finished) {
              finished_tasks += 1;
            }

            if (!next_task && !task.finished) {
              next_task = task;
            }
          });
        }

        this.finished_tasks_percentage = (
          (finished_tasks / total_tasks) *
          100
        ).toFixed(0);

        this.total_tasks = total_tasks;
        this.pending_tasks = total_tasks - finished_tasks;
        this.finished_tasks = finished_tasks;
        this.next_task = next_task;
      },

      show_more_info_about_chart_points() {
        const alert = this.alert;

        if (this.pending_tasks == 0) {
          return;
        }

        let description = `
          ${this.pending_tasks} tareas más, llevas un ${this.finished_tasks_percentage}% completado sigamos...
        `;

        description = description.replace(/\n/g, " ");
        description = description.replace(/\s+/g, " ");

        alert.info(description.replace("\n", " "));
      },

      go(task) {
        if (task.go) {
          if (task.go.tab) {
            this.$router.push({
              query: {
                ...this.$route.query,
                ...{ tab: task.go.tab },
              },
            });
          }

          if (task.go.modal) {
            this.$store.dispatch("modals/toggle", {
              id: task.go.modal,
              force: "open",
            });
          }
        }
      },
    },
  };
</script>
