<template>
  <component-desktop v-if="screen_resolution(this) == 'desktop'" />
  <component-mobile v-if="screen_resolution(this) == 'mobile'" />
</template>

<script>
  // Libs
  import { defineComponent } from "vue";

  // Components
  import component_desktop from "./_desktop.vue";
  import component_mobile from "./_mobile.vue";

  export default defineComponent({
    components: {
      "component-desktop": component_desktop,
      "component-mobile": component_mobile,
    },
  });
</script>
