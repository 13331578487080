<template>
  <div class="content-mobile-index">
    <component-block_user />
    <component-block_chart />
    <component-block_tasks />
  </div>
</template>
<script>
import block_user from "../../components/index/components/block_user.vue";
import block_chart from "../../components/index/components/block_chart.vue";
import block_tasks from "../../components/index/components/block_tasks.vue";

export default {
  components: {
    "component-block_user": block_user,
    "component-block_chart": block_chart,
    "component-block_tasks": block_tasks,
  },

  computed: {
    visibility() {
      return this.$windowWidth >= 768 ? "desktop" : "mobile";
    },
  },

  mounted() {
    this.$store.state.template.darkmode = false;
    this.track();
  },

  methods: {
    async track() {
      const track = new this.controllers.Track();

      await track.add({
        name: `mobile_index_profile`,
        category: ["events", "page_view", "mobile_only"].join(","),
      });
    },
  },
};
</script>
