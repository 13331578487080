<template>
  <div class="mobile">
    <div
      v-if="step == 1"
      class="emergency-mobile d-flex justify-content-center align-items-end"
    >
      <div class="title d-flex flex-column gap-1 text-center">
        <div class="emoji">
          <emoji size="68px" file="face_with_spiral_eyes.png" />
        </div>
        <h4 class="m-0">¿Necesitas asistencia?</h4>
        <p class="text-muted m-0">
          Entendemos que este puede ser un momento complicado.
          <br />¿Comenzamos?
        </p>
        <div class="buttons d-flex w-100 mt-1 justify-content-center align-items-center">
          <component-button
            :classes="['btn', 'btn-transparent', 'btn-light']"
            type="button"
            @click="
              () => {
                $store.dispatch('modals/toggle', {
                  id: 'modal_onboarding_emergency',
                });
              }
            "
          >
            <i class="fi fi-rr-bulb"></i>
            <span>¿Como funciona?</span>
          </component-button>

          <component-button :classes="['btn', 'btn-dark']" type="button" @click="start()">
            <i class="fi fi-rr-hand-holding-heart"></i>
            <span>Comienza</span>
          </component-button>
        </div>

        <p class="alert secondary m-0 mt-1">
          Este modulo esta diseñado para ayudarte en ataques de
          <b>ansiedad, pánico o emociones similares</b>. Para una solucion a largo plazo,
          te invitamos a <b @click="go_tab('suggestions')">explorar</b> nuestras rutas o a
          comenzar tu <b @click="go_tab('diagnosis')">diagnósis</b>.
        </p>

        <div class="row mt-1 d-flex justify-content-center align-items-center">
          <div class="col-10">
            <p class="text-muted m-0">
              Carga resultados basados tus síntomas de las últimas 24 horas haciendo
              <br /><b @click="by_default()">clic aqui</b>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step == 2" class="emergency-mobile d-flex flex-column gap-2">
      <div v-if="loading" class="block-loader">
        <div class="loader"></div>
      </div>

      <div class="title d-flex flex-column gap-1">
        <h4 class="m-0 d-flex align-items-center gap-2">
          Resultados listos
          <emoji size="38px" file="index_pointing_up.png" />
        </h4>
        <p class="text-muted m-0">
          Aquí encontrarás opciones personalizadas para abordar los síntomas que estás
          experimentando. Te invitamos a explorar cada una, desde la primera hasta la
          última. ¿Nos haces saber cuáles te resultaron efectivas?
        </p>
      </div>

      <div class="block-routes">
        <div class="d-flex flex-column gap-3">
          <component-carousels-routes
            name="emergency_routes"
            :key="'emergency_routes'"
            :blocked="false"
            :from="get_routes"
          />
        </div>
      </div>

      <div class="d-flex flex-column">
        <div class="text-muted">
          Para optimizar los resultados que estás obteniendo, es fundamental que
          actualices regularmente tu información. Te invitamos a
          <b @click="start()"
            >registrar cada uno de tus síntomas de manera detallada aquí.</b
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    visibility() {
      return this.$windowWidth >= 768 ? "desktop" : "mobile";
    },
  },

  data() {
    return {
      step: 1,
      loading: true,
    };
  },

  mounted() {
    this.$store.state.template.darkmode = false;
    this.track();
  },

  methods: {
    async start() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_moods",
        callbacks: {
          onclose: () => {
            this.step = 2;
            this.$store.dispatch("template/update_daemons", {
              index_emergency: new Date(),
            });
          },
        },
      });
    },

    async by_default() {
      this.step = 2;
      this.$store.dispatch("template/update_daemons", {
        index_emergency: new Date(),
      });
    },

    async get_routes({ page = 1, limit = 4, callback = null }) {
      const alert = this.alert;
      const route = new this.controllers.Route();

      this.loading = true;

      if (this.screen_resolution(this) == "mobile") {
        limit = 4;
      }

      const { status, response } = await route.recommended({
        page,
        limit,
        mode: "emergency",
      });

      if (status != "SUCCESS" && response.message) {
        this.step = 1;
        alert.error(response.message);
      }

      setTimeout(() => {
        callback && callback(response);
        this.loading = false;
      }, 1000);
    },

    go_tab(tab) {
      this.tab = tab;

      this.$router.push({
        query: {
          ...this.$route.query,
          ...{ tab: tab },
        },
      });
    },

    async track() {
      const track = new this.controllers.Track();

      await track.add({
        name: `emergency`,
        category: ["events", "page_view", "mobile_only"].join(","),
      });
    },
  },
};
</script>
