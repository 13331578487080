export const EMAIL = "hello@memeonlyme.com";
export const NETWORK_WHATSAPP = "13059276364";
export const NETWORK_INSTAGRAM = "mmonlyme";
export const NETWORK_TIKTOK = "@mmonlyme";

export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=io.ionic.memeonlyme";

export const APPLE_STORE_LINK =
  "https://apps.apple.com/us/app/memeonlyme-supera-la-ansiedad/id6459873900";

export default {
  EMAIL,
  NETWORK_WHATSAPP,
  NETWORK_INSTAGRAM,
  NETWORK_TIKTOK,
  GOOGLE_PLAY_LINK,
  APPLE_STORE_LINK,
};
