<template>
  <div v-if="is_loading">
    <div class="box dark d-inline-flex p-2 gap-2 border-radius-2 text-light w-fit">
      <div class="loader-simple dark"></div>
    </div>
  </div>

  <div
    v-if="!is_loading && item"
    :key="item.id"
    :class="[
      'component__route_unit_cover',
      'item',
      `id-${item.id}`,
      afinity && afinity >= 80 && 'hight-afinity',
      item.format,
      preview && !is_player_ready && 'opacity-5',
    ]"
  >
    <div :style="{ backgroundImage: `url(${item.image})` }" class="cover">
      <div :class="['background', `color-${index}`]">
        <div class="block d-flex flex-column gap-2 px-3 pt-2">
          <div class="badgets d-flex justify-content-between gap-2">
            <div class="d-flex gap-2">
              <div
                v-if="blocked"
                class="badge transparent br-rounded text-light d-flex gap-2 p-0"
              >
                <i class="fi fi-sr-lock"></i>
              </div>
            </div>

            <div class="d-flex gap-2">
              <div
                v-if="preview && is_player_ready"
                @click="toggle()"
                class="badge transparent br-rounded text-light d-flex gap-2 p-0"
              >
                <i v-if="!is_playing" class="fi fi-rr-play"></i>
                <i v-if="is_playing" class="fi fi-sr-play"></i>
              </div>

              <div v-if="preview && !is_player_ready" style="width: fit-content">
                <span class="loader-simple" style="margin-top: 7px"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="play" @click="open_onclick()">
          <div class="button">
            <i class="fi fi-rr-play"></i>
          </div>
        </div>

        <div
          v-if="incognito"
          class="blocked"
          @click="
            () => {
              incognito_callbacks && incognito_callbacks();
            }
          "
        >
          <div class="icono animate__animated animate__pulse animate__infinite">
            <i class="fi fi-rr-question"></i>
          </div>
        </div>

        <div
          class="block d-flex flex-column gap-2 p-3 justify-content-end"
          @click="open_onclick()"
        >
          <div class="title">
            {{ item.title }}
          </div>

          <div class="badgets d-flex flex-column gap-0">
            <div class="d-flex w-100 justify-content-start">
              <div v-if="item.units > 0" class="badge light mb-1">
                {{ item.units }} unidades
              </div>
            </div>

            <div class="d-flex w-100 justify-content-start">
              <div
                v-if="afinity && afinity > 0"
                class="badge transparent text-light d-flex gap-2 p-0"
              >
                <i class="afinity fi fi-rr-sparkles" v-if="afinity < 80"></i>

                <i
                  class="afinity fi fi-sr-sparkles animate__animated animate__pulse"
                  v-if="afinity >= 80"
                ></i>
              </div>
            </div>

            <div class="d-flex w-100 justify-content-start">
              <div
                v-if="item.is_new"
                class="badge transparent text-light d-flex gap-2 p-0"
              >
                <i class="fi fi-rr-flame"></i>
                Nuevo
              </div>
            </div>
          </div>

          <div v-if="progress > 0">
            <div :class="['progress-bar', 'd-flex', 'w-100']">
              <div :style="{ width: `${progress}%` }" class="progress"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!is_loading && !item">
    <div class="alert dark m-0">No encontramos la ruta</div>
  </div>
</template>

<script>
import initialize from "./methods/initialize.js";
import toggle from "./methods/toggle.js";

export default {
  props: {
    route_id: {
      default: null,
    },

    index: {
      default: 0,
    },

    open_player: {
      default: null,
    },

    route: {
      default: null,
    },

    incognito: {
      default: false,
    },

    incognito_callbacks: {
      default: null,
    },

    blocked: {
      default: false,
    },

    blocked_callbacks: {
      default: null,
    },

    unit: {
      default: null,
    },

    preview: {
      default: null,
    },

    onload: {
      default: null,
    },
  },

  computed: {
    afinity() {
      const afinity =
        this.item.symptom_affinity && this.item.symptom_affinity > 0
          ? parseFloat(this.item.symptom_affinity * 100).toFixed(0)
          : 0;

      return afinity;
    },

    progress() {
      return parseFloat(this.item.my_progress.percent).toFixed(0);
    },

    player() {
      if (this.item) {
        return this.$store?.state?.cover?.players[this.item.id];
      }
    },
  },

  data() {
    return {
      item: null,
      is_playing: false,
      is_player_ready: false,
      is_loading: false,
    };
  },

  async mounted() {
    this.item = this.route || this.unit;
    await this.self_autoload();

    if (this.item) {
      this.initialize();
    }
  },

  unmounted() {
    if (this.player) {
      this.player.stop();
      this.player.unload();
    }
  },

  methods: {
    initialize,
    toggle,
    open_onclick() {
      if (this.player) {
        this.player.stop();
        this.player.unload();
      }

      if (this.blocked) {
        this.blocked_callbacks && this.blocked_callbacks();
      }

      if (!this.blocked) {
        this.open_player && this.open_player(this.item);
      }
    },

    async self_autoload() {
      if (!this.route_id) {
        return;
      }

      this.is_loading = true;
      const route_controller = new this.controllers.Route();

      const { status, response } = await route_controller.search_by({
        page: 1,
        limit: 1,
        search_by: JSON.stringify([
          {
            column: "exact:id",
            value: this.route_id,
          },
        ]),
      });

      const route = response.routes[0] || null;

      this.item = route;
      this.onload && this.onload();
      this.is_loading = false;
    },
  },
};
</script>
