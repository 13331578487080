import axios from "axios";
import * as DOMAIN from "../configs/domains";
import moment from "moment-timezone";

export default class Request {
  #headers = {
    content_type: "multipart/form_data",
  };

  #set_authorization() {
    const authorization = localStorage.getItem("authorization");

    if (authorization) {
      const { token } = JSON.parse(authorization);
      this.#headers["Authorization"] = `Bearer ${token}`;
    }
  }

  #prepare_body(body) {
    const form_data = new FormData();
    const ls = localStorage;
    const timezone = moment.tz.guess();

    Object.keys(body).forEach((key) => {
      if (body[key] === null || body[key] === undefined || body[key] === "") {
        return;
      }

      form_data.set(key, body[key]);
    });

    // tracking
    const utm_params = {
      utm_campaign: ls.getItem("utm_campaign"),
      utm_medium: ls.getItem("utm_medium"),
      utm_content: ls.getItem("utm_content"),
      utm_term: ls.getItem("utm_term"),
      utm_source: ls.getItem("utm_source"),
      utm_device: ls.getItem("utm_device"),
      utm_version: ls.getItem("utm_version"),
      utm_segmentation: ls.getItem("utm_segmentation"),
      utm_testing: ls.getItem("utm_testing"),
      utm_reffered: ls.getItem("utm_reffered"),
    };

    Object.entries(utm_params).forEach(([key, value]) => {
      if (value && value.length > 0 && !form_data.has(key)) {
        form_data.set(key, value);
      }
    });

    if (timezone) {
      form_data.set("timezone", timezone);
    }

    return form_data;
  }

  async #request(method, { path, body = {} }) {
    this.#set_authorization();
    let target = `${DOMAIN.API}${path}`;

    if (localStorage.getItem("testing")) {
      target = `${DOMAIN.API_STAGING}${path}`;
    }

    const formatted_body = this.#prepare_body(body);

    let status = "SUCCESS";
    let response = {};

    try {
      const axios_response = await axios({
        method,
        url: target,
        data: method == "get" ? undefined : formatted_body,
        headers: this.#headers,
      });

      if (axios_response) {
        response = axios_response.data;

        if (response.status) {
          status = response.status;
        }
      }
    } catch (error) {
      console.error(error);
      status = "SERVER_ERROR";
    }

    if (status === "AUTH_ERROR") {
      window.dispatchEvent(
        new CustomEvent("RequestEvent", {
          detail: {
            response,
            status,
          },
        })
      );
    }

    return {
      status,
      response,
    };
  }

  async get(options) {
    return this.#request("get", options);
  }

  async post(options) {
    return this.#request("post", options);
  }

  async put(options) {
    return this.#request("put", options);
  }

  async delete(options) {
    return this.#request("delete", options);
  }
}
