<template>
  <ion-content class="page__onboarding_v2">
    <div
      :class="[
        'wrapper-container',
        'wrapper',
        'dark-pattern',
        'justify-content-end',
        'justify-content-sm-center',
        'd-flex',
        'flex-column',
        'p-0',
        'dark',
      ]"
    >
      <!-- Step 0 -->
      <div
        class="step"
        style="background-image: url(/assets/onboarding/onboarding01.jpg)"
        v-if="step == 0"
      >
        <div class="bg-overflow">
          <component-step0 :id="id" :step="step" :change_step_to="change_step_to" />

          <!--  Progress bar -->
          <component-progress :id="id" :step="step" :change_step_to="change_step_to" />
        </div>
      </div>

      <!-- Step 1 -->
      <div class="step" v-if="step == 1">
        <div class="bg-overflow">
          <component-step1 :id="id" :step="step" :change_step_to="change_step_to" />

          <!--  Progress bar -->
          <component-progress :id="id" :step="step" :change_step_to="change_step_to" />
        </div>
      </div>

      <!-- Step 2 -->
      <div class="step" v-if="step == 2">
        <div class="bg-overflow">
          <component-step2 :id="id" :step="step" :change_step_to="change_step_to" />

          <!--  Progress bar -->
          <component-progress :id="id" :step="step" :change_step_to="change_step_to" />
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
//
import { v4 as uuidv4 } from "uuid";

// Components
import component_progress from "./components/progress.vue";
import component_step0 from "./components/step0.vue";
import component_step1 from "./components/step1.vue";
import component_step2 from "./components/step2.vue";

export default {
  components: {
    "component-progress": component_progress,
    "component-step0": component_step0,
    "component-step1": component_step1,
    "component-step2": component_step2,
  },

  data() {
    return {
      id: uuidv4(),
      step: 0,
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      // Ids
      const ls = localStorage;

      if (ls.getItem("user")) {
        setTimeout(() => {
          this.$router.push({ name: "index" });
        }, 1000);
      }

      if (ls.getItem("anonymous_user_id")) {
        this.id = ls.getItem("anonymous_user_id");
      }

      ls.setItem("anonymous_user_id", this.id);
    },

    change_step_to(step) {
      this.step = step;
    },
  },
};
</script>
