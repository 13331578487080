export default async function (ctx) {
  const { category } = ctx;
  const user = new this.controllers.User();
  const tab = this.$route.query.tab;
  const mode = tab === "emergency" ? tab : "suggestions";

  category.symptoms.forEach((symptom_id) => {
    user.store_symptoms({
      symptom_id,
      anonymous_user_id: this.id,
      has: 1,
      mode,
    });
  });
}
