import moment from "moment";

export default function format_human_readable_date(date_string) {
  const date = moment(date_string);
  const now = moment();

  const diff_days = now.diff(date, "days");
  const diff_hours = now.diff(date, "hours");
  const diff_minutes = now.diff(date, "minutes");
  const diff_seconds = now.diff(date, "seconds");

  if (diff_days < 7) {
    if (diff_days >= 1) {
      return `${diff_days} dias`;
    } else if (diff_hours >= 1) {
      return `${diff_hours} horas`;
    } else if (diff_minutes >= 1) {
      return `${diff_minutes} minutos`;
    } else {
      return `${diff_seconds} segundos`;
    }
  } else {
    return date.format("YYYY-MM-DD HH:mm:ss");
  }
}
