<template>
  <div class="section-title mb-2">
    <h3 class="m-0">Únete</h3>
    <p class="text-muted m-0">
      Genial, estás a un paso de hacer un cambio maravilloso! Con solo unos
      toques, te unirás a nuestra comunidad en la app y comenzarás a darle un
      nuevo giro a tu historia.
    </p>
  </div>
</template>

<script>
  export default {};
</script>
