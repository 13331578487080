export default function () {
  if (this.is_playing) {
    let progress = parseInt(
      (this.player.seek() / this.player.duration()) * 100
    );

    this.duration = parseInt(this.player.duration());
    this.seconds_played = parseInt(this.player.seek());

    if (isNaN(progress)) {
      progress = 0;
    }

    this.progress = progress;
  }
}
