export default async function () {
  const route = new this.controllers.Route();
  const progress_unit = this.unit.my_assignment.progress;
  const unit = this.unit;

  localStorage.setItem(
    `player-unit-${this.unit.id}`,
    JSON.stringify({
      progress: this.progress,
      seconds_played: this.seconds_played,
    })
  );

  let follow_progress_on_unit =
    unit && !unit.is_introduction && !unit.is_conclusion;

  if (follow_progress_on_unit && this.progress > progress_unit) {
    await route.update_progress({
      progress: this.progress.toFixed(0),
      route_id: this.route.id,
      route_unit_id: this.unit.id,
    });
  }
}
