<template>
  <div
    :class="[
      'question',
      'd-flex',
      'gap-3',
      'flex-column',
      'p-3',
      'active',
      'w-100',
      'animate__animated',
      'animate__fadeIn',
    ]"
  >
    <div class="top d-flex flex-column gap-1">
      <span class="title">{{ title }}</span>
      <p class="description">
        {{ description }}
      </p>
    </div>

    <div class="buttons d-flex gap-2">
      <component-button
        v-for="option in options"
        :classes="[
          'btn',
          'btn-block',
          'btn-small',
          'btn-transparent',
          'btn-black',
          ...option.classes,
        ]"
        type="button"
        :execute="{
          callback: save_answers,
          arguments: { route, unit, question, answer: option },
        }"
        :disabled="loading[option.id]"
        :loading="loading[option.id]"
      >
        <span>{{ option.text }}</span>
      </component-button>
    </div>
  </div>
</template>

<script>
// Methods
import save_answers from "../methods/save_answers";

export default {
  props: {
    active: {
      default: false,
    },

    question: {
      default: null,
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    unit() {
      return this.$store.state.player.unit;
    },

    route() {
      return this.$store.state.player.route;
    },

    title() {
      return this.question.title;
    },

    description() {
      return this.question.description;
    },

    options() {
      return this.question.options;
    },
  },

  data() {
    return {
      loading: {},
    };
  },

  mounted() {
    this.options.forEach((option) => {
      this.loading[option.id] = false;
    });
  },

  methods: {
    save_answers,
  },
};
</script>
