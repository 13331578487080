function get_day_name(dateString) {
  const date = new Date(dateString);
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  return days[date.getDay()];
}

export default function () {
  const months_names = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };

  const offset =
    this.pagination.month.current_page * this.pagination.month.per_page;
  const end = offset + this.pagination.month.per_page;

  return Object.keys(this.spaces)
    .map((day) => {
      let [year, month, day_of_month] = day.split("-");
      const day_name = get_day_name(day);
      const spaces = this.spaces[day];

      return {
        id: day,
        day: day_of_month,
        day_name,
        month: months_names[parseInt(month)],
        spaces: spaces.length,
      };
    })
    .slice(offset, end);
}
