<template>
  <div class="explore-mobile">
    <component-explore />
  </div>
</template>

<script>
  // Components
  import component_explore from "../../components/explore/index.vue";

  export default {
    components: {
      "component-explore": component_explore,
    },

    mounted() {
      this.$store.state.template.darkmode = false;
      this.track();
    },

    methods: {
      async track() {
        const track = new this.controllers.Track();

        await track.add({
          name: `explore`,
          category: ["events", "page_view", "mobile_only"].join(","),
        });
      },
    },
  };
</script>
