<template>
  <div class="explore d-flex flex-column gap-3">
    <div class="title d-flex flex-column gap-1">
      <h4 class="m-0">
        Descubre
        <emoji
          size="38px"
          file="cowboy_hat_face.png" />
      </h4>
      <p class="text-muted m-0">
        Descubre una variedad de recursos gratuitos especialmente diseñados para
        ti. Explora y encuentra herramientas útiles para comprender y manejar
        diferentes situaciones de ansiedad.
      </p>
    </div>

    <div
      v-if="loading"
      class="block-loader">
      <div class="loader"></div>
    </div>

    <div v-show="!loading">
      <component-carousels-routes
        name="explore"
        :from="get_routes" />
    </div>
  </div>
</template>

<script>
  // Methods
  import get_routes from "./methods/get_routes";

  export default {
    data() {
      return {
        loading: true,
        notifications: [],
        routes: [],
      };
    },

    methods: {
      get_routes,
    },
  };
</script>
