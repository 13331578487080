import moment from "moment";

export default async function () {
  const how_you_met_us_next_attempt = localStorage.getItem(
    "how_you_met_us_next_attempt"
  );

  if (
    how_you_met_us_next_attempt &&
    moment().isAfter(moment(how_you_met_us_next_attempt))
  ) {
    this.$store.dispatch("modals/toggle", {
      id: "modal_how_you_met_us",
      force: "open",
    });
  }
}
