export default (state) => {
  let start;
  let end;
  const unit = state.unit;
  const units = state.units;

  if (units && units.length > 0) {
    const index = units.findIndex((u) => u.id == unit.id);
    const max_per_page = 3;

    if (index == -1) {
      return [];
    }

    if (index == 0) {
      start = index;
      end =
        index + max_per_page > units.length
          ? units.length
          : index + max_per_page;
    } else if (index == units.length - 1) {
      start = index - max_per_page + 1 < 0 ? 0 : index - max_per_page + 1;
      end = index + 1;
    } else {
      start = index - 1;
      end = index + max_per_page - 1;
    }

    return units.slice(start, end);
  }

  return [];
};
