<template>
  <div
    v-if="question"
    :class="['questions', 'd-flex', 'flex-column', 'gap-3', 'w-100']">
    <component-question :question="question" />
  </div>
</template>

<script>
  import question from "./question.vue";

  export default {
    components: {
      "component-question": question,
    },

    computed: {
      is_playing() {
        return this.$store.state.player.player.info.is_playing;
      },

      progress() {
        return this.$store.state.player.player.info.progress;
      },

      question() {
        const questions = this.$store.getters["player/unit_visible_questions"];
        return questions.length > 0 ? questions[0] : null;
      },
    },

    methods: {},
  };
</script>
