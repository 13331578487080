import check_if_the_unit_is_valid from "../helpers/check_if_the_unit_is_valid";

export default function ({ commit, state }, _params) {
  const default_unit = _params.by_default || null;
  const units = state.units;
  let unit_found;

  if (default_unit) {
    unit_found = units.find((u) => u.id == default_unit);
  }

  if (!unit_found && units.length > 0) {
    unit_found = units.find(
      (u) => u.my_assignment && u.my_assignment.status == "in_progress"
    );

    if (!unit_found) {
      unit_found = units[0];
    }
  }

  const error = check_if_the_unit_is_valid({
    units,
    unit: unit_found,
  });

  if (error) {
    commit("SET_UNIT", {
      action: "update",
      unit: units[0],
    });

    return;
  }

  commit("SET_UNIT", {
    action: "update",
    unit: unit_found,
  });
}
