<template>
  <ion-app>
    <notification-downloadapp />
    <component-navigation />

    <router-view v-if="!loading" />

    <modal-menu />
    <modal-onlyweb />

    <div v-if="user">
      <modal-update_app />
      <modal-getnps />
      <modal-relogin />
      <modal-moods />
      <modal-subscription />
      <modal-player />
      <modal-appointment />
      <modal-onboarding_emergency />
      <modal-onboarding_diagnosis />
      <modal-onboarding_suggestions />
      <modal-update-password />
      <modal-tickets />
      <modal-share />
      <modal-promotion />
      <modal-stepsrequired />
      <modal-how_you_met_us />
      <modal-thanks />
      <modal-continue_to_full />
    </div>
  </ion-app>
</template>

<script>
// Libs
import { defineComponent } from "vue";
import { App } from "@capacitor/app";

// Methods
import initialize from "./methods/initialize";
import track_session from "./methods/track_session";
import symptoms_disclaimer from "./methods/symptoms_disclaimer";
import url_listeners from "./methods/url_listeners";
import check_version from "./methods/check_version";
import modal_checker from "./methods/modal_checker";
import check_device from "./methods/check_device";
import check_how_you_met_us from "./methods/check_how_you_met_us";
import get_settings from "./methods/get_settings";

// Components
import navigation from "@/components/template/navigation/index.vue";
import modal_tickets from "@/views/modals/tickets/index.vue";
import modal_getnps from "@/views/modals/getnps/index.vue";
import modal_share from "@/views/modals/share/index.vue";
import modal_player from "@/views/modals/player/index.vue";
import modal_appointment from "@/views/modals/appointment/index.vue";
import modal_onboarding_emergency from "@/views/modals/onboarding_emergency/index.vue";
import modal_onboarding_diagnosis from "@/views/modals/onboarding_diagnosis/index.vue";
import modal_onboarding_suggestions from "@/views/modals/onboarding_suggestions/index.vue";
import modal_subscription from "@/views/modals/subscription/index.vue";
import modal_moods from "@/views/modals/moods/index.vue";
import modal_relogin from "@/views/modals/relogin/index.vue";
import modal_menu from "@/views/modals/menu/index.vue";
import modal_promotion from "@/views/modals/promotion/index.vue";
import modal_update_profile from "@/views/modals/update_profile/index.vue";
import modal_stepsrequired from "@/views/modals/stepsrequired/index.vue";
import modal_how_you_met_us from "@/views/modals/how_you_met_us/index.vue";
import modal_thanks from "@/views/modals/thanks/index.vue";
import modal_update_app from "@/views/modals/update_app/index.vue";
import modal_continue_to_full from "@/views/modals/continue_to_full/index.vue";
import modal_onlyweb from "@/views/modals/onlyweb/index.vue";

import notification_downloadapp from "@/views/notifications/downloadapp/index.vue";

export default defineComponent({
  components: {
    "component-navigation": navigation,
    "modal-update-password": modal_update_profile,
    "modal-stepsrequired": modal_stepsrequired,
    "modal-getnps": modal_getnps,
    "modal-menu": modal_menu,
    "modal-player": modal_player,
    "modal-appointment": modal_appointment,
    "modal-subscription": modal_subscription,
    "modal-moods": modal_moods,
    "modal-relogin": modal_relogin,
    "modal-tickets": modal_tickets,
    "modal-share": modal_share,
    "modal-promotion": modal_promotion,
    "modal-onboarding_emergency": modal_onboarding_emergency,
    "modal-onboarding_diagnosis": modal_onboarding_diagnosis,
    "modal-onboarding_suggestions": modal_onboarding_suggestions,
    "modal-how_you_met_us": modal_how_you_met_us,
    "modal-thanks": modal_thanks,
    "modal-continue_to_full": modal_continue_to_full,
    "modal-update_app": modal_update_app,
    "modal-onlyweb": modal_onlyweb,

    "notification-downloadapp": notification_downloadapp,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    $route(old_value, new_value) {
      this.modal_checker(old_value);
    },

    "$store.state.auth.user"() {
      this.get_settings();
    },
  },

  data() {
    return {
      deferred_prompt: null,
      loading: true,
    };
  },

  async mounted() {
    const vm = this;

    await this.initialize();
    await this.get_settings();

    this.loading = false;

    this.url_listeners();
    this.symptoms_disclaimer();
    this.check_version();
    this.modal_checker();
    this.check_device();
    this.check_how_you_met_us();

    // Loader...
    setTimeout(() => {
      const loader = window.document.getElementById("loader");
      loader.classList.add("animate__fadeOut");

      setTimeout(() => {
        loader.style.display = "none";
      }, 250);
    }, 1500);

    //
    App.addListener("appStateChange", async (state) => {
      if (state.isActive) {
        await vm.initialize();
        vm.track_session();
        vm.check_version();
        vm.check_device();
        vm.check_how_you_met_us();
        vm.get_settings();
      }
    });
  },

  methods: {
    initialize,
    track_session,
    symptoms_disclaimer,
    check_how_you_met_us,
    url_listeners,
    check_version,
    modal_checker,
    check_device,
    get_settings,
  },
});
</script>
