<template>
  <div
    class="top-notification d-flex gap-4 dark"
    v-if="current_device() == 'web' && opened"
  >
    <div class="content w-100">
      <div class="d-flex align-items-center justify-content-between gap-2">
        <p class="text-muted-light m-0 text-bold">
          <emoji size="18px" file="ok_hand.png" />
          Vive la experiencia completa descargando la App en tu tienda favorita
        </p>

        <div style="w-fit">
          <a
            v-if="get_device_brand() == 'android'"
            aria-label="Google Play"
            @click="open_browser(GOOGLE_PLAY_LINK)"
            class="btn btn-light d-flex gap-2 align-items-center justify-content-center"
            target="_blank"
          >
            <i class="lh-1 fi fi-brands-google"></i>
            <span>Google Play</span>
          </a>

          <a
            v-if="get_device_brand() == 'ios'"
            aria-label="Apple Store"
            @click="open_browser(APPLE_STORE_LINK)"
            class="btn btn-light d-flex gap-2 align-items-center justify-content-center"
            target="_blank"
          >
            <i class="lh-1 fi fi-brands-apple"></i>
            <span>Apple Store</span>
          </a>

          <a
            v-if="get_device_brand() == 'web'"
            style="width: 130px"
            aria-label="Web App"
            @click="open_browser(`/join`)"
            class="btn btn-light d-flex gap-2 align-items-center justify-content-center"
            target="_blank"
          >
            <i class="lh-1 fi fi-rr-download"></i>
            <span>Nuestra Web</span>
          </a>
        </div>
      </div>
    </div>

    <div class="close-notification" @click="this.opened = false">
      <i class="fi fi-rr-cross-small"></i>
    </div>
  </div>
</template>

<script>
import { GOOGLE_PLAY_LINK, APPLE_STORE_LINK } from "@/configs/vars.js";
import { PUBLIC_URL } from "@/configs/domains.js";

export default {
  data() {
    return {
      opened: true,
      PUBLIC_URL,
      GOOGLE_PLAY_LINK,
      APPLE_STORE_LINK,
    };
  },

  mounted() {
    setTimeout(() => {
      this.opened = false;
    }, 1000 * 15);
  },

  methods: {
    get_device_brand() {
      const user_agent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(user_agent)) {
        return "android";
      }

      if (/iPad|iPhone|iPod/.test(user_agent) && !window.MSStream) {
        return "ios";
      }

      return "web";
    },
  },
};
</script>
