import store from "../store/index";

export default function (to, from, next) {
  const route_to_validate = to;
  let user = store.state.auth.user;

  // Si no tenemos sesion entonces tratamos de restaurar la sesion
  if (!user) {
    store.dispatch("auth/restore");

    if (localStorage.getItem("testing")) {
      store.dispatch("template/update_testingmode", true);
    }

    user = store.state.auth.user;
  }

  // Si tenemos sesion y visita una ruta de landing entonces lo enviamos a la app
  if (user && ["home"].some((x) => x == route_to_validate.name)) {
    next({ name: "index" });
  }

  // Validamos si es una ruta permitida de acceder
  if (route_to_validate.meta.login_required == true && !user) {
    const token = route_to_validate?.query?.auth_code || "";
    localStorage.setItem("redirect_after_login", route_to_validate.fullPath);
    next({ name: "login", query: { token } });
  }

  let title = to.meta.title;

  if (to?.name == "index" && to?.query?.tab) {
    if (to.query.tab == "suggestions") {
      title = "Explora";
    }

    if (to.query.tab == "index") {
      title = "Inicio";
    }

    if (to.query.tab == "diagnosis") {
      title = "Diagnosis";
    }

    if (to.query.tab == "emergency") {
      title = "Emergencia";
    }
  }

  document.title = `memeonlyme | ${title}` || "";
  next();
}
