export default async function () {
  const appointment = new this.controllers.Appointment();
  const alert = this.alert;

  if (!this.appointment) {
    return;
  }

  this.loading_confirm = true;

  const { status, response } = await appointment.confirm({
    appoitment_id: this.appointment.id,
  });

  if (response.message) {
    alert.error(response.message);
  }

  this.loading_confirm = false;

  this.$store.dispatch("template/update_daemons", {
    index_notifications: new Date(),
  });
}
