<template>
  <div
    class="header gap-3"
    v-if="route">
    <div
      v-if="close_button"
      class="close"
      @click="toggle">
      <i class="fi fi-rr-cross"></i>
    </div>

    <div class="d-flex flex-column gap-2">
      <div class="d-flex flex-column">
        <h4 class="title">
          {{ title }}
        </h4>

        <p class="description">{{ description }}</p>
      </div>

      <component-box_cta_full_access
        v-if="!subscription_status || subscription_level === 'access'" />

      <div class="d-flex w-100 gap-2">
        <component-button
          :classes="['btn', 'btn-light', 'btn-small']"
          v-if="screen_resolution(this) == 'mobile'"
          @click="change_screen()">
          <i class="fi fi-rr-play"></i>
          <span>Inicia</span>
        </component-button>

        <component-button
          :classes="['btn', 'btn-transparent', 'btn-small', 'text-light']"
          v-if="screen_resolution(this) == 'mobile'"
          @click="share_route()">
          <i class="fi fi-rr-share"></i>
          <span>Comparte</span>
        </component-button>
      </div>
    </div>
  </div>
</template>

<script>
  //
  import { useBackButton } from "@ionic/vue";
  import { defineComponent } from "vue";

  export default defineComponent({
    computed: {
      close_button() {
        return this.$store.state.player.settings.with_close_button;
      },

      screen_size() {
        return this.$windowWidth;
      },

      route() {
        return this.$store.state.player.route;
      },

      description() {
        return this.route.description;
      },

      title() {
        return this.route.title;
      },

      subscription_level() {
        return (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.subscription &&
          this.$store.state.auth.user.subscription.level
        );
      },

      subscription_status() {
        return (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.subscription &&
          this.$store.state.auth.user.subscription.status
        );
      },
    },

    mounted() {
      useBackButton(10, () => {
        this.toggle();
      });
    },

    methods: {
      toggle() {
        this.$store.dispatch("modals/toggle", {
          id: "modal_player",
        });
      },

      change_screen() {
        this.$store.dispatch("player/update_mobile_mode", {
          mode: "player",
        });
      },

      share_route() {
        this.$store.dispatch("modals/toggle", {
          id: "modal_share",
          dark: true,
          close_others_modal: false,
        });
      },
    },
  });
</script>
