<template>
  <component-modal
    id="modal_subscription"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-2']"
    :clean="false"
    :dark="false"
    :permanent_mode="false"
    :with_close_button="true"
    :onopened="init"
  >
    <div class="content-modal">
      <component-paywall_1 v-if="paywall == 1" />
      <component-paywall_2 v-else-if="paywall == 2" />
      <component-paywall_3 v-else-if="paywall == 3" />
      <component-paywall_4 v-else-if="paywall == 4" />
      <component-paywall_dynamic
        :paywall_blocks="paywall_blocks"
        v-else-if="paywall == 'dynamic'"
      />
      <component-paywall_4 v-else />
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

// Paywalls
import paywall_1 from "./components/paywalls/v1.vue";
import paywall_2 from "./components/paywalls/v2.vue";
import paywall_3 from "./components/paywalls/v3.vue";
import paywall_4 from "./components/paywalls/v4.vue";
import paywall_dynamic from "./components/paywalls/dynamic.vue";

export default defineComponent({
  components: {
    "component-paywall_1": paywall_1,
    "component-paywall_2": paywall_2,
    "component-paywall_3": paywall_3,
    "component-paywall_4": paywall_4,
    "component-paywall_dynamic": paywall_dynamic,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },
  },

  data() {
    return {
      paywall: null,
      paywall_blocks: [],
    };
  },

  mounted() {
    const interval = setInterval(() => {
      if (!this.subscription_status) {
        let some_modal_is_opened = false;

        Object.keys(this.$store.state.modals.modals).forEach((modal_id) => {
          const modal = this.$store.state.modals.modals[modal_id];
          if (modal.opened) {
            some_modal_is_opened = true;
          }
        });

        if (!some_modal_is_opened) {
          this.$store.dispatch("modals/toggle", {
            id: "modal_subscription",
            force: "open",
          });

          this.init();
          clearInterval(interval);
        }
      }
    }, 1000);
  },

  methods: {
    init() {
      const vm = this;

      this.ab({
        vm,
        fn: "mounted",
        rt: "modal_subscription",
        cb: ({ event = null, properties = [] }) => {
          switch (event) {
            case "update":
              Object.keys(properties).forEach((key) => {
                vm[key] = properties[key];
              });
              break;
          }
        },
      });
    },
  },
});
</script>
