<template>
  <div class="component__simple-audio">
    <div
      class="block-loader"
      v-if="loading">
      <div class="loader"></div>
    </div>

    <div class="block-principal">
      <span class="time-element">
        {{ time_current }}
      </span>

      <div class="block-audio">
        <div
          class="button prev"
          :class="is_seeking && 'disabled'"
          @click="rewind()">
          <i class="fi fi-rr-rewind"></i>
        </div>

        <div
          class="button play"
          :class="is_seeking && 'disabled'"
          @click="toggle()">
          <i
            v-if="!is_playing"
            class="fi fi-rr-play"></i>
          <i
            v-if="is_playing"
            class="fi fi-rr-pause"></i>
        </div>

        <div
          class="button next"
          :class="is_seeking && 'disabled'"
          @click="forward()">
          <i class="fi fi-rr-forward"></i>
        </div>
      </div>

      <span class="time-element">
        {{ time_total }}
      </span>
    </div>

    <div class="block">
      <div class="progress-bar dark">
        <div
          @click="move_progress_by_click"
          class="tracker"></div>

        <div
          :style="{ width: `${progress}%` }"
          class="progress"></div>
      </div>
    </div>
  </div>
</template>

<script>
  //Libs
  import { v4 as uuidv4 } from "uuid";

  //Methods
  import initialize from "./methods/initialize.js";
  import onprogress from "./methods/onprogress.js";
  import toggle from "./methods/toggle.js";
  import forward from "./methods/forward.js";
  import rewind from "./methods/rewind.js";
  import move_progress_by_click from "./methods/move_progress_by_click.js";
  import track_onload from "./methods/track_onload.js";
  import track_play from "./methods/track_play.js";
  import track_finished from "./methods/track_finished.js";
  import track_loadtime from "./methods/track_loadtime.js";

  export default {
    props: {
      name: {
        type: String,
        required: true,
        default: "",
      },

      source: {
        type: String,
        required: true,
        default: null,
      },

      format: {
        type: String,
        required: true,
        default: "mp3",
      },

      onend: {
        type: Function,
        required: false,
        default: null,
      },

      autoplay: {
        default: false,
      },
    },

    computed: {
      user() {
        return this.$store.state.auth.user;
      },

      time_total() {
        let minutes = Math.floor(this.duration / 60);
        let seconds = this.duration % 60;

        let minutes_formated = minutes.toString().padStart(2, "0");
        let seconds_formated = seconds.toString().padStart(2, "0");

        return minutes_formated + ":" + seconds_formated;
      },

      time_current() {
        let duration = this.seconds_played;
        let minutes = Math.floor(duration / 60);
        let seconds = duration % 60;

        let minutes_formated = minutes.toString().padStart(2, "0");
        let seconds_formated = seconds.toString().padStart(2, "0");

        return minutes_formated + ":" + seconds_formated;
      },
    },

    data() {
      return {
        id: uuidv4(),
        loading: true,
        player: null,
        is_playing: false,
        is_seeking: false,
        progress: 0,
        intervals: [],
        duration: 0,
        seconds_played: 0,
        timetoload: null,
      };
    },

    mounted() {
      this.initialize();
    },

    unmounted() {
      this.intervals.forEach((interval) => clearInterval(interval));
      this.player.stop();
      this.player.unload();
    },

    methods: {
      initialize,
      onprogress,
      toggle,
      forward,
      rewind,
      move_progress_by_click,
      track_onload,
      track_play,
      track_finished,
      track_loadtime,
    },
  };
</script>
