export default async function ({ page = 1, limit = 10, callback = () => {} }) {
  const alert = this.alert;
  const route = new this.controllers.Route();

  this.search_by_text_loading = true;

  const { status, response } = await route.search_by({
    page,
    limit,
    search_by: JSON.stringify([
      {
        column: "primary",
        value: 0,
      },
      {
        column: "is_101",
        value: 0,
      },
      {
        column: "deep",
        value: this.search_by_text,
      },
    ]),
  });

  if (status != "SUCCESS" && response.message) {
    alert.error(response.message);
  }

  if (response.routes && response.routes.length > 0) {
    this.routes = response.routes;
  }

  setTimeout(() => {
    callback && callback(response);
    this.search_by_text_loading = false;
  }, 1000);
}
