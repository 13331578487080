import Request from "../helpers/request";

export default class {
  #request = new Request();

  async tickets(body) {
    return await this.#request.post({
      path: "/api/route/tickets",
      body,
    });
  }

  async store(body) {
    return await this.#request.post({
      path: "/api/route/ticket/store",
      body,
    });
  }

  async remove(body) {
    return await this.#request.post({
      path: "/api/route/ticket/remove",
      body,
    });
  }

  async channels(body) {
    return await this.#request.post({
      path: "/api/route/tickets/channels",
      body,
    });
  }
  
  async pending_to_be_read(body) {
    return await this.#request.post({
      path: "/api/route/tickets/pending_to_be_read",
      body,
    });
  }
}
