export default function () {
  if (!this.preview || !this.item.preview) {
    return;
  }

  const format = this.item.preview.format || "mp3";
  const media = this.item.preview.media;

  this.$store.dispatch("cover/player_init", {
    id: this.item.id,
    media,
    format,
    vm: this,
  });
}
