export default async function () {
  const unit = this.unit ?? null;

  if (unit && (unit.is_introduction || unit.is_conclusion)) {
    const route = new this.controllers.Route();
    const route_id = this.route ? this.route.id : null;
    const unit_id = this.unit ? this.unit.id : null;

    route.update_progress({
      progress: 100,
      route_id: route_id,
      route_unit_id: unit_id,
    });
  }
}
