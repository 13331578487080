<template>
  <img
    :style="{ display: 'inline', width: size, height: size }"
    :src="image_src"
    @error="set_default_image" />
</template>

<script>
  export default {
    props: {
      file: {
        type: String,
      },

      size: {
        type: String,
        default: "23px",
      },
    },

    data() {
      return {
        image_src: "",
      };
    },

    watch: {
      file(newVal) {
        this.image_src = `/assets/emojis/${newVal}`;
      },
    },

    methods: {
      set_default_image() {
        this.image_src = "/assets/emojis/white_exclamation_mark.png";
      },
    },

    mounted() {
      this.image_src = `/assets/emojis/${this.file}`;
    },
  };
</script>
