<template>
  <Teleport
    to="body"
    v-if="opened">
    <div class="component__modal">
      <div
        @click="!permanent_mode && close()"
        :class="[
          'modal-background',
          `modal-${id}`,
          dark_mode_status && 'dark',
          'animate__animated',
          'animate__faster',
          'animate__fadeIn',
        ]">
        <div :class="['background', with_pattern && 'with-pattern']"></div>
      </div>

      <div
        :class="[
          `modal-${id}`,
          'modal',
          dark_mode_status && 'dark',
          ...classes_modal,
        ]">
        <div
          :class="[
            'modal-wrapper',
            clean && 'clean',
            'animate__animated',
            'animate__faster',
            animation,
            'large',
            ...classes_wrapper,
          ]">
          <div
            v-if="with_close_button"
            class="close_button"
            @click="close()">
            <i class="fi fi-rr-cross"></i>
          </div>
          <slot></slot>

          <div
            class="progress-bar"
            style="
              position: absolute;
              bottom: 0;
              width: 100%;
              left: 0;
              border-radius: 0;
            "
            v-if="progress">
            <div
              class="progress"
              style="border-radius: 0"
              :style="{ width: progress + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
  import { v4 as uuidv4 } from "uuid";

  export default {
    props: {
      id: {
        default: uuidv4(),
      },

      with_close_button: {
        default: true,
      },

      with_pattern: {
        default: false,
      },

      permanent_mode: {
        default: false,
      },

      dark: {
        default: false,
      },

      clean: {
        default: false,
      },

      animation: {
        default: "animate__fadeInUp",
      },

      classes_wrapper: {
        default: ["p-2"],
      },

      classes_modal: {
        default: ["center", "p-2"],
      },

      progress: {
        default: 0,
      },

      onopened: {
        default: null,
      },

      onclose: {
        default: null,
      },
    },

    computed: {
      dark_mode_status() {
        return this.dark || this.dark_mode;
      },
    },

    watch: {
      opened() {
        if (this.opened == true) {
          this.onopened && this.onopened();
          this.callbacks && this.callbacks.onopen && this.callbacks.onopen();
          this.track_open();
        }

        if (this.opened == false) {
          this.onclose && this.onclose();
          this.callbacks && this.callbacks.onclose && this.callbacks.onclose();
        }
      },
    },

    data() {
      return {
        _: {},
        opened: false,
        dark_mode: false,
        callbacks: {},
      };
    },

    mounted() {
      this.$store.dispatch("modals/add", {
        id: this.id,
        ref: this,
      });
    },

    methods: {
      toggle() {
        this.opened = this.opened ? false : true;
      },

      close() {
        this.opened = false;
      },

      open() {
        this.opened = true;
      },

      set_custom_vars(vars) {
        this._ = vars;
      },

      set_dark_mode(status) {
        this.dark_mode = status;
      },

      set_callbacks(callbacks = {}) {
        this.callbacks = callbacks;
      },

      async track_open() {
        const track = new this.controllers.Track();

        await track.add({
          name: `modal_${this.id}`,
          category: ["events", "page_view"].join(","),
        });
      },
    },
  };
</script>
