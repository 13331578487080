import Repository from "../repositories/notification";

export default class {
  #router = null;
  #repository = new Repository();

  async save_notification_push_token(body) {
    const { status, response } =
      await this.#repository.save_notification_push_token({
        body,
      });

    return { status, response };
  }

  inject({ router = null }) {
    this.#router = router ? { ...this.#router, ...router } : this.#router;

    // Injections
    if (this.#router) {
      this.#repository.inject({ router: this.#router });
    }
  }
}
