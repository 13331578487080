<template>
  <component-modal
    ref="modal"
    id="modal_promotion"
    :dark="true"
    :clean="false"
    :permanent_mode="false"
    :with_close_button="true"
    :classes_modal="['center', 'p-2']"
    :classes_wrapper="['p-4']">
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title text-center d-flex flex-column gap-2">
              <h4 class="m-0">
                {{ content.title }}
              </h4>
              <p class="description m-0 mt-1 text-muted">
                {{ content.description }}
              </p>
            </div>

            <div class="block-network mt-3 d-flex gap-3">
              <component-button
                :class="['d-flex', 'w-100']"
                :classes="['btn', 'btn-block', 'btn-secondary']"
                type="button"
                @click="
                  () => {
                    this.$store.dispatch('modals/toggle', {
                      id: 'modal_share',
                      close_others_modal: false,
                    });
                  }
                "
                :loading="false">
                <i class="fi fi-rr-paper-plane"></i>
                <span>Comparte</span>
              </component-button>

              <component-button
                :class="['d-flex', 'w-100']"
                :classes="['btn', 'btn-block', 'btn-dark']"
                type="button"
                @click="
                  () => {
                    this.$refs.modal.close();
                    this.$store.dispatch('modals/toggle', {
                      id: 'modal_subscription',
                      close_others_modal: false,
                    });
                  }
                "
                :loading="false">
                <i class="fi fi-rr-bolt"></i>
                <span>Suscribete</span>
              </component-button>
            </div>

            <div class="block-footer mt-3">
              <p class="description m-0 mt-1 text-muted">
                Recuerda, estamos aquí para brindarte apoyo. No dudes en
                contactarnos de la manera que te resulte más cómoda. ¡Esperamos
                con ansias ayudarte!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
  // Others elements
  import { defineComponent } from "vue";
  import { Browser } from "@capacitor/browser";

  export default defineComponent({
    setup() {
      const messages = [
        {
          title: "¿Disfrutando la app?",
          description:
            "Nos llena de alegría saber que estás aprovechando al máximo lo que ofrecemos. Si sientes que alguien más podría beneficiarse, ¿qué tal si compartes nuestra app con ellos? ¡Es fácil! Solo haz clic en el botón de abajo. ",
        },
        {
          title: "¿Cómo va todo?",
          description:
            "Nos encantaría saber que estás sacando el máximo partido de nuestra plataforma. ¡Invita a tus amigos a unirse y haz clic abajo para compartir!",
        },
        {
          title: "¿Siguiente nivel?",
          description:
            "Sabemos lo mucho que estás disfrutando aquí. ¿Por qué no invitas a alguien más a la fiesta? Comparte la app con un clic y, si te sientes audaz. ",
        },
        {
          title: "¿Listo?",
          description:
            "Es genial verte aprovechando nuestra plataforma. Si conoces a alguien que podría disfrutarla tanto como tú, pásale el dato. ",
        },
        {
          title: "¿Disfrutando la app?",
          description:
            "Si estás amando la experiencia, comparte la alegría. Invita a tus amigos y, si estás listo para más, considera nuestra suscripción.",
        },
        {
          title: "¿Disfrutando la app?",
          description:
            "Si sientes que nuestra plataforma te eleva, ¿por qué no elevar a otros? Comparte con un amigo y, si quieres ir un paso más allá, echa un vistazo a nuestra suscripción.",
        },
      ];

      const content = messages[Math.floor(Math.random() * messages.length)];

      return {
        content,
      };
    },

    methods: {
      async open(url) {
        await Browser.open({ url });
      },
    },
  });
</script>
