export default async function () {
  if (
    this.$route?.query?.tab === "diagnosis" &&
    (!this.subscription_status ||
      (this.subscription_status && this.subscription_level === "access"))
  ) {
    this.$store.dispatch("modals/toggle", {
      id: "continue_to_full",
      close_others_modal: false,
      dark: true,
    });

    return;
  }

  if (
    this.$route?.query?.tab !== "diagnosis" &&
    this.route?.is_necessary_a_subscription == 1 &&
    !this.subscription_status
  ) {
    this.$store.dispatch("modals/toggle", {
      id: "continue_to_full",
      close_others_modal: false,
      dark: true,
    });

    return;
  }
}
