<template>
  <component-modal
    id="modal_onlyweb"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="false"
    :permanent_mode="false"
    :with_close_button="true"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title text-center">
              <h4 class="m-0">
                Hey
                <emoji size="38px" file="grinning_face.png" />
              </h4>

              <p class="description m-0 mt-1 text-muted">
                Estamos en tu tienda favorita,
                <b
                  >solo debes descargar nuestra aplicación y seguir disfrutando de los
                  beneficios que podríamos ofrecerte,</b
                >
                recuerda que la version web es una version reducida de la aplicación que
                no tiene todos los modulos activos.
              </p>
            </div>

            <div class="d-flex w-100 gap-2 flex-column mt-2">
              <component-button
                v-if="get_device_brand() == 'ios'"
                :classes="['btn', 'btn-block', 'btn-primary']"
                @click="open_browser(APPLE_STORE_LINK)"
              >
                <i class="lh-1 fi fi-brands-apple"></i>
                <span>Apple Store</span>
              </component-button>

              <component-button
                v-if="get_device_brand() == 'android'"
                :classes="['btn', 'btn-block', 'btn-primary']"
                @click="open_browser(GOOGLE_PLAY_LINK)"
              >
                <i class="lh-1 fi fi-brands-google"></i>
                <span>Google Play</span>
              </component-button>

              <component-button
                v-if="get_device_brand() == 'web'"
                :classes="['btn', 'btn-block', 'btn-primary']"
                @click="open_browser(`/join`)"
              >
                <i class="lh-1 fi fi-rr-download"></i>
                <span>Descargala</span>
              </component-button>

              <component-button
                :classes="['btn', 'btn-block', 'btn-transparent', 'text-dark']"
                :loading="false"
                type="button"
                @click="close_modal()"
              >
                <span>Continuar en version Web</span>
              </component-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import { GOOGLE_PLAY_LINK, APPLE_STORE_LINK } from "@/configs/vars.js";
import { PUBLIC_URL } from "@/configs/domains.js";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  data() {
    return {
      PUBLIC_URL,
      GOOGLE_PLAY_LINK,
      APPLE_STORE_LINK,
    };
  },

  methods: {
    close_modal() {
      this.$refs.modal.close();
    },

    get_device_brand() {
      const user_agent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(user_agent)) {
        return "android";
      }

      if (/iPad|iPhone|iPod/.test(user_agent) && !window.MSStream) {
        return "ios";
      }

      return "web";
    },
  },
});
</script>
