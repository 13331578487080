<template>
  <ion-content class="page__index">
    <div
      :class="[
        'wrapper-container',
        'wrapper',
        'wrapper-spacing',
        'light-pattern',
      ]">
      <div class="container mt-3">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="d-flex flex-column gap-3">
              <component-diagnosis />
              <component-appointments />
              <component-footer
                :address="true"
                :links="false"
                :terms="true"
                :disclaimer="true" />
            </div>
          </div>

          <div class="col-12 col-md-8 contents flex-column gap-3">
            <component-emergency />
            <component-suggestions />
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
  // Libs
  import { defineComponent } from "vue";

  // Components
  import component_continue from "./components/diagnosis/index.vue";
  import component_appointments from "./components/appointments/index.vue";
  import component_suggestions from "./components/suggestions/index.vue";
  import component_emergency from "./components/emergency/index.vue";
  import component_explore from "./components/explore/index.vue";
  import component_index from "./components/index/index.vue";

  export default defineComponent({
    components: {
      "component-diagnosis": component_continue,
      "component-appointments": component_appointments,
      "component-suggestions": component_suggestions,
      "component-emergency": component_emergency,
      "component-explore": component_explore,
      "component-index": component_index,
    },

    mounted() {
      this.$store.state.template.darkmode = false;
      this.track();
    },

    methods: {
      async track() {
        const track = new this.controllers.Track();

        await track.add({
          name: `index_app`,
          category: ["events", "page_view", "desktop"].join(","),
        });
      },
    },
  });
</script>
