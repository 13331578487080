import { createApp } from "vue";
import App from "./views/pages/bootstrap/index.vue";
import store from "./store/index";
import router from "./router/index";
import Toast from "vue-toastification";
import controllers from "./pluggins/controllers";
import alerts from "./pluggins/alerts";
import vars from "./pluggins/configs";
import Popper from "vue3-popper";
import { VueWindowSizePlugin } from "vue-window-size/plugin";
import mixin_open_browser from "./mixin/open_browser";
import mixin_format_human_readable_date from "./mixin/format_human_readable_date";
import mixin_screen_resolution from "./mixin/screen_resolution";
import mixin_current_device from "./mixin/current_device";
import mixin_ab from "./mixin/ab";
import * as Sentry from "@sentry/vue";
import { VueShowdownPlugin } from "vue-showdown";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

import {
  IonicVue,
  IonApp,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonItem,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonInput,
  IonCardTitle,
  IonCardSubtitle,
  IonLabel,
  IonCol,
  IonRow,
  IonGrid,
  IonNavLink,
  IonNav,
  IonIcon,
  IonModal,
  IonRouterOutlet,
} from "@ionic/vue";

import component_input from "./components/commons/input/index.vue";
import component_emoji from "./components/commons/emoji/index.vue";
import component_button from "./components/commons/button/index.vue";
import component_footer from "./components/commons/footer/index.vue";
import component_badge from "./components/commons/badge/index.vue";
import component_box from "./components/commons/box/index.vue";
import component_carousels_routes from "./components/template/carousels/routes/index.vue";
import component_modal from "./components/commons/modal/index.vue";
import component_cover_route_unit from "./components/commons/cover/index.vue";
import component_player_version_pro from "./components/commons/players/pro/index.vue";
import component_player_version_simple from "./components/commons/players/simple/index.vue";

import box_cta_full_access from "./components/commons/ctas/box_cta_full_access.vue";

// Styles
import "@ionic/vue/css/core.css";
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "@flaticon/flaticon-uicons/css/all/all.css";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-fade";
import "@ionic/vue/css/ionic-swiper.css";
import "animate.css";
import "./styles/bootstrap.scss";
import "vue-toastification/dist/index.css";

const app = createApp(App);

// Providers
app.provide("$store", store);

// ...
app.use(router);
app.use(store);
app.use(controllers);
app.use(alerts);
app.use(vars);
app.use(VueWindowSizePlugin);
app.use(IonicVue);
app.use(Toast, {
  position: "bottom-center",
  maxToasts: 3,
  icon: false,
  transition: "fade",
  newestOnTop: true,
});
app.use(VueShowdownPlugin, {
  flavor: "github",

  options: {
    emoji: false,
  },
});

// ...
app.component("IonApp", IonApp);
app.component("IonRouterOutlet", IonRouterOutlet);
app.component("IonPage", IonPage);
app.component("IonHeader", IonHeader);
app.component("IonToolbar", IonToolbar);
app.component("IonTitle", IonTitle);
app.component("IonButton", IonButton);
app.component("IonItem", IonItem);
app.component("IonContent", IonContent);
app.component("IonCard", IonCard);
app.component("IonCardContent", IonCardContent);
app.component("IonCardHeader", IonCardHeader);
app.component("IonInput", IonInput);
app.component("IonCardTitle", IonCardTitle);
app.component("IonCardSubtitle", IonCardSubtitle);
app.component("IonLabel", IonLabel);
app.component("IonCol", IonCol);
app.component("IonRow", IonRow);
app.component("IonGrid", IonGrid);
app.component("IonNavLink", IonNavLink);
app.component("IonNav", IonNav);
app.component("IonIcon", IonIcon);
app.component("IonModal", IonModal);
app.component("Popper", Popper);
app.component("emoji", component_emoji);
app.component("component-input", component_input);
app.component("component-button", component_button);
app.component("component-footer", component_footer);
app.component("component-box", component_box);
app.component("component-cover", component_cover_route_unit);
app.component("component-player-pro", component_player_version_pro);
app.component("component-player-simple", component_player_version_simple);
app.component("component-carousels-routes", component_carousels_routes);
app.component("component-modal", component_modal);
app.component("component-box_cta_full_access", box_cta_full_access);
app.component("component-badge", component_badge);

router.isReady().then(async () => {
  const mixins = {
    open_browser: mixin_open_browser,
    screen_resolution: mixin_screen_resolution,
    current_device: mixin_current_device,
    ab: mixin_ab,
    format_human_readable_date: mixin_format_human_readable_date,
  };

  if (process.env.NODE_ENV !== "development") {
    // Sentry
    Sentry.init({
      Vue: app,
      dsn: "https://6f5a6c2c31459677919b7724c2249c1e@o4506517952135168.ingest.sentry.io/4506517953380352",
      ignoreErrors: [
        "NotAllowedError: Write permission denied.",
        "No se pudo completar la operación. (Error de SKErrorDomain 2)",
        "No se pudo completar la operación. (Error de com.apple.AuthenticationServices.AuthorizationError 1001)",
        "The user canceled the sign-in flow.",
        "Not supported on web.",
        "GlassfyError(code=UserCancelPurchase, description=User cancel purchase, debug=Transaction was canceled by the user. (USER_CANCELED))",
        "Something went wrong",
        "GlassfyError(code=ProductAlreadyOwned, description=Product already owned, debug=The purchase failed because the item is already owned. (ITEM_ALREADY_OWNED))",
      ],
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [
            "localhost",
            "https://memeonlyme.com",
            "https://app.memeonlyme.com",
            /^\//,
          ],
        }),

        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],

      logErrors: true,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      sessionReplay: false,
    });
  }

  mixins["sentry_track_error"] = (error) => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    Sentry.captureException(error);
  };

  mixins["sentry_track_event"] = (event) => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    Sentry.captureEvent(event);
  };

  mixins["sentry_set_user"] = (user) => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    Sentry.setUser({
      id: user.id,
      name: user.name,
      email: user.email,
    });

    Sentry.setTags({
      id: user.id,
      name: user.name,
      email: user.email,
    });
  };

  // ------------------------------
  // GA
  if (mixin_current_device() === "web") {
    try {
      await FirebaseAnalytics.initializeFirebase({
        apiKey: "AIzaSyAu1R4dxakroP4KdSkZ9sKadgAguUsjbHo",
        authDomain: "memeonlyme-95639.firebaseapp.com",
        projectId: "memeonlyme-95639",
        storageBucket: "memeonlyme-95639.appspot.com",
        messagingSenderId: "79622214608",
        appId: "1:79622214608:web:2692cb723daade6f5d9fd5",
        measurementId: "G-7EQFQX7C9R",
      });

      await FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
      });
    } catch (error) {}
  }

  mixins["ga_event"] = (name, params) => {
    try {
      const event_name = `${mixin_current_device()}_${name}`;

      FirebaseAnalytics.logEvent({
        name: event_name,
        params,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // ------------------------------

  app.mixin({
    methods: mixins,
  });

  app.mount("#app");
});
