<template>
  <ion-content class="page__index mobile">
    <div
      :class="[
        'wrapper-container',
        'wrapper',
        tab == 'diagnosis' ? 'dark-pattern' : 'light-pattern',
      ]"
    >
      <div class="component-loader" v-if="loading">
        <div class="loader"></div>
      </div>

      <component-notification />
      <component-index v-if="tab == 'index'" />
      <component-diagnosis v-if="tab == 'diagnosis'" />
      <component-suggestions v-if="tab == 'suggestions'" />
      <component-emergency v-if="tab == 'emergency'" />

      <div :class="['navigation', tab == 'diagnosis' ? 'dark' : 'light']">
        <div class="navigation-container">
          <div :class="['icon', tab == 'index' && 'active']" @click="go_tab('index')">
            <i :class="['fi', `fi-${tab == 'index' ? 'sr' : 'rr'}-home`]"></i>
            <span>Inicio</span>
          </div>

          <div
            :class="['icon', tab == 'diagnosis' && 'active']"
            @click="go_tab('diagnosis')"
          >
            <i :class="['fi', `fi-${tab == 'diagnosis' ? 'sr' : 'rr'}-user-md-chat`]"></i>
            <span>Diagnosis</span>
          </div>

          <div
            :class="['icon', tab == 'suggestions' && 'active']"
            @click="go_tab('suggestions')"
          >
            <i :class="['fi', `fi-${tab == 'suggestions' ? 'sr' : 'rr'}-sparkles`]"></i>
            <span>Explora</span>
          </div>

          <div
            :class="['icon', tab == 'emergency' && 'active']"
            @click="go_tab('emergency')"
          >
            <i :class="['fi', `fi-${tab == 'emergency' ? 'sr' : 'rr'}-sad-tear`]"></i>
            <span>Ayuda</span>
          </div>

          <div v-if="user" :class="['icon']" @click="open_user_modal()">
            <i :class="['fi', `fi-rr-user`]"></i>
            <span>{{ user.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
// Libs
import { defineComponent } from "vue";

// Components
import explore from "./mobile/tabs/explore.vue";
import suggestions from "./mobile/tabs/suggestions.vue";
import continue_route from "./mobile/tabs/diagnosis.vue";
import index from "./mobile/tabs/index.vue";
import emergency from "./mobile/tabs/emergency.vue";
import notification from "./components/notification/index.vue";

export default defineComponent({
  components: {
    "component-explore": explore,
    "component-suggestions": suggestions,
    "component-diagnosis": continue_route,
    "component-index": index,
    "component-emergency": emergency,
    "component-notification": notification,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    darkmode() {
      return this.$store.state.template.darkmode;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },
  },

  watch: {
    "$route.query.tab": function () {
      this.checktab();
    },
  },

  data() {
    return {
      tab: "index",
      loading: true,
    };
  },

  beforeMount() {
    this.checktab();
    this.prepare();
  },

  methods: {
    checktab() {
      if (this.$route.query.tab) {
        switch (this.$route.query.tab) {
          case "index":
            this.tab = "index";
            break;
          case "help":
            this.tab = "help";
            break;
          case "explore":
            this.tab = "explore";
            break;
          case "emergency":
            this.tab = "emergency";
            break;
          case "suggestions":
            this.tab = "suggestions";
            break;
          case "diagnosis":
            this.tab = "diagnosis";
            break;
        }
      } else {
        this.go_tab("index");
      }
    },

    open_user_modal() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_menu",
        force: "open",
      });
    },

    prepare() {
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },

    go_tab(tab) {
      this.tab = tab;

      this.$router.push({
        query: {
          ...this.$route.query,
          ...{ tab: tab },
        },
      });
    },
  },
});
</script>
