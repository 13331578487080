<template>
  <component-modal
    id="modal_suggestions_filters"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :onopened="init"
    :clean="false"
    :dark="false"
    :permanent_mode="false"
    :with_close_button="true"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12 d-flex flex-column gap-2">
            <div class="block-title">
              <h4 class="m-0 gap-2 d-flex align-items-center">
                Busca
                <emoji size="38px" file="magic_wand.png" />
              </h4>

              <p class="description m-0 mt-1 text-muted">
                Encuentra lo que buscas de manera facil y rapida.
              </p>
            </div>

            <div class="d-flex flex-column">
              <component-input
                type="text"
                placeholder="Escribe aqui..."
                name="email"
                :default="search_by_text"
                label="¿Buscas una ruta?"
                :onupdate="(x) => (this.search_by_text = x)"
              />
            </div>

            <div class="d-flex flex-column">
              <div
                class="box bordered p-4 w-100 d-flex justify-content-center align-items-center"
                v-if="search_by_text_loading"
              >
                <div class="loader dark"></div>
              </div>

              <component-carousels-routes
                class="mt-2"
                v-show="!search_by_text_loading"
                ref="carousels-routes"
                name="search_routes"
                :key="'search_routes'"
                :from="get_routes"
                :preview="true"
                :slider="true"
                :autoplay="false"
                :pagination="false"
                :lockable="true"
                :lockable_callbacks="open_modal_subscription"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import _ from "lodash"; // Importa lodash
import { defineComponent } from "vue";
import get_routes from "./methods/get_routes";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    search_by_text: {
      handler: _.debounce(function () {
        this.$refs["carousels-routes"].get_items();
      }, 500),

      immediate: false,
    },
  },

  data() {
    return {
      search_by_text: "",
      search_by_text_loading: false,

      routes: [],
    };
  },

  methods: {
    init() {
      this.search_by_text = "";
    },

    get_routes,

    open_modal_subscription() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_subscription",
      });
    },
  },
});
</script>
