export default function (
  { state },
  {
    id,
    dark,
    update,
    callbacks = null,
    close_others_modal = true,
    force = null,
    inject = null,
  }
) {
  if (dark) {
    state.modals[id]?.set_dark_mode(dark);
  }

  if (inject) {
    state.modals[id]?.set_custom_vars(inject);
  }

  if (update && update.route && update.route.query) {
    const $route = update.route.$route;
    const $router = update.route.$router;

    $router.push({
      query: {
        ...$route.query,
        ...update.route.query,
      },
    });
  }

  if (callbacks) {
    state.modals[id]?.set_callbacks(callbacks);
  }

  // ...
  if (close_others_modal) {
    Object.keys(state.modals).forEach((idx) => {
      if (idx === id) {
        return;
      }

      state.modals[idx]?.close();
    });
  }

  switch (force) {
    case "open":
      state.modals[id]?.open();
      break;

    case "close":
      state.modals[id]?.close();
      break;

    default:
      state.modals[id]?.toggle();
      break;
  }
}
