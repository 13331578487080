<template>
  <component-modal
    ref="modal"
    id="modal_share"
    :dark="false"
    :clean="false"
    :permanent_mode="false"
    :with_close_button="true"
    :classes_modal="['center', 'p-2']"
    :classes_wrapper="['p-4']"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title">
              <h4 class="m-0">¿Quieres ayudar a alguien?</h4>
              <p class="description m-0 mt-1 text-muted">
                ¡Eh! Pásale este enlace a un amigo o familiar. Es una forma genial de
                compartir lo que estamos haciendo para ayudar a quienes más lo necesitan.
              </p>
            </div>

            <div class="form-content mt-3">
              <div class="form-group">
                <label>Enlace</label>
                <component-input
                  type="text"
                  :default="link"
                  icon="fi fi-rr-copy-alt"
                  :disabled="true"
                  :onclickicon="
                    () => {
                      this.track();
                      this.copy();
                    }
                  "
                />
              </div>

              <p class="text-muted m-0 mt-1">
                * Para ver la ruta, tu amigo deberá registrarse primero. Pero tranquilo,
                al abrir el enlace se toparán con el formulario. ¡Así podrán sumarse y
                disfrutar lo que compartiste!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import { PUBLIC_URL } from "@/configs/domains.js";

// Methods
import track from "./methods/track";
import copy from "./methods/copy";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state.auth ? this.$store.state.auth.user : null;
    },

    route() {
      return this.$store.state.player.route;
    },
  },

  watch: {
    "$store.state.player.route": function () {
      if (!this.route) {
        return;
      }

      this.link = `${PUBLIC_URL}/route/${this.route.slug}`;

      if (this.utm_reffered) {
        this.link += `?utm_reffered=${this.user.id}&utm_medium=inappsharedlink&utm_source=app`;
      }
    },
  },

  data() {
    return {
      link: ``,
    };
  },

  mounted() {
    this.link = `${PUBLIC_URL}/register`;

    if (this.utm_reffered) {
      this.link += `?utm_reffered=${this.user.id}&utm_medium=inappsharedlink&utm_source=app`;
    }
  },

  methods: {
    track,
    copy,
  },
});
</script>
