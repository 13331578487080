import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import set_push_notifications from "@/helpers/push_notifications";
import restore from "../../../modals/subscription/methods/restore";

export default async function () {
  const alert = this.alert;
  const user = new this.controllers.User();
  const track = new this.controllers.Track();
  let google_response = null;

  this.with_google_loading = true;
  this.forms.auth.inputs.email.value = "";
  this.forms.auth.inputs.password.value = "";

  try {
    google_response = await GoogleAuth.signIn();
  } catch (error) {
    if (error.error == "popup_closed_by_user" || error.code == "12501") {
      this.with_google_loading = false;
      return;
    }

    console.error(error);
    this.sentry_track_error(error);
    alert.info(
      "Tenemos un problema para procesar tu solicitud, si este problema persiste contáctanos."
    );
    this.with_google_loading = false;
    return;
  }

  const { email, id } = google_response;

  const { status, response } = await user.authenticate({
    google_id: id,
    email,
  });

  if (status == "SERVER_ERROR" || status == "ERROR") {
    alert.info(
      "El email que ingresastes para iniciar sesión no está registrado; te invitamos a registrarte. "
    );

    this.with_google_loading = false;
    return;
  }

  this.with_google_loading = false;

  this.sentry_set_user(response.user);
  this.ga_event("login", {});

  this.$store.dispatch("auth/login", {
    user: response.user,
    authorization: response.authorization,
  });

  track.add({
    name: `login`,
    category: ["events", "new_login"].join(","),
  });

  // Push
  set_push_notifications({ vm: this });

  // Redirection
  const redirect_url = localStorage.getItem("redirect_after_login");

  if (redirect_url) {
    this.$router.push(redirect_url);
    localStorage.removeItem("redirect_after_login");
    return;
  } else {
    this.$router.push({
      name: "index",
      query: {
        tab: "index",
      },
    });
  }
}
