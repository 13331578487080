const GOOGLE_AUTH_IOSCLIENTID =
  "79622214608-fdmheafkrj79tth0hmso9rclc4795j0h.apps.googleusercontent.com";
const GOOGLE_AUTH_ANDROIDCLIENTID =
  "79622214608-7ip0qv52hugdbpjjg4jhn4u59rf6g9kh.apps.googleusercontent.com";
const GOOGLE_AUTH_CLIENTID =
  "79622214608-7ip0qv52hugdbpjjg4jhn4u59rf6g9kh.apps.googleusercontent.com";
const GOOGLE_AUTH_SCOPES = ["profile", "email"];
const GOOGLE_AUTH_GRANTOFFLINEACCESS = true;

export {
  GOOGLE_AUTH_IOSCLIENTID,
  GOOGLE_AUTH_CLIENTID,
  GOOGLE_AUTH_SCOPES,
  GOOGLE_AUTH_GRANTOFFLINEACCESS,
  GOOGLE_AUTH_ANDROIDCLIENTID,
};
