<template>
  <div
    :class="['app-navigation', darkmode && 'darkmode', subscription_status && 'premium']"
    v-if="screen_resolution(this) == 'desktop' || visiblity_forced"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="app-navigation-wrapper">
            <div class="left">
              <img
                src="/assets/logo/logo_text_blue1.png"
                class="logo dark cursor-pointer"
                @click="$router.push({ name: 'home' })"
              />

              <img
                src="/assets/logo/logo_text_white.png"
                class="logo light cursor-pointer"
                @click="$router.push({ name: 'home' })"
              />
            </div>
            <div class="right">
              <ul class="menu-general">
                <li>
                  <a
                    v-if="!user"
                    :class="['cursor-pointer']"
                    @click="open_browser(`/contact`)"
                  >
                    contactanos
                  </a>
                </li>
                <li>
                  <a
                    v-if="!user"
                    :class="[
                      'cursor-pointer',
                      'login_register',
                      $route.name == 'login' && 'active',
                    ]"
                    @click="$router.push({ name: 'login' })"
                  >
                    Accede
                  </a>
                </li>
                <li class="user_menu">
                  <a
                    class="text-dark"
                    v-if="user"
                    @click="
                      () => {
                        this.$store.dispatch('modals/toggle', {
                          id: 'modal_menu',
                          dark: false,
                        });
                      }
                    "
                  >
                    <div
                      class="avatar"
                      :style="{ backgroundImage: `url(${user.avatar})` }"
                    >
                      <div class="icon-premium" v-if="subscription_status">
                        <i
                          class="fi fi-sr-bolt"
                          :class="[
                            subscription_level === 'access' ? 'text-light' : 'text-info',
                          ]"
                        ></i>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    visiblity_forced() {
      const on_tab_home =
        (this.$route?.name == "index" && this.$route?.query?.tab == "diagnosis") ||
        (this.$route?.name == "index" && this.$route?.query?.tab == "emergency") ||
        (this.$route?.name == "index" && this.$route?.query?.tab == "index") ||
        (this.$route?.name == "index" && !this.$route?.query?.tab);

      return (
        this.$route.name == "player" ||
        this.$route.name == "appointment" ||
        this.$route.name == "do_you_have_anxiety" ||
        on_tab_home
      );
    },

    darkmode() {
      return this.$store.state.template.darkmode;
    },

    user() {
      return this.$store.state.auth.user;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },

    subscription_level() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.level
      );
    },
  },
});
</script>
