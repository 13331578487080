<template>
  <div class="section-title mb-2">
    <h3 class="m-0">Recuperar clave</h3>
    <p class="text-muted m-0">
      Ingresa tu correo electrónico y te enviaremos instrucciones de que debes
      hacer para recuperar tu clave.
    </p>
  </div>
</template>

<script>
  export default {};
</script>
