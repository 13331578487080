export default async function ({ page = 1, limit = 3 }) {
  const alert = this.alert;
  const route = new this.controllers.Route();

  if (this.screen_resolution(this) == "mobile") {
    limit = 2;
  }

  const { status, response } = await route.search_by({
    page,
    limit,
    search_by: JSON.stringify([
      {
        column: "is_101",
        value: 0,
      },
    ]),
  });

  if (status != "SUCCESS" && response.message) {
    alert.error(response.message);
  }

  this.sample_routes = response.routes || [];
}
