<template>
  <div
    :class="['diagnosis', 'p-3', 'animate__animated', ...classes]"
    @mouseenter="remove_classes_on_hover()">
    <div class="block title">
      <div class="t_continue">Continua</div>
      <div class="t_title">
        {{ route.title }}
      </div>
      <div class="t_description">
        {{ route.description }}
      </div>
      <div
        v-if="route.my_progress.misssing_units > 0"
        class="t_remain">
        Tienes
        <span>{{ route.my_progress.misssing_units }} unidades</span> por
        finalizar
      </div>
      <div
        v-if="route.my_progress.misssing_units == 0"
        class="t_remain">
        Genial, has terminado 🩵
      </div>
    </div>

    <div class="block goal">
      <div class="progress-bar">
        <div
          :style="{ width: `${route.my_progress.percent}%` }"
          class="progress"></div>
      </div>

      <div
        v-if="route.my_progress.percent < 100"
        class="b_continue cursor-pointer"
        @click="open_modal_route()">
        <span
          v-if="route.my_progress.percent == 0"
          class="text-muted"
          >inicia</span
        >
        <span
          v-if="
            route.my_progress.percent > 0 && route.my_progress.percent < 100
          "
          class="text-muted"
          >continua</span
        >
        <i class="fi fi-rr-angle-small-right"></i>
      </div>
    </div>

    <div class="block buttons d-flex justify-content-between">
      <component-button
        :classes="[
          'btn',
          'btn-block',
          'btn-nopadding',
          'btn-black',
          'transparent',
        ]"
        @click="open_modal_route()">
        <i class="fi fi-rr-play"></i>
        <span v-if="route.my_progress.percent == 0">iniciar ruta</span>
        <span
          v-if="
            route.my_progress.percent > 0 && route.my_progress.percent < 100
          "
          >continuar</span
        >
        <span v-if="route.my_progress.percent == 100">ver ruta</span>
      </component-button>

      <component-button
        :classes="[
          'btn',
          'btn-block',
          'btn-nopadding',
          'btn-black',
          'transparent',
        ]"
        @click="open_browser(`/contact`)">
        <i class="fi fi-rr-headset"></i>
      </component-button>
    </div>

    <div
      class="block notifications"
      v-if="screen_resolution(this) == 'mobile'">
      <component-appointments />
    </div>
  </div>
</template>

<script>
  // Components
  import component_appointments from "../../../components/appointments/index.vue";

  export default {
    props: ["route"],

    components: {
      "component-appointments": component_appointments,
    },

    computed: {
      user() {
        return this.$store.state.auth.user;
      },

      subscription_is_necessary() {
        const user = this.$store.state.auth.user;
        const route = this.route;

        return (
          route &&
          route.is_public == 0 &&
          route.primary == 0 &&
          user &&
          user.subscription == null
        );
      },
    },

    data() {
      return {
        classes: [],
      };
    },

    mounted() {
      this.check_if_we_should_apply_any_effect();
    },

    methods: {
      check_if_we_should_apply_any_effect() {
        const onboarding_processed_02 = localStorage.getItem(
          "onboarding_processed_02"
        );

        if (!onboarding_processed_02 && this.user?.auth_counts == 1) {
          localStorage.setItem("onboarding_processed_02", true);
          // this.classes.push("animate__pulse", "animate__infinite");
        } else {
          this.classes.push("animate__fadeIn");
        }
      },

      remove_classes_on_hover() {
        this.classes = this.classes.map((x) => {
          // if (x != "animate__pulse" && x != "animate__infinite") {
          //   return x;
          // }
        });
      },

      open_modal_route() {
        const alert = this.alert;

        if (this.subscription_is_necessary) {
          alert.error(
            "Entendemos que puede ser un bajón no tener una suscripción activa. ¡Pero no te preocupes! Aquí está la solución: simplemente dirígete a nuestra página de suscripciones y elige la que más te guste. ¡Estamos aquí para asegurar que tu experiencia sea suave y gratificante!"
          );

          setTimeout(() => {
            this.$store.dispatch("modals/toggle", {
              id: "modal_subscription",
            });
          }, 1500);
          return;
        }

        this.$store.dispatch("modals/toggle", {
          id: "modal_player",
          update: {
            route: {
              $route: this.$route,
              $router: this.$router,
              query: {
                route_id: this.route.id,
              },
            },
          },
          callbacks: {
            onclose: () => {
              this.$store.dispatch("template/update_daemons", {
                index_continue: new Date(),
                index_notifications: new Date(),
              });
            },
          },
        });
      },
    },
  };
</script>
