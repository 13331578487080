<template>
  <div :class="['d-flex w-100 component__footer', ...classes]">
    <div class="footer w-100">
      <div class="block-logo" v-if="logo">
        <div class="logo">
          <img style="height: 27px" src="/assets/logo/logo_text_white.png" />
        </div>
      </div>

      <div class="block-adress m-0 mt-2" v-if="address">
        <div class="company d-flex gap-1 align-items-center">
          <span class="badge dark small" v-if="version && current_version">{{
            current_version
          }}</span>

          <b>memeonlyme LLC</b>
        </div>

        <div class="address text-muted mt-2">
          Miami, Florida 33132111 <br />
          NE 1st St, 8th Floor Suite
        </div>
      </div>

      <div class="block-links mt-2" v-if="terms">
        <ul>
          <li>
            <a class="d-flex gap-1 text-decoration-none" @click="open_browser(`/terms`)">
              <span>Términos y condiciones de uso</span>
            </a>
          </li>
          <li>
            <a class="d-flex gap-1 text-decoration-none" @click="open_browser(`/privacy`)">
              <span>Políticas de privacidad </span>
            </a>
          </li>
        </ul>
      </div>

      <div class="block-links mt-2 d-flex gap-3 flex-wrap">
        <component-button :classes="['btn', 'btn-nopadding', 'btn-light', 'btn-transparent']" type="button"
          @click="open(`https://wa.me/${app_settings.app_whatsapp}`)" :loading="false">
          <i class="fi fi-brands-whatsapp"></i>
          <span>Whatsapp</span>
        </component-button>

        <component-button :classes="['btn', 'btn-nopadding', 'btn-light', 'btn-transparent']" type="button"
          @click="copy_email()" :loading="false">
          <i class="fi fi-rr-at"></i>
          <span>Email</span>
        </component-button>

        <component-button v-if="links" :classes="['btn', 'btn-nopadding', 'btn-light', 'btn-transparent']" type="button"
          @click="open(`https://www.instagram.com/${app_settings.app_instagram}`)" :loading="false">
          <i class="fi fi-brands-instagram"></i>
          <span>Instagram</span>
        </component-button>
      </div>

      <div v-if="$store.state.template.testing_mode" class="mt-2 d-flex">
        <div class="badge dark">sanbox</div>
      </div>

      <div class="block-disclaimer" v-if="disclaimer">
        <p class="m-0 mt-2 text-muted text-italic">
          Aviso: Es importante que prestes atención a esto! En nuestra plataforma
          encontrarás contenido audiovisual pregrabado, que tienen como objetivo proveerte
          de herramientas, técnicas, estrategias, consejos y acompañamiento, respecto a
          cualquier malestar que estés experimentando. En este sentido, los contenidos de
          nuestra plataforma no representan, bajo ningún concepto, un tratamiento
          psicológico. Lo que usted sienta, experimente, piense, intérprete o concluya
          durante el uso de la plataforma, queda bajo su completa responsabilidad.
        </p>
        <p class="m-0 mt-2 text-muted text-italic">
          memeonlyme es un espacio para el autoconocimiento consciente y responsable de
          todo lo que amerita e implica un proceso como este. Si usted siente que necesita
          un soporte mucho más profundo y especializado, por favor, contacte a un
          profesional de la salud mental.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import go from "../../../helpers/go";
import { Browser } from "@capacitor/browser";

export default {
  props: {
    logo: {
      default: false,
    },

    address: {
      default: true,
    },

    links: {
      default: false,
    },

    terms: {
      default: true,
    },

    terms_onclick: {
      default: null,
    },

    disclaimer: {
      default: false,
    },

    classes: {
      default: () => [],
      type: Array,
    },

    version: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    current_version() {
      return localStorage.getItem("utm_version");
    },

    app_settings() {
      return this.$store.state.template.app_settings;
    }
  },

  methods: {
    go,

    async open(url) {
      await Browser.open({ url });
    },

    copy_email() {
      const email = this.app_settings.app_admin_mail;
      navigator.clipboard.writeText(email);
      this.alert.info(
        `Copiamos nuestro correo ${email} a su portapapeles, ve a tu aplicación de correo favorito, pega el correo y escríbenos.`
      );
    },
  },
};
</script>
