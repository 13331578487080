export default async function (ctx) {
  const { answer, symptom, category } = ctx;
  const user = new this.controllers.User();
  const tab = this.$route.query.tab;
  const mode = tab === "emergency" ? tab : "suggestions";

  if (category) {
    category.symptoms.forEach((symptom_id) => {
      user.store_symptoms({
        symptom_id,
        has: 1,
        mode,
      });
    });
  }

  if (answer && symptom) {
    this.symptoms = this.symptoms.filter((s) => s.id != symptom.id);

    user.store_symptoms({
      symptom_id: symptom.id,
      has: answer == "yes" ? 1 : 0,
      mode,
    });

    this.questions_answered += 1;
  }
}
