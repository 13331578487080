import Request from "../helpers/request";

export default class {
  #request = new Request();
  async get_subscriptions(body) {
    return await this.#request.post({
      path: "/api/subscription/get_subscriptions",
      body,
    });
  }

  async store(body) {
    return await this.#request.post({
      path: "/api/subscription/store",
      body,
    });
  }
  async cancel(body) {
    return await this.#request.post({
      path: "/api/subscription/cancel",
      body,
    });
  }
}
