<template>
  <div class="d-flex flex-column gap-3">
    <div class="title d-flex flex-column gap-1">
      <div class="d-flex gap-2 justify-content-between align-items-center">
        <h4 class="m-0 d-flex gap-2 align-items-center">
          Explora
          <emoji size="38px" file="grinning_face_with_big_eyes.png" />
        </h4>

        <span
          class="d-flex btn btn-dark w-fit btn-small"
          @click="
            $store.dispatch('modals/toggle', {
              id: 'modal_suggestions_filters',
            })
          "
        >
          <i class="fi fi-rr-search lh-1"></i>
          <span>¿Buscas algo?</span>
        </span>
      </div>
      <p class="text-muted m-0">
        Seleccionaremos cuidadosamente los recursos pensando en ti y en tu búsqueda de
        bienestar. Esperamos que te sean de gran ayuda en tu camino hacia una vida plena y
        equilibrada.
      </p>
    </div>

    <div
      v-if="loading"
      class="box p-4 w-100 d-flex justify-content-center align-items-center"
      style="min-height: 360px"
    >
      <div class="loader"></div>
    </div>

    <div class="component__list_of_items" v-if="!loading && has_notifications">
      <div class="two-column" v-if="sample_routes.length > 0">
        <div class="block">
          <component-cover
            v-for="route in sample_routes"
            :incognito="true"
            :incognito_callbacks="
              () => {
                this.open_modal('modal_moods');
              }
            "
            :route="route"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!loading && has_notifications"
      class="buttons d-flex w-100 mt-1 justify-content-left align-items-center"
    >
      <component-button
        :classes="['btn', 'btn-dark']"
        type="button"
        @click="
          () => {
            this.open_modal('modal_moods');
          }
        "
      >
        <i class="fi fi-rr-magic-wand"></i>
        <span>Comienza</span>
      </component-button>

      <component-button
        :classes="['btn', 'btn-transparent', 'btn-light']"
        type="button"
        @click="
          () => {
            this.$store.dispatch('modals/toggle', {
              id: 'modal_onboarding_suggestions',
            });
          }
        "
      >
        <i class="fi fi-rr-bulb"></i>
        <span>¿Como funciona?</span>
      </component-button>
    </div>

    <div class="d-flex flex-column" v-show="!has_notifications">
      <component-carousels-routes
        ref="carousels-routes"
        name="suggestions"
        :key="'suggestions'"
        :lockable="true"
        :lockable_callbacks="open_modal_subscription"
        :from="get_routes"
        :preview="true"
        :slider="false"
        :pagination="true"
      />
    </div>

    <div class="d-flex flex-column">
      <div class="text-muted">
        Para optimizar los resultados que estás obteniendo, es fundamental que actualices
        regularmente tu información. Te invitamos a
        <b @click="open_modal('modal_moods')"
          >registrar cada uno de tus síntomas de manera detallada aquí.</b
        >
      </div>
    </div>
  </div>
</template>

<script>
// Methods
import get_routes from "../methods/get_routes";
import get_last_two_routes from "../methods/get_last_two_routes";

export default {
  computed: {
    has_notifications() {
      return this.notifications.length > 0;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },
  },

  data() {
    return {
      loading: true,
      notifications: [],
      routes: [],
      sample_routes: [],
      route_undefined: {
        id: "test",
        format: "audio",
        image: "",
        title: "",
        units: 0,
        my_progress: { percent: 0 },
      },
    };
  },

  mounted() {
    this.get_last_two_routes({ page: 1, limit: 2 });
  },

  methods: {
    get_routes,
    get_last_two_routes,

    open_modal_subscription() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_subscription",
      });
    },

    open_modal(id) {
      if (!id) {
        return;
      }

      let callbacks = {};

      if (id == "modal_moods") {
        callbacks = {
          onclose: () => {
            this.$store.dispatch("template/update_daemons", {
              index_suggestions: new Date(),
            });
          },
        };
      }

      this.$store.dispatch("modals/toggle", {
        id,
        callbacks,
      });
    },
  },
};
</script>
