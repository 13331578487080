<template>
  <div class="diagnosis loading animate__animated animate__fadeIn">
    <div class="block d-flex justify-content-center align-items-center">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>
