export default async function () {
  const appointment = new this.controllers.Appointment();
  const track = new this.controllers.Track();
  const alert = this.alert;

  this.loading = true;

  if (!this.selected_month || !this.selected_hour) {
    alert.error(
      "¡Vamos a apartar tu lugar, solo necesitamos que elijas la fecha y la hora que más te convenga."
    );

    this.loading = false;
  }

  //
  let selected_space = this.selected_hour;
  let start_at = selected_space?.server?.replace("|", " ");

  if (!start_at) {
    alert.error(
      "Parece que hubo un pequeñín problema con la configuración de tu fecha de reserva 🤔. ¿Podrías cerrar la app y volver a abrirla? Cuéntame si eso soluciona el inconveniente o si seguimos en la misma, para echarte una mano 🙌"
    );
    this.loading = false;
    return;
  }

  const { status, response } = await appointment.store({
    start_at,
  });

  if (response.errors && response.errors.length > 0) {
    alert.error(response.errors[0]);
    this.loading = false;
    return;
  }

  if (response.status == "ERROR" && response.message) {
    alert.error(response.message);
    this.loading = false;
    return;
  }

  if (response.status == "SUCCESS" && response.message) {
    alert.success(response.message);
    this.loading = false;
    return;
  }

  track.add({
    user_id: this.user.id,
    name: `session`,
    category: ["events", "lifecycle", "session_request"].join(","),
    labels: `activation`,
    update: true,
  });

  alert.success(
    "¡Genial! Todo está listo para tu cita, no olvides echarle un vistazo a tu correo, te mandaremos un mensajito allí y también por WhatsApp."
  );

  this.$store.dispatch("modals/toggle", {
    id: "modal_appointment",
    force: "close",
  });

  this.loading = false;
}
