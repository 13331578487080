export default async function () {
  const appointment = new this.controllers.Appointment();
  const { status, response } = await appointment.get_notifications();

  if (!response?.title) {
    return;
  }

  this.notification.code = response.code;
  this.notification.title = response.title;
  this.notification.message = response.message;
  this.appointment = response.appointment;
}
