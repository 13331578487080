<template>
  <component-modal
    id="modal_getnps"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="true"
    :onopened="init"
    :permanent_mode="true"
    :with_close_button="false"
    :progress="progress_width"
  >
    <div class="content-modal" v-if="!ask_feedback">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <h4 class="m-0 d-flex align-items-center gap-2 justify-content-center">
              Ayudanos
              <emoji size="38px" file="direct_hit.png" />
            </h4>

            <p class="description m-0 text-muted text-center">
              Cuéntanos sobre tu experiencia en la ruta: <b>{{ route.title }}</b
              >, unidad: <b>{{ unit.title }}</b
              >, nos ayudará para poder crear mejor contenido para el futuro.
            </p>

            <div
              class="buttons d-flex justify-content-center align-items-center gap-4 mt-3"
            >
              <emoji size="42px" file="thumbs_up.png" @click="positive()" />

              <emoji size="42px" file="thumbs_down.png" @click="negative()" />
            </div>

            <p class="description m-0 mt-3 text-muted text-center">
              Selecciona la opción que te parezca correcta y podrás continuar con tu
              unidad.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content-modal" v-if="ask_feedback">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <h4 class="m-0 d-flex align-items-center gap-2 justify-content-center">
              ¿Porque?
              <emoji size="38px" file="speak_no_evil_monkey.png" />
            </h4>

            <p class="description m-0 text-muted text-center">
              Saber por qué nos calificaste negativo nos ayudará a no repetir el problema,
              cuéntanos dando clic en el siguiente boton.
            </p>

            <div class="buttons d-flex justify-content-center mt-2 gap-2">
              <component-button
                :classes="['btn', 'btn-block', 'btn-light']"
                @click="feedback()"
              >
                <i class="fi fi-rr-comment-quote"></i>
                <span>Cuentanos</span>
              </component-button>

              <component-button
                :classes="['btn', 'btn-block', 'btn-transparent', 'text-light']"
                @click="close_modal()"
              >
                <i class="fi fi-rr-play"></i>
                <span>Continua</span>
              </component-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  data() {
    return {
      ask_feedback: false,
      route: {},
      unit: {},
      progress_width: 0,
      duration_ms: 1000 * 60,
    };
  },

  methods: {
    init() {
      this.ask_feedback = false;
      this.route = {};
      this.unit = {};
      this.progress_width = 0;

      this.get_reference_vars();
      this.fill_progress_bar();

      setTimeout(() => {
        this.close_modal();
      }, this.duration_ms);
    },

    get_reference_vars() {
      const _ = this.$refs.modal._;

      Object.keys(_).forEach((k) => {
        this[k] = _[k];
      });
    },

    positive() {
      const track = new this.controllers.Track();

      const user_id = this.user.id;
      const route_id = this.route.id;
      const unit_id = this.unit.id;
      const name = "nps_01";
      const value = "positive";
      const category = "questions";
      const labels = "quality";

      track.add({
        user_id,
        route_id,
        unit_id,
        name,
        value,
        category,
        labels,
        update: true,
      });

      this.close_modal();
    },

    negative() {
      const track = new this.controllers.Track();

      const user_id = this.user.id;
      const route_id = this.route.id;
      const unit_id = this.unit.id;
      const name = "nps_01";
      const value = "negative";
      const category = "questions";
      const labels = "quality";

      track.add({
        user_id,
        route_id,
        unit_id,
        name,
        value,
        category,
        labels,
        update: true,
      });

      this.ask_feedback = true;
    },

    feedback() {
      const url = `/feedback`;

      this.close_modal();
      this.open_browser(url, {
        route_id: this.route.id,
        unit_id: this.unit.id,
        experience: "Insatisfactoria",
      });
    },

    close_modal() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_getnps",
        close_others_modal: false,
        force: "close",
      });
    },

    fill_progress_bar() {
      const increment = 100 / (this.duration_ms / 100);
      const interval = setInterval(() => {
        if (this.progress_width >= 100) {
          clearInterval(interval);
        } else {
          this.progress_width += increment;
        }
      }, 100);
    },
  },
};
</script>
