<template>
  <ion-content class="page__login">
    <div
      :class="[
        'wrapper-container',
        'wrapper',
        'wrapper-spacing',
        'light-pattern',
        'd-flex',
        'flex-column',
        'align-items-center',

        'p-4',

        'justify-content-end',
        'justify-content-sm-center',
      ]"
    >
      <div class="section-form d-flex flex-column">
        <!-- Mobile -->
        <component-title v-if="$windowWidth <= 768" />

        <div class="box d-flex flex-column p-3">
          <!-- Desktop -->
          <component-title v-if="$windowWidth > 768" />

          <form class="d-flex flex-column gap-1 section-inputs">
            <component-input
              type="email"
              placeholder="Correo electronico"
              name="email"
              :default="forms.auth.inputs.email.value"
              :error="forms.auth.inputs.email.error"
              :loading="forms.auth.loading"
              label="Correo electronico"
              :onupdate="(x) => (forms.auth.inputs.email.value = x)"
            />

            <div class="input-password">
              <component-input
                label="Clave"
                type="password"
                placeholder="Clave"
                name="clave"
                :default="forms.auth.inputs.password.value"
                :classes="['mt-2']"
                :error="forms.auth.inputs.password.error"
                :loading="forms.auth.loading"
                :onupdate="(x) => (forms.auth.inputs.password.value = x)"
              />

              <p class="d-flex justify-content-end mt-2 mb-0">
                <ion-nav-link @click="go({ name: 'recover' })">
                  ¿Olvidates tu clave?
                </ion-nav-link>
              </p>
            </div>
          </form>

          <div class="row mt-2" v-if="forms.auth.error">
            <div class="col-12">
              <div class="alert primary m-0">{{ forms.auth.error }}</div>
            </div>
          </div>

          <div class="row mt-2">
            <div
              class="col-12 d-flex gap-2 flex-column justify-content-end align-items-end"
            >
              <div class="d-flex gap-2">
                <component-button
                  :classes="['btn', 'btn-block', 'btn-secondary']"
                  type="button"
                  :execute="{
                    callback: go,
                    arguments: { name: 'register' },
                  }"
                >
                  <i class="fi fi-rr-glass-cheers"></i>
                  <span>Registrate</span>
                </component-button>

                <component-button
                  :classes="['btn', 'btn-block', 'btn-primary']"
                  type="button"
                  @click="submit"
                  :disabled="forms.auth.loading"
                  :loading="forms.auth.loading"
                >
                  <i class="fi fi-rr-arrow-right"></i>
                  <span>Accede</span>
                </component-button>
              </div>

              <hr class="hr m-0" />

              <div class="d-flex gap-2 w-100">
                <component-button
                  :class="['w-100']"
                  :classes="['btn', 'btn-block', 'w-100', 'btn-google']"
                  type="button"
                  :disabled="with_google_loading"
                  :loading="with_google_loading"
                  @click="with_google()"
                >
                  <i class="lh-1 fi fi-brands-google"></i>
                  <span>Google</span>
                </component-button>

                <component-button
                  v-if="current_device() == 'ios'"
                  :class="['w-100']"
                  :classes="['btn', 'btn-block', 'w-100', 'btn-dark']"
                  type="button"
                  :disabled="with_apple_loading"
                  :loading="with_apple_loading"
                  @click="with_apple()"
                >
                  <i class="lh-1 fi fi-brands-apple"></i>
                  <span>Apple</span>
                </component-button>
              </div>
            </div>
          </div>

          <div class="row disclaimer mt-2">
            <div class="col-12">
              <p class="m-0 text-muted">
                Al navegar y usar nuestra aplicación, estás aceptando nuestros
                <span class="font-weight-semibold" @click="open_browser(`/terms`)"
                  >términos y condiciones</span
                >
                y
                <span class="font-weight-semibold" @click="open_browser(`/privacy`)"
                  >nuestra política de privacidad</span
                >, te invitamos a leerlos si tienes alguna duda.
              </p>
            </div>
          </div>
        </div>

        <div class="section-footer mt-2 mt-sm-4">
          <component-footer address="true" :links="false" :terms="true" />
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

// Components
import pageTitleComponent from "./components/page-title.vue";

// Methods
import build from "./methods/build";
import checks from "./methods/checks";
import submit from "./methods/submit";
import go from "./methods/go";
import with_google from "./methods/with_google";
import with_apple from "./methods/with_apple";

export default defineComponent({
  components: {
    "component-title": pageTitleComponent,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    "forms.auth.inputs.email.value": function () {
      if (this.forms.auth.inputs.email.value === "1testing") {
        this.alert.info("Testing enabled...");
        localStorage.setItem("testing", "1");
        this.$store.dispatch("template/update_testingmode", true);
      }

      if (this.forms.auth.inputs.email.value === "0testing") {
        this.alert.info("Testing disabled...");
        localStorage.removeItem("testing");
        this.$store.dispatch("template/update_testingmode", false);
      }

      if (this.forms.auth.inputs.email.value === "clearall") {
        this.alert.info("Alll data cleared...");
        localStorage.clear();
        sessionStorage.clear();
      }
    },

    "$store.state.template.app_settings"() {
      this.ab({
        vm: this,
        fn: "mounted",
      });
    },
  },

  data() {
    return {
      with_google_loading: false,
      with_apple_loading: false,
      loading_pay: false,

      forms: {
        auth: {
          loading: false,
          error: null,

          inputs: {
            email: {
              value: "",
              error: null,
            },

            password: {
              value: "",
              error: null,
            },
          },
        },
      },
    };
  },

  beforeMount() {
    this.checks();
  },

  mounted() {
    this.build();

    this.ab({
      vm: this,
      fn: "mounted",
    });

    this.user &&
      this.$router.push({
        name: "index",
      });

    if (this.$route.query?.token) {
      this.submit();
    }
  },

  methods: {
    go,
    submit,
    build,
    checks,
    with_google,
    with_apple,
  },
});
</script>
