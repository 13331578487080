<template>
  <component-modal
    id="modal_thanks"
    ref="modal"
    :classes_modal="['center', 'p-2', 'small']"
    :classes_wrapper="['p-4']"
    :clean="false"
    :dark="false"
    :permanent_mode="false"
    :with_close_button="true"
  >
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div
              class="block-title d-flex gap-1 justify-content-center align-items-center flex-column"
            >
              <emoji size="38px" file="fire.png" />

              <h4 class="m-0 text-center">Gracias!</h4>
              <p class="description m-0 text-muted text-center">
                <b>Ya todo está listo,</b> puedes continuar disfrutando de nuestra
                aplicación al máximo. Recuerda: si necesitas gestionar tu subscripción
                debes hacerlo a través de la tienda de aplicaciones.
              </p>
            </div>

            <div
              class="buttons d-flex gap-2 mt-2 justify-content-center align-items-center"
            >
              <component-button
                v-if="subscription_level === 'access'"
                :class="['w-100']"
                :classes="['btn', 'btn-block', 'btn-dark']"
                @click="close_modal()"
              >
                <i class="fi fi-rr-bolt"></i>
                <span> Sigue explorando </span>
              </component-button>

              <component-button
                v-if="subscription_level === 'full'"
                :class="['w-100']"
                :classes="['btn', 'btn-block', 'btn-dark']"
                @click="close_modal()"
              >
                <i class="fi fi-rr-bolt"></i>
                <span> Inicia diagnosis </span>
              </component-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },

    subscription_level() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.level
      );
    },
  },

  methods: {
    async close_modal() {
      if (this.subscription_level === "access") {
        this.$router.push({
          name: "index",
          query: { tab: "suggestions" },
        });
      }

      if (this.subscription_level === "full") {
        this.$router.push({
          name: "index",
          query: { tab: "diagnosis" },
        });
      }

      this.$store.dispatch("modals/toggle", {
        id: "modal_thanks",
      });
    },
  },
};
</script>
