export default async function ({
  route = null,
  unit = null,
  question = {},
  answer = {},
}) {
  const track = new this.controllers.Track();

  const user_id = this.user.id;
  const route_id = route ? route.id : null;
  const unit_id = unit ? unit.id : null;
  const name = question.description;
  const value = answer.text;
  const category = "questions";
  const labels = question.category;

  this.loading[answer.id] = true;

  track.add({
    user_id,
    route_id,
    unit_id,
    name,
    value,
    category,
    labels,
    update: true,
  });

  this.selected = answer.id;
  this.loading[answer.id] = false;
  this.opened = false;

  this.$store.dispatch("player/add_questions_answered", question.id);
}
