import { v4 as uuidv4 } from "uuid";
import app_version from "@/version.json";
import app_segmentation from "@/segmentation.json";

const set_utm_params = async ({ vm }) => {
  const ls = localStorage;
  const local_storage_values = {
    utm_campaign: ls.getItem("utm_campaign") || "",
    utm_version: app_version.version,
    utm_segmentation: app_segmentation.segmentation,
    utm_testing: ls.getItem("utm_testing") || "",
    utm_medium: ls.getItem("utm_medium") || "",
    utm_content: ls.getItem("utm_content") || "",
    utm_term: ls.getItem("utm_term") || "",
    utm_source: ls.getItem("utm_source") || "",
    utm_device: vm.current_device(),
    utm_reffered: ls.getItem("utm_reffered") || "",
    r: ls.getItem("r") || "",
  };

  let params = { ...vm.$route.query };

  //
  if (ls.getItem("testing")) {
    local_storage_values.utm_segmentation = "testing";
    local_storage_values.utm_version = "development";
  }

  // Old version
  if (vm.$route.query.r) {
    params.utm_reffered = vm.$route.query.r;
  }

  Object.entries(local_storage_values).forEach(([key, value]) => {
    if (params[key]) {
      value = params[key];
      delete params[key];
    }

    // Update utm params
    ls.setItem(key, value);
  });

  if (Object.keys(params).length >= 1) {
    vm.$router.replace({ query: params });
  }
};

const set_temporal_id = function () {
  localStorage.setItem("temporal_id", uuidv4());
};

export default async function () {
  this.ga_event("app_open", {});
  set_utm_params({ vm: this });
  set_temporal_id();
}
