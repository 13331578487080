<template>
  <div class="block features v1">
    <div class="feature" v-for="x in items">
      <emoji size="28px" :file="x.emoji" />
      <span class="title mt-2">{{ x.title }}</span>
      <span class="description">{{ x.description }}</span>
    </div>
  </div>
</template>

<script>
// Others elements
import { defineComponent } from "vue";

export default defineComponent({
  props: ["benefits"],

  computed: {
    items() {
      const subscription = this.$store?.state?.auth?.user?.subscription;
      const items =
        this.benefits && this.benefits.length > 0 ? this.benefits : this.default;
      const get_items_for = !subscription ? "access" : "full";
      return items.filter((x) => x.subscription == get_items_for);
    },
  },

  data() {
    return {
      default: [
        {
          emoji: "check_mark_button.png",
          title: "Estrategias",
          description: "Rutas basadas en tus síntomas especificos",
          subscription: "access",
        },
        {
          emoji: "check_mark_button.png",
          title: "Chat",
          description: "Con psicólogos para que te orienten",
          subscription: "access",
        },
        {
          emoji: "check_mark_button.png",
          title: "Sesiones",
          description: "Live grupales cada mes para responder dudas",
          subscription: "access",
        },
        {
          emoji: "check_mark_button.png",
          title: "Contenido nuevo",
          description: "Si no encuentras una ruta, nosotros la crearemos!",
          subscription: "access",
        },
        {
          emoji: "check_mark_button.png",
          title: "Acceso completo",
          description: "Beneficios de la subscripción Access",
          subscription: "full",
        },
        {
          emoji: "check_mark_button.png",
          title: "Sesiones 1-1",
          description: "Nuestros psicólogos te guiaran en sesiones en vivo",
          subscription: "full",
        },
        {
          emoji: "check_mark_button.png",
          title: "Acompañamiento",
          description: "Durante tu proceso y luego de él",
          subscription: "full",
        },
        {
          emoji: "check_mark_button.png",
          title: "Planes a medida",
          description: "Basado en tu historia personal",
          subscription: "full",
        },
      ],
    };
  },
});
</script>
