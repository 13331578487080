<template>
  <component-onboarding-v2 v-if="version === 'v2'" />
</template>

<script>
// Components
import component_onboarding_v2 from "./v2/index.vue";

export default {
  components: {
    "component-onboarding-v2": component_onboarding_v2,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    version() {
      return this.$route?.query?.version ?? "v2";
    },
  },

  watch: {
    "$store.state.template.app_settings"() {
      this.ab({
        vm: this,
        fn: "mounted",
      });
    },
  },

  mounted() {
    this.ab({
      vm: this,
      fn: "mounted",
    });
  },
};
</script>
