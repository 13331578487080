import Repository from "../repositories/track";
import { v4 as uuidv4 } from "uuid";

export default class {
  #repository = new Repository();

  async add({
    name = "",
    value = "",
    user_id = null,
    route_id = null,
    feedback_id = null,
    temporal_id = null,
    unit_id = null,
    media_id = null,
    event_id = null,
    category = null,
    labels = null,
    debug = null,
    update = false,
  }) {
    if (!user_id) {
      let user = localStorage.getItem("user");

      if (user) {
        user = JSON.parse(user);
        user_id = user.id;
      }
    }

    if (debug) {
      debug = JSON.stringify(debug);
    }

    temporal_id = localStorage.getItem("temporal_id");

    if (!temporal_id) {
      const id = uuidv4();
      localStorage.setItem("temporal_id", id);
      temporal_id = id;
    }

    return await this.#repository.add({
      name,
      value,
      user_id,
      route_id,
      temporal_id,
      media_id,
      feedback_id,
      unit_id,
      event_id,
      category,
      labels,
      debug,
      update,
    });
  }
}
