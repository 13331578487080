<template>
  <component-box
    v-show="title"
    :classes="['dark', 'notification', 'animate__animated', 'animate__fadeIn']"
    :title="title"
    :description="message"
    :buttons="buttons"
    :with_close_icon="false"
  />
</template>

<script>
// Methods
import get_notifications from "./methods/get_notifications";
import confirm_appointment from "./methods/confirm_appointment";
import cancel_appointment from "./methods/cancel_appointment";

export default {
  computed: {
    message: function () {
      return this.notification.message || null;
    },

    title: function () {
      return this.notification.title || null;
    },

    buttons: function () {
      if (this.notification.code == "003" || this.notification.code == "004") {
        return [
          {
            classes: ["btn", "btn-small", "btn-primary"],
            onclick: () => {
              this.$store.dispatch("modals/toggle", {
                dark: false,
                id: "modal_appointment",
                callbacks: {
                  onclose: () => {
                    this.$store.dispatch("template/update_daemons", {
                      index_notifications: new Date(),
                    });
                  },
                },
              });
            },
            loading: this.loading_cancel,
            text: "Agendar orientacion",
          },
        ];
      }

      if (this.notification.code == "008") {
        return [
          {
            classes: ["btn", "btn-small", "btn-secondary"],
            onclick: () => {
              this.open_browser(`/contact`);
            },
            loading: this.loading_cancel,
            text: "Contactanos",
          },
        ];
      }

      if (this.notification.code == "005") {
        return [
          {
            classes: ["btn", "btn-small", "btn-secondary"],
            onclick: this.confirm_appointment,
            loading: this.loading_confirm,
            text: "Confirmar",
          },

          {
            classes: ["btn", "btn-small", "btn-transparent", "text-light"],
            onclick: this.cancel_appointment,
            loading: this.loading_cancel,
            text: "Cancelar",
          },
        ];
      }

      if (this.notification.code == "009") {
        return [
          {
            classes: ["btn", "btn-small", "btn-primary"],
            onclick: this.cancel_appointment,
            loading: this.loading_cancel,
            text: "No podre asistir",
          },
        ];
      }

      if (this.notification.code == "007") {
        return [
          {
            classes: ["btn", "btn-small", "btn-primary"],
            onclick: () => {
              this.open_browser(`/contact`);
            },
            text: "Contactanos",
          },
        ];
      }

      return [];
    },
  },

  watch: {
    "$store.state.template.daemons.index_notifications": {
      handler() {
        this.get_notifications();
      },

      deep: true,
    },
  },

  data() {
    return {
      loading_cancel: false,
      loading_confirm: false,
      appointment: null,
      notification: {},
      interval: null,
    };
  },

  mounted() {
    this.get_notifications();
  },

  unmounted() {
    clearInterval(this.interval);
  },

  methods: {
    confirm_appointment,
    cancel_appointment,
    get_notifications,
  },
};
</script>
