<template>
  <div class="diagnosis-mobile">
    <component-diagnosis />
  </div>
</template>

<script>
  // Components
  import component_continue from "../../components/diagnosis/index.vue";

  export default {
    components: {
      "component-diagnosis": component_continue,
    },

    mounted() {
      this.$store.state.template.darkmode = true;
      this.track();
    },

    methods: {
      async track() {
        const track = new this.controllers.Track();

        await track.add({
          name: `dignosis`,
          category: ["events", "page_view", "mobile_only"].join(","),
        });
      },
    },
  };
</script>
