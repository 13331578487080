<template>
  <ion-content class="page__recover">
    <div
      :class="[
        'wrapper-container',
        'wrapper',
        'wrapper-spacing',
        'light-pattern',
        'd-flex',
        'flex-column',
        'align-items-center',

        'p-4',

        'justify-content-end',
        'justify-content-sm-center',
      ]">
      <div class="section-form d-flex flex-column">
        <!-- Mobile -->
        <component-title v-if="$windowWidth <= 768" />

        <div class="box d-flex flex-column p-3">
          <!-- Desktop -->
          <component-title v-if="$windowWidth > 768" />

          <form class="d-flex flex-column gap-1 section-inputs">
            <component-input
              v-if="!token"
              type="text"
              label="¿Cuál es tu correo?"
              placeholder="Correo electronico"
              :default="forms.recover.inputs.email.value"
              :error="forms.recover.inputs.email.error"
              :loading="forms.recover.loading"
              :onupdate="(x) => (forms.recover.inputs.email.value = x)" />

            <component-input
              v-if="token"
              type="password"
              placeholder="Nueva clave"
              label="¿Cuál clave quieres colocar ahora?"
              :default="forms.recover.inputs.password.value"
              :error="forms.recover.inputs.password.error"
              :loading="forms.recover.loading"
              :onupdate="(x) => (forms.recover.inputs.password.value = x)" />

            <p class="text-muted m-0 mt-2">
              Está atento a tu correo electrónico <br />
              Te enviaremos instrucciones y confirmaciones!
            </p>
          </form>

          <div
            class="row mt-2"
            v-if="forms.recover.error">
            <div class="col-12">
              <div class="alert dark m-0">{{ forms.recover.error }}</div>
            </div>
          </div>

          <div class="d-flex justify-content-end section-buttons mt-2">
            <div class="row">
              <div class="col-12">
                <div class="d-flex gap-2">
                  <component-button
                    :classes="['btn', 'btn-block', 'btn-primary']"
                    type="button"
                    :full_width="true"
                    :execute="{ callback: submit, arguments: {} }"
                    :loading="forms.recover.loading">
                    <i class="fi fi-rr-arrow-right"></i>
                    <span v-if="!token">recuperar clave</span>
                    <span v-if="token">cambiar clave</span>
                  </component-button>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end section-buttons mt-2">
            <div class="row">
              <div class="col-12">
                <div class="d-flex gap-2">
                  <component-button
                    :classes="['btn', 'btn-block', 'btn-secondary']"
                    type="button"
                    :execute="{
                      callback: go,
                      arguments: { name: 'register' },
                    }">
                    <i class="fi fi-rr-glass-cheers"></i>
                    <span>Registrate</span>
                  </component-button>

                  <component-button
                    :classes="['btn', 'btn-block', 'btn-secondary']"
                    type="button"
                    :execute="{
                      callback: go,
                      arguments: { name: 'login' },
                    }">
                    <i class="fi fi-rr-sign-in-alt"></i>
                    <span>Accede</span>
                  </component-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-footer mt-2 mt-sm-4">
          <component-footer
            address="true"
            :links="false"
            :terms="true" />
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
  // Others elements
  import { defineComponent } from "vue";

  // Components
  import pageTitleComponent from "./components/page-title.vue";

  // Methods
  import build from "./methods/build";
  import submit from "./methods/submit";
  import go from "./methods/go";

  export default defineComponent({
    components: {
      "component-title": pageTitleComponent,
    },

    data() {
      return {
        token: this.$route.query.token,
        forms: {
          recover: {
            loading: false,
            error: null,

            inputs: {
              name: {
                value: "",
                error: null,
              },

              email: {
                value: "",
                error: null,
              },

              whatsapp: {
                value: "",
                error: null,
              },

              password: {
                value: "",
                error: null,
              },
            },
          },
        },
      };
    },

    mounted() {
      this.build();
    },

    methods: {
      go,
      submit,
      build,
    },
  });
</script>
