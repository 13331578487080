export default function restore({ commit, dispatch }) {
  let user = localStorage.getItem("user");
  let authorization = localStorage.getItem("authorization");
  let authorization_expiration = localStorage.getItem(
    "authorization_expiration"
  );

  if (!user || !authorization) {
    return;
  }

  user = JSON.parse(user);
  authorization = JSON.parse(authorization);
  authorization_expiration = new Date(authorization_expiration * 1000);

  if (new Date() >= authorization_expiration) {
    commit("SET_USER", null);
    commit("SET_AUTHORIZATION", null);

    localStorage.clear();
  }

  commit("SET_USER", user);
  commit("SET_AUTHORIZATION", authorization);
}
