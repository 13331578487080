import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import {
  GOOGLE_AUTH_IOSCLIENTID,
  GOOGLE_AUTH_CLIENTID,
  GOOGLE_AUTH_SCOPES,
  GOOGLE_AUTH_GRANTOFFLINEACCESS,
  GOOGLE_AUTH_ANDROIDCLIENTID,
} from "@/configs/googleauth";

export default async function () {
  this.with_google_loading = true;

  GoogleAuth.initialize({
    iosClientId: GOOGLE_AUTH_IOSCLIENTID,
    clientId: GOOGLE_AUTH_CLIENTID,
    androidClientId: GOOGLE_AUTH_ANDROIDCLIENTID,
    scopes: GOOGLE_AUTH_SCOPES,
    grantOfflineAccess: GOOGLE_AUTH_GRANTOFFLINEACCESS,
  });

  setTimeout(() => {
    this.with_google_loading = false;
  }, 1000);
}
