<template>
  <div
    :class="['component__box', dark && 'dark', ...classes]"
    v-if="opened && show"
    @click="onclick && onclick()">
    <div
      :class="['small_version', 'content', clear && 'clear']"
      v-if="box_style == 'small'">
      <span
        v-if="with_close_icon"
        @click="close_box()"
        class="close_icon">
        <i class="fi fi-rr-cross-small"></i>
      </span>

      <div
        class="bg_icon"
        v-if="with_icon">
        <i :class="with_icon"></i>
      </div>

      <span
        class="badgets mb-2"
        v-if="labels.length >= 1">
        <span
          :class="['badge', ...label.classes]"
          v-for="label in labels">
          {{ label.text }}
        </span>
      </span>

      <span
        class="title"
        v-if="title"
        >{{ title }}</span
      >

      <span
        class="box-description"
        v-if="description">
        {{ description }}
      </span>

      <div
        class="buttons mt-2"
        v-if="buttons.length >= 1">
        <component-button
          v-for="button in buttons"
          :classes="button.classes"
          type="button"
          :execute="{ callback: button.onclick, arguments: button }"
          :loading="button.loading">
          <div v-html="button.text"></div>
        </component-button>
      </div>
    </div>

    <div
      :class="['big_version', 'content', clear && 'clear']"
      v-if="box_style == 'big'">
      <span
        v-if="with_close_icon"
        @click="close_box()"
        class="close_icon">
        <i class="fi fi-rr-cross-small"></i>
      </span>

      <div
        class="bg_icon"
        v-if="with_icon">
        <i :class="with_icon"></i>
      </div>

      <span
        class="badgets mb-2"
        v-if="labels.length >= 1">
        <span
          :class="['badge', ...label.classes]"
          v-for="label in labels">
          {{ label.text }}
        </span>
      </span>

      <h4
        class="title text-center lh-1"
        v-if="title">
        {{ title }}
      </h4>

      <div
        class="box-description text-muted text-center"
        v-if="description">
        {{ description }}
      </div>

      <div
        class="buttons mt-2"
        v-if="buttons.length >= 1">
        <component-button
          v-for="button in buttons"
          :classes="button.classes"
          type="button"
          :execute="{ callback: button.onclick, arguments: button }"
          :loading="button.loading">
          <i
            v-if="button.icon"
            :class="[button.icon]"></i>
          <span>{{ button.text }}</span>
        </component-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      box_style: {
        type: String,
        default: "small",
      },

      title: {
        type: String,
        default: null,
      },

      description: {
        type: String,
        default: null,
      },

      buttons: {
        type: Array,
        default: () => [],
      },

      classes: {
        type: Array,
        default: () => [],
      },

      labels: {
        type: Array,
        default: () => [],
      },

      show: {
        default: true,
      },

      loading: {
        default: false,
      },

      dark: {
        default: false,
      },

      clear: {
        default: false,
      },

      with_close_icon: {
        default: false,
      },

      with_icon: {
        default: false,
      },

      hidden_after: {
        default: null,
      },

      onclick: {
        default: () => {},
      },
    },

    data() {
      return {
        opened: true,
      };
    },

    mounted() {
      if (this.hidden_after) {
        setTimeout(() => {
          this.opened = false;
        }, this.hidden_after * 1000);
      }
    },

    methods: {
      close_box() {
        this.opened = false;
      },
    },
  };
</script>
