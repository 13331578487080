export default async function () {
  if (!this.user) {
    return;
  }

  // Error de internet
  if (!navigator.onLine) {
    this.$store.dispatch("modals/toggle", {
      id: "modal_relogin",
      force: "open",
      close_others_modal: true,
    });

    this.error_type = 1;
    return;
  }
}
