<template>
  <component-block_chart />
</template>
<script>
import block_chart from "../../components/index/components/block_chart.vue";

export default {
  components: {
    "component-block_chart": block_chart,
  },

  computed: {
    visibility() {
      return this.$windowWidth >= 768 ? "desktop" : "mobile";
    },
  },

  mounted() {
    this.$store.state.template.darkmode = false;
  },
};
</script>
