// Actions
import close from "./actions/close";
import open from "./actions/open";
import toggle from "./actions/toggle";
import add from "./actions/add";

// Mutations
import { SET_MODAL } from "./mutations/modals";

export default {
  namespaced: true,

  state: () => {
    return {
      modals: {},
    };
  },

  mutations: {
    SET_MODAL,
  },

  actions: {
    add,
    open,
    close,
    toggle,
  },
};
