<template>
  <div v-show="tab != 'diagnosis' && enabled === true && routes.length > 0" class="w-100">
    <div class="d-flex flex-column gap-1 w-100">
      <label>Continua</label>

      <div class="content-loading" v-show="loading" style="height: 100px">
        <span class="loader"></span>
      </div>

      <component-carousels-routes
        v-show="!loading"
        name="continue_inunit"
        key="continue_inunit"
        :from="get_routes"
        :slider="true"
        :autoplay="false"
        :pagination="false"
        :preview="true"
        :lockable_callbacks="open_modal_subscription"
      />
    </div>
  </div>

  <div v-show="tab == 'diagnosis' && enabled === true" class="w-100">
    <div class="d-flex flex-column gap-1 w-100">
      <label>Continua</label>
      <div
        class="d-flex w-100 gap-2 align-items-center justify-content-start box dark p-3"
      >
        <emoji
          @click="open_modal_appointment()"
          size="38px"
          :file="`clapping_hands.png`"
        />
        <p class="m-0 text-muted text-left description" @click="open_modal_appointment()">
          Ahora el <b>siguiente paso es continuar con tu sesión de orientación, </b>
          <b class="badge light small">haz clic</b> sobre este cuadro y vamos a encontrar
          una fecha y hora donde podamos hablar.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// Methods
import get_routes from "../methods/get_routes";

export default {
  computed: {
    route() {
      return this.$store.state.player.route;
    },

    unit() {
      return this.$store.state.player.unit;
    },

    units() {
      return this.$store.state.player.units;
    },

    tab() {
      return this.$route?.query?.tab;
    },
  },

  data() {
    return {
      enabled: false,
      routes: [],
      loading: true,
    };
  },

  mounted() {
    const unit_id = this.unit.id;
    const unit_index = this.units.findIndex((unit) => unit.id == unit_id);
    const unit_next = this.units[unit_index + 1];

    if (!unit_next) {
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  },

  methods: {
    get_routes,

    open_player_modal(next) {
      this.$store.dispatch("modals/toggle", {
        id: "modal_player",
        close_others_modal: true,
      });

      setTimeout(() => {
        next && next();
      }, 1000);
    },

    open_modal_subscription() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_subscription",
        close_others_modal: true,
      });
    },

    open_modal_appointment() {
      this.$store.dispatch("modals/toggle", {
        id: "modal_appointment",
        close_others_modal: false,
      });
    },
  },
};
</script>
