<template>
  <div class="paywall paywall_1 d-flex flex-column gap-2">
    <div
      class="block title d-flex flex-column gap-1 justify-content-center align-items-center"
    >
      <emoji size="48px" file="dizzy.png" />
      <h4 class="m-0 mt-2">Retoma el <span class="badge">control</span></h4>
      <p class="m-0 description">Con la asistencia de <b>psicólogos</b> certificados</p>
    </div>

    <component-benefits />
    <component-social_proof />

    <!-- Payment process -->
    <component-go_payment />
  </div>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import go_payment from "./components/gopayment.vue";
import benefits from "./components/benefits.vue";
import social_proof from "./components/social_proof.vue";

export default defineComponent({
  components: {
    "component-go_payment": go_payment,
    "component-benefits": benefits,
    "component-social_proof": social_proof,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription() {
      return this.$store?.state?.auth?.user?.subscription;
    },
  },
});
</script>
