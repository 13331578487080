<template>
  <div class="paywall paywall_3 d-flex flex-column gap-2">
    <div
      class="block title d-flex flex-column gap-1 justify-content-center align-items-center"
      v-if="title"
    >
      <emoji size="48px" :file="title?.emoji" />
      <h4 class="m-0 mt-2" v-if="title?.title" v-html="title?.title"></h4>
      <p
        class="m-0 description"
        v-if="title?.description"
        v-html="title?.description"
      ></p>
    </div>

    <!-- Key facts -->
    <template v-if="key_facts">
      <p class="m-0" v-if="key_facts.title">
        <b>{{ key_facts.title }}</b>
      </p>
      <component-key_facts :key_facts="key_facts.items" />
      <hr class="hr m-0" />
    </template>

    <!-- Problems -->
    <template v-if="problems">
      <p class="m-0" v-if="problems.title">
        <b>{{ problems.title }}</b>
      </p>
      <component-problems :problems="problems.items" />
      <hr class="hr m-0" />
    </template>

    <!-- Benefits -->
    <template v-if="benefits">
      <p class="m-0" v-if="benefits.title">
        <b>{{ benefits.title }}</b>
      </p>
      <component-benefits :benefits="benefits.items" />
      <hr class="hr m-0" />
    </template>

    <!-- Social proof -->
    <template v-if="social_proof">
      <p class="m-0" v-if="social_proof.title">
        <b>{{ social_proof.title }}</b>
      </p>
      <component-social_proof :social_proof="social_proof.items" />
      <hr class="hr m-0" />
    </template>

    <p class="text-dark m-0 text-center" v-if="footer" v-html="footer?.content"></p>

    <!-- Payment process -->
    <component-go_payment />
  </div>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import go_payment from "./components/gopayment.vue";
import problems from "./components/problems.vue";
import benefits from "./components/benefits.vue";
import social_proof from "./components/social_proof.vue";
import key_facts from "./components/key_facts.vue";

export default defineComponent({
  props: ["paywall_blocks"],

  components: {
    "component-go_payment": go_payment,
    "component-problems": problems,
    "component-benefits": benefits,
    "component-social_proof": social_proof,
    "component-key_facts": key_facts,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription() {
      return this.$store?.state?.auth?.user?.subscription;
    },

    //
    title() {
      return this.paywall_blocks?.title;
    },

    problems() {
      return this.paywall_blocks?.problems;
    },

    benefits() {
      return this.paywall_blocks?.benefits;
    },

    key_facts() {
      return this.paywall_blocks?.key_facts;
    },

    social_proof() {
      return this.paywall_blocks?.social_proof;
    },

    footer() {
      return this.paywall_blocks?.footer;
    },
  },

  data() {
    return {};
  },
});
</script>
