import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import store from "./helpers/store.js";
import login from "./helpers/login.js";

let options = {
  clientId: "io.ionic.memeonlyme",
  scopes: "email name",
};

function validate_email(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default async function () {
  const ls = localStorage;
  const anonymous_user_id = ls.getItem("anonymous_user_id");

  this.with_apple_loading = true;
  this.forms.register.inputs.email.value = "";
  this.forms.register.inputs.password.value = "";
  this.forms.register.inputs.name.value = "";

  if (!this.i_have_18) {
    this.alert.error(
      "Confirma que eres mayor de edad y que has leído y aceptado los términos y condiciones de uso."
    );
    this.with_apple_loading = false;
    return;
  }

  SignInWithApple.authorize(options)
    .then(async (res) => {
      const apple_response = res.response;

      if (!apple_response) {
        this.alert.error("Tenemos un problema con la autenticación.");
        this.with_apple_loading = false;
      }

      let name = apple_response.givenName;
      let email = apple_response.email;
      let temporal_id = localStorage.getItem("temporal_id");
      const identity_token = apple_response.user;

      // Pedimos el email
      email = prompt("¿Cuál es tu email?");

      if (validate_email(email) === false) {
        this.alert.error(
          "No hemos podido registrarte, necesitamos un correo valido."
        );

        this.with_apple_loading = false;
        return;
      }

      // Storing
      const response = await store({
        vm: this,
        form: this.forms.register,

        email,
        name,
        temporal_id,
        apple_identity_token: identity_token,

        anonymous_user_id,
        save_on_sendpulse: 1,
        autologin: 1,
      });

      this.with_apple_loading = false;

      if (response) {
        login({
          vm: this,
          user: response.user,
          authorization: response.authorization,
        });

        this.$router.push({
          name: "index",
          query: {
            tab: "index",
            anonymous_user: anonymous_user_id ? 1 : 0,
          },
        });

        this.ab({
          vm: this,
          rt: "register",
          fn: "onregister",
        });

        return;
      }

      this.alert.error(
        "No hemos podido registrarte, un error inesperado sucedió."
      );
      this.with_apple_loading = false;
    })
    .catch((error) => {
      console.error(error);
      this.sentry_track_error(error);
      this.alert.error(
        "Tenemos un problema para conseguir la autorización para iniciar sesión con Apple ID. "
      );
      this.with_apple_loading = false;
    });
}
