export default async function () {
  const common = new this.controllers.Common();
  const utm_device = localStorage.getItem("utm_device");
  const utm_version = localStorage.getItem("utm_version");

  if (utm_device == "web") {
    return;
  }

  if (utm_version == "development") {
    return;
  }

  try {
    const { status, response } = await common.version();
    const versions = response.version[utm_device];

    if (versions.some((x) => x.version == "-1")) {
      return;
    }

    if (!versions.some((x) => x.version == utm_version)) {
      this.$store.dispatch("modals/toggle", {
        id: "modal_update_app",
        force: "open",
      });
    }
  } catch (error) {}
}
