export default async function ({ callback = () => {} }) {
  const route = new this.controllers.Route();

  this.loading = true;
  this.notifications = [];

  let { status, response } = await route.recommended({
    page: 1,
    limit: 7,
    mode: "suggestions",
  });

  if (response.routes && response.routes.length > 0) {
    this.routes = response.routes;

    if (this.route) {
      this.routes = this.routes.filter((x) => x.id != this.route.id);
    }
  }

  setTimeout(() => {
    callback(response);
    this.loading = false;
  }, 1000);
}
