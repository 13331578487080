import set_push_notifications from "@/helpers/push_notifications";

export default async ({ vm, user, authorization }) => {
  const track = new vm.controllers.Track();

  // const testing = localStorage.getItem("testing");
  // localStorage.clear();
  // sessionStorage.clear();
  // testing && localStorage.setItem("testing", "1");

  vm.sentry_set_user(user);

  vm.$store.dispatch("auth/login", {
    user: user,
    authorization: authorization,
  });

  // Track
  vm.ga_event("sign_up", {});
  await track.add({
    name: `register`,
    category: ["events", "lifecycle", "user_registration"].join(","),
    labels: `acquisition`,
    update: true,
  });

  // Push
  set_push_notifications({ vm });
};
