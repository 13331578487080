<template>
  <div class="diagnosis no-route-assigned animate__animated animate__fadeIn">
    <div
      class="block d-flex flex-column justify-content-center align-items-center p-4">
      <h4 class="m-0 text-muted-light">Ups...</h4>

      <p class="m-0 mt-2 text-muted text-center">
        Parece que algo anda un poco mal en nuestro lado. Pero no te preocupes,
        estamos aquí para ayudarte.
        <b class="text-muted-light">¿Por qué no nos envías un mensajito?</b>
        ¡Con gusto te asistiremos!
      </p>

      <div class="d-flex mt-2">
        <component-button
          :classes="[
            'btn',
            'btn-block',
            'btn-small',
            'btn-black',
            'transparent',
          ]"
          @click="open_browser(`/contact`)">
          <i class="fi fi-rr-headset"></i>
          <span>Pide ayuda</span>
        </component-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>
