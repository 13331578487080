import moment from "moment-timezone";

export default async function () {
  this.loading_spaces = true;

  const appointment = new this.controllers.Appointment();
  const alert = this.alert;

  const { status, response } = await appointment.best_spaces_to_reserve({
    days_after: 31,
  });

  if (
    status !== "SUCCESS" ||
    !response.spaces ||
    response.spaces.length === 0
  ) {
    alert.error(
      "Tenemos un problema para encontrar los espacios disponibles, contáctanos."
    );
    this.loading_spaces = false;
    return;
  }

  const spaces_with_spaces = response.spaces.filter((s) => s.spaces.length > 0);

  if (spaces_with_spaces.length === 0) {
    alert.error("No hay espacios disponibles en el momento.");
    this.loading_spaces = false;
    return;
  }

  const server_timezone = response.timezone;
  const client_timezone = moment.tz.guess();
  let all_spaces = {};

  this.timezone = client_timezone;

  spaces_with_spaces.forEach((space) => {
    space.spaces.forEach((x) => {
      const serverMoment = moment.tz(x, "YYYY-MM-DD|hh:mm a", server_timezone);
      const localMoment = serverMoment.clone().tz(client_timezone);
      const day = localMoment.format("YYYY-MM-DD");

      if (!all_spaces[day]) {
        all_spaces[day] = [];
      }

      all_spaces[day].push({
        client_timezone,
        server_timezone,
        day,
        local: localMoment.format("YYYY-MM-DD|hh:mm a"),
        server: serverMoment.format("YYYY-MM-DD|hh:mm a"),
      });
    });
  });

  this.psychologist = response.psychologist || null;
  this.duration = response.duration || 30;
  this.spaces = all_spaces;
  this.pagination.month.current_page = 0;
  this.pagination.hour.current_page = 0;
  this.pagination.month.per_page = this.$windowWidth < 768 ? 3 : 3;
  this.pagination.hour.per_page = this.$windowWidth < 768 ? 3 : 3;
  this.selected_month = spaces_with_spaces[0]
    ? spaces_with_spaces[0].day
    : null;
  this.loading_spaces = false;
}
