export default async function () {
  // Evitamos procesar si no esta activa la ruta.
  // Es decir si cierra antes de terminar la unidad.
  if (
    !this.$store.state.player ||
    (this.$store.state.player && !this.$store.state.player.route)
  ) {
    return;
  }

  // Evitamos que cierre la unidad sin responder las preguntas
  const questions = this.$store.getters["player/unit_questions"];
  const questions_answered = this.$store.state.player.questions_answered;

  if (questions_answered.length < questions.length) {
    this.$store.dispatch("player/add_error", {
      message:
        "¡Vamos! Asegúrate de responder todas las preguntas antes de continuar. Recuerda, cada respuesta te acerca más a tu objetivo. ¡Tú puedes!",
      type: "error",
    });

    return;
  }

  const route_id = this.route ? this.route.id : null;
  const unit_id = this.unit ? this.unit.id : null;
  const route = new this.controllers.Route();
  const cache_token = `player-unit-${unit_id}`;
  const track = new this.controllers.Track();

  route.update_progress({
    progress: 100,
    route_id: route_id,
    route_unit_id: unit_id,
  });

  track.add({
    name: `route_flow`,
    category: ["events", "route", "finished"].join(","),
    labels: `acquisition`,
    update: true,
    route_id,
    unit_id,
  });

  //
  if (this.unit.follow_nps === 1) {
    this.$store.dispatch("modals/toggle", {
      id: "modal_getnps",
      dark: false,
      close_others_modal: false,
      force: "open",
      inject: {
        route: this.route,
        unit: this.unit,
      },
    });
  }

  localStorage.removeItem(cache_token);

  const subscription_status =
    this.$store.state.auth.user &&
    this.$store.state.auth.user.subscription &&
    this.$store.state.auth.user.subscription.status;

  const is_public = this.$store.state.player.route.is_public;

  // Compartir si es free
  if (!subscription_status && is_public) {
    let finished_units = localStorage.getItem("finished_units");

    if (finished_units) {
      finished_units = parseFloat(finished_units);
      finished_units += 1;
    } else {
      finished_units = 1;
    }

    localStorage.setItem("finished_units", finished_units);
  }

  if (!this.unit.is_conclusion) {
    this.$store.dispatch("player/update_player_loading", true);
    await this.get_route();
    await this.get_units({ next: true });
    this.$store.dispatch("player/update_player_loading", false);
  }
}
