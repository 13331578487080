let PUBLIC_URL = "https://memeonlyme.com";
let API = "https://memeonlyme.com";
let API_STAGING = "https://beta.memeonlyme.com";
let URL_ASSETS = "https://memeonlyme.com/assets";

if (localStorage.getItem("testing")) {
  PUBLIC_URL = API_STAGING;
}

export { API, URL_ASSETS, PUBLIC_URL, API_STAGING };
