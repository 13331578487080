<template>
  <component-loading v-if="loading" />

  <component-prepared
    v-if="screen_resolution(this) == 'desktop' && route"
    :route="route"
  />

  <component-prepared-mobile
    v-if="screen_resolution(this) == 'mobile' && route"
    :route="route"
  />

  <component-no-route-assigned v-if="!loading && !route" />
</template>

<script>
// Methods
import get_assigned_route from "./methods/get_assigned_route";

// Methods
import prepared from "./components/prepared.vue";
import prepared_mobile from "./components/prepared_mobile.vue";
import loading from "./components/loading.vue";
import no_route_assigned from "./components/no_route_assigned.vue";

export default {
  components: {
    "component-prepared": prepared,
    "component-prepared-mobile": prepared_mobile,
    "component-loading": loading,
    "component-no-route-assigned": no_route_assigned,
  },

  watch: {
    "$store.state.template.daemons.index_continue": {
      handler() {
        this.get_assigned_route();
      },

      deep: true,
    },
  },

  data() {
    return {
      loading: true,
      route: null,
    };
  },

  mounted() {
    this.get_assigned_route();
  },

  methods: {
    get_assigned_route,
  },
};
</script>
