import { v4 as uuidv4 } from "uuid";

export default function (state, notification) {
  if (notification.id) {
    const index = state.notifications.findIndex((n) => n.id == notification.id);

    if (index !== -1) {
      state.notifications[index] = notification;
      return;
    }
  }

  state.notifications.push({
    id: uuidv4(),
    ...notification,
  });
}
