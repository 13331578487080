<template>
  <component-desktop v-if="screen_resolution(this) == 'desktop'" />
  <component-mobile v-if="screen_resolution(this) == 'mobile'" />
</template>

<script>
// Libs
import { defineComponent } from "vue";

// Methods
import get_route from "./methods/get_route";
import get_units from "./methods/get_units";
import accessibility_check from "./methods/accessibility_check";

// Components
import component_desktop from "./_desktop.vue";
import component_mobile from "./_mobile.vue";

export default defineComponent({
  components: {
    "component-desktop": component_desktop,
    "component-mobile": component_mobile,
  },

  props: {
    settings: {
      default: {},
    },
  },

  computed: {
    unit() {
      return this.$store.state.player.unit;
    },

    units() {
      return this.$store.state.player.units;
    },

    route() {
      return this.$store.state.player.route;
    },

    subscription_status() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.status
      );
    },

    subscription_level() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.subscription &&
        this.$store.state.auth.user.subscription.level
      );
    },

    player() {
      return this.$store.state.player.player.instance;
    },
  },

  watch: {
    "$store.state.player.unit": {
      handler(new_unit, old_unit) {
        if (old_unit && new_unit && new_unit.id != old_unit.id) {
          this.$store.commit("player/SOFT_RESET");
        }
      },

      deep: true,
    },

    "$store.state.player.route": {
      handler() {
        this.accessibility_check();
      },

      deep: true,
    },
  },

  async mounted() {
    await this.inicializate();
  },

  unmounted() {
    this.destroy_player();
  },

  methods: {
    get_route,
    get_units,
    accessibility_check,
    async inicializate() {
      // Iniciamos los estados
      this.$store.commit("player/RESET");
      this.$store.dispatch("player/update_player_loading", true);
      this.$store.dispatch("player/update_player_component", this);
      this.$store.dispatch("player/update_settings", {
        settings: this.settings,
      });

      // Optenemos de nuevo las rutas y unidades
      setTimeout(async () => {
        await this.get_route();
        await this.get_units({ next: false });
      }, 1000);
    },

    async destroy_player() {
      this.player && this.player.stop();
      this.player && this.player.unload();
      this.$store.commit("player/RESET");
      const { route_id, unit_id, auto_play, ...remaining_query } = this.$route.query;

      this.$router.replace({
        path: this.$route.path,
        query: remaining_query,
      });
    },
  },
});
</script>
