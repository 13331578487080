import set_push_notifications from "@/helpers/push_notifications";
import restore from "../../../modals/subscription/methods/restore";

export default async function () {
  const user = new this.controllers.User();
  const track = new this.controllers.Track();

  this.forms.auth.error = null;
  this.forms.auth.loading = true;

  const { status, response } = await user.authenticate({
    email: this.forms.auth.inputs.email.value,
    password: this.forms.auth.inputs.password.value,
    auth_refreshtoken: this.$route.query?.token,
  });

  if (status == "SERVER_ERROR" || status == "ERROR") {
    if (response.errors) {
      Object.keys(response.errors).forEach((input) => {
        if (this.forms.auth.inputs[input]) {
          this.forms.auth.inputs[input].error =
            this.forms.auth.inputs[input] && response.errors[input][0];
        }
      });
    }

    if (response.message) {
      this.forms.auth.error = response.message;
    }

    this.forms.auth.loading = false;
    return;
  }

  this.forms.auth.loading = false;

  this.sentry_set_user(response.user);
  this.ga_event("login", {});

  this.$store.dispatch("auth/login", {
    user: response.user,
    authorization: response.authorization,
  });

  track.add({
    name: `login`,
    category: ["events", "new_login"].join(","),
  });

  // Push
  set_push_notifications({ vm: this });

  // Redirection
  const redirect_url = localStorage.getItem("redirect_after_login");

  if (redirect_url) {
    this.$router.push(redirect_url);
    localStorage.removeItem("redirect_after_login");
    return;
  } else {
    this.$router.push({
      name: "index",
      query: {
        tab: "index",
      },
    });
  }
}
