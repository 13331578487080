<template>
  <div class="step1 content gap-2 blocks">
    <div
      class="block-loader"
      v-if="loading">
      <div class="loader"></div>
    </div>

    <div
      class="block-wrapper d-flex flex-column gap-2"
      v-if="!loading">
      <div
        class="d-flex flex-column gap-0 w-100 justify-content-center align-items-center"
        style="padding: 0 1rem">
        <h3 class="title m-0 text-center m-0">Personaliza</h3>

        <p class="subtitle m-0 text-muted text-center m-0">
          <b>Afinaremos el plan</b> que vamos a construir para ti basado en tus
          respuestas.
        </p>
      </div>

      <div
        class="categories"
        v-if="unselected_categories.length > 0">
        <div
          v-for="category in unselected_categories"
          class="category"
          :class="category.selected && 'active'">
          <div
            class="left"
            @click="select_category(category)">
            <emoji
              size="28px"
              :file="category.emoji" />

            <div class="content">
              <div class="title">{{ category.name }}</div>
            </div>
          </div>

          <div
            class="right"
            @click="alert.info(category.description)">
            <i
              class="fi fi-rr-information"
              v-if="!category.selected"></i>
            <i
              class="fi fi-sr-check-circle"
              v-if="category.selected"></i>
          </div>
        </div>
      </div>

      <div class="d-flex w-100 px-3">
        <component-button
          :class="['w-100']"
          :classes="['btn', 'btn-block', 'btn-light']"
          :disabled="selected_categories.length === 0"
          :loading="loading_saving_categories"
          @click="go_next_step()"
          type="button">
          <i class="fi fi-rr-arrow-right"></i>
          <span>Continua</span>
        </component-button>
      </div>
    </div>
  </div>
</template>

<script>
  import save_symptom from "../methods/save_symptom";
  import get_symptoms_categories from "../methods/get_symptoms_categories";

  export default {
    props: ["id", "step", "change_step_to"],

    computed: {
      open_categories() {
        return (
          this.categories.length -
          this.categories.filter((x) => x.ignore === true).length
        );
      },

      unselected_categories() {
        return this.categories;
      },

      selected_categories() {
        return this.categories.filter((x) => x.selected === true);
      },
    },

    data() {
      return {
        loading: true,
        loading_saving_categories: false,
        categories: [],
      };
    },

    mounted() {
      this.get_symptoms_categories();

      // Tracking
      const track = new this.controllers.Track();

      track.add({
        name: `onboarding_step_1`,
        category: ["events", "lifecycle", "page_view"].join(","),
        labels: `acquisition`,
        update: true,
      });
    },

    methods: {
      get_symptoms_categories,
      save_symptom,
      select_category(category) {
        if (category.selected === undefined) {
          category.selected = true;
          return;
        }

        if (category.selected === true) {
          category.selected = false;
          return;
        }

        if (category.selected === false) {
          category.selected = true;
          return;
        }
      },

      reset() {
        this.categories.forEach((x) => {
          x.ignore = false;
          x.selected = false;
        });
      },

      go_next_step() {
        if (this.selected_categories.length === 0) {
          this.alert.info("Debes seleccionar al menos una categoría.");
          return;
        }

        this.loading_saving_categories = true;
        this.selected_categories.forEach((category) => {
          this.save_symptom({ category });
        });

        setTimeout(() => {
          this.change_step_to(2);
          this.loading_saving_categories = false;
        }, 3000);
      },
    },
  };
</script>
