// Actions
import player_init from "./actions/player_init";

// Mutations
import { SET_PLAYER } from "./mutations/players";

export default {
  namespaced: true,

  state: () => {
    return {
      players: {},
    };
  },

  mutations: {
    SET_PLAYER,
  },

  actions: {
    player_init,
  },
};
