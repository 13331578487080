import Request from "../helpers/request";

export default class {
  #request = new Request();

  async auth(body) {
    return await this.#request.post({
      path: "/api/auth/login",
      body,
    });
  }
  async logout(body) {
    return await this.#request.post({
      path: "/api/auth/logout",
      body,
    });
  }

  async store_symptoms(body) {
    return await this.#request.post({
      path: "/api/user/symptoms/store",
      body,
    });
  }

  async delete_account(body) {
    return await this.#request.post({
      path: "/api/user/delete",
      body,
    });
  }

  async get_symptoms(body) {
    return await this.#request.post({
      path: "/api/user/symptoms/get",
      body,
    });
  }

  async get_symptoms_categories(body) {
    return await this.#request.post({
      path: "/api/user/symptoms/get_by_categories",
      body,
    });
  }

  async get_symptoms_disclaimer(body) {
    return await this.#request.post({
      path: "/api/user/symptoms/disclaimer",
      body,
    });
  }

  async get_status_tasks(body) {
    return await this.#request.post({
      path: "/api/user/tasks/status",
      body,
    });
  }

  async get_by(body) {
    return await this.#request.post({
      path: "/api/users",
      body,
    });
  }

  async get_my_points(body) {
    return await this.#request.post({
      path: "/api/my/points",
      body,
    });
  }

  async get_my_points_progress(body) {
    return await this.#request.post({
      path: "/api/my/points/history",
      body,
    });
  }

  async get_phone_code(body) {
    return await this.#request.post({
      path: "/api/user/phone_code",
      body,
    });
  }

  async session(body) {
    return await this.#request.post({
      path: "/api/my/session",
      body,
    });
  }

  async store(body) {
    return await this.#request.post({
      path: "/api/user",
      body,
    });
  }

  async recover_password_get_link(body) {
    return await this.#request.post({
      path: "/api/user/recover_password_get_link",
      body,
    });
  }

  async recover_password_set_password(body) {
    return await this.#request.post({
      path: "/api/user/recover_password_set_password",
      body,
    });
  }

  async get_temporary_code(body) {
    return await this.#request.post({
      path: "/api/my/get_temporary_code",
      body,
    });
  }

  async check_temporary_code(body) {
    return await this.#request.post({
      path: "/api/my/check_temporary_code",
      body,
    });
  }

  async update_profile(body) {
    return await this.#request.post({
      path: "/api/user/profile/update",
      body,
    });
  }

  async update_utm_variables(body) {
    return await this.#request.post({
      path: "/api/user/profile/update_utm_variables",
      body,
    });
  }
}
