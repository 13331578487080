<template>
  <div class="paywall paywall_2 d-flex flex-column gap-2">
    <div
      class="block title d-flex flex-column gap-1 justify-content-center align-items-center"
    >
      <emoji size="48px" file="flag_in_hole.png" />
      <h4 class="m-0 mt-2">Inicia <span class="badge">tu plan</span></h4>
      <p class="m-0 description">Con la asistencia de <b>psicólogos</b> certificados</p>
    </div>

    <div class="block features v2" v-if="!subscription">
      <div class="feature">
        <emoji size="38px" file="direct_hit.png" />
        <div class="d-flex flex-column gap-0">
          <span class="title">Hoy</span>
          <span class="description">
            Conversa con nuestros psicólogos certificados,
            <b>cuéntanos tu historia e inmediatamente te darán unos pasos a seguir.</b>
          </span>
        </div>
      </div>

      <div class="feature">
        <emoji size="38px" file="crystal_ball.png" />
        <div class="d-flex flex-column gap-0">
          <span class="title">Luego</span>
          <span class="description">
            <b>Completa las rutas asignadas,</b> aplica los ejercicios, anota para hablar
            con tu psicólogo luego.
          </span>
        </div>
      </div>

      <div class="feature">
        <emoji size="38px" file="man_lifting_weights.png" />
        <div class="d-flex flex-column gap-0">
          <span class="title">Repite</span>
          <span class="description">
            <b>Consigue tus objetivos</b> y repite el proceso háblandonos y asi darte
            siguientes recomendaciones.
          </span>
        </div>
      </div>
    </div>

    <div class="block features v2" v-else>
      <div class="feature">
        <emoji size="38px" file="direct_hit.png" />
        <div class="d-flex flex-column gap-0">
          <span class="title">Hoy</span>
          <span class="description">
            Da el primer paso
            <b
              >iniciando tu ruta de diagnosis o terminando tu ruta asignada, aplica los
              consejos y continuemos.
            </b>
          </span>
        </div>
      </div>

      <div class="feature">
        <emoji size="38px" file="crystal_ball.png" />
        <div class="d-flex flex-column gap-0">
          <span class="title">Luego</span>
          <span class="description">
            <b>Completa la ruta,</b> Agenda una sesión 1-1 con tu psicólogo asignado para
            que puedan evaluar el progreso.
          </span>
        </div>
      </div>

      <div class="feature">
        <emoji size="38px" file="man_lifting_weights.png" />
        <div class="d-flex flex-column gap-0">
          <span class="title">Finalmente</span>
          <span class="description">
            <b>Habla con tu psicólogo el día de la sesión,</b> cuéntale tu progreso y
            el/ella te dará trucos, consejos, orientación y guía para la siguiente semana.
          </span>
        </div>
      </div>
    </div>

    <p class="text-dark text-center m-0">
      <b>Repite el proceso semanalmente</b> para ir avanzando constantemente hacia tus
      objetivos y así podrás desacerté de esa carga extra.
    </p>

    <!-- Payment process -->
    <component-go_payment />
  </div>
</template>

<script>
// Others elements
import { defineComponent } from "vue";
import go_payment from "./components/gopayment.vue";
import social_proof from "./components/social_proof.vue";

export default defineComponent({
  components: {
    "component-go_payment": go_payment,
    "component-social_proof": social_proof,
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    subscription() {
      return this.$store?.state?.auth?.user?.subscription;
    },
  },
});
</script>
