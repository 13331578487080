<template>
  <swiper
    class="swiper-container"
    :modules="[Navigation, Autoplay]"
    @swiper="onSwiper"
    :autoplay="{
      disableOnInteraction: true,
      stopOnLastSlide: true,
    }"
    :slidesPerView="1"
    :spaceBetween="10"
  >
    <swiper-slide class="slide" v-for="x in items">
      <div class="content d-flex flex-column gap-2 items">
        <div class="d-flex w-100 gap-2 align-items-start justify-content-start item">
          <emoji size="28px" :file="`${x.emoji}.png`" />

          <p
            class="m-0 text-muted text-left description"
            v-html="x.description"
            @click="alert.info(x.description.replace(/<[^>]*>/g, ''))"
          ></p>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
//
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper/modules";

export default {
  props: ["social_proof"],

  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    return {
      Pagination,
      Navigation,
      Autoplay,
      EffectFade,
    };
  },

  computed: {
    items() {
      return this.social_proof && this.social_proof.length > 0
        ? this.social_proof
        : this.default;
    },
  },

  data() {
    return {
      default: [
        {
          emoji: "astonished_face",
          description:
            "<b>Más del 90% de nuestros</b> usuarios con acompañamiento completo logran una recuperación completa en tan solo 6 meses.",
        },
        {
          emoji: "face_with_hand_over_mouth",
          description:
            "Al ser premium, <b>tendrás acceso a solicitar rutas personalizadas </b> especialmente para afrontar tus problemas.",
        },
        {
          emoji: "thumbs_up",
          description:
            "Tenemos muchas <b>reseñas positivas</b> que reflejan la satisfacción de nuestros usuarios al usar nuestra aplicacion.",
        },
        {
          emoji: "star_struck",
          description:
            "Buscamos destacar por <b>la mejor app del año</b> por su eficacia y facilidad de uso en varios medios especializados.",
        },
        {
          emoji: "flexed_biceps",
          description:
            "Un 95% de nuestros usuarios <b>recomiendan nuestra app a amigos y familiares</b> para mejorar su bienestar.",
        },
        {
          emoji: "hand_with_fingers_splayed",
          description:
            "Puedes formar parte de una comunidad de <b>usuarios activos</b> que comparten tus mismos objetivos, superar la ansiedad.",
        },
        {
          emoji: "clapping_hands",
          description:
            "<b>Colaboraras con expertos reconocidos</b> en el ámbito de la salud mental y el bienestar personal, acompañandote en cada paso.",
        },
        {
          emoji: "sparkles",
          description:
            "Nuestros usuarios reportan una <b>mejora significativa en su calidad de vida</b> tras usar la app regularmente, nos cuentas como te va a ti.",
        },
        {
          emoji: "fire",
          description:
            "Innovamos constantemente con <b>actualizaciones semanales</b> con nuevas características basadas en feedback de usuarios.",
        },
      ],
    };
  },

  methods: {
    onSwiper(x) {
      this.swiper = x;
    },
  },
};
</script>
