<template>
  <template v-if="opened">
    <div
      class="chat-floating-notification animate__animated animate__slideInRight"
      :class="[darkmode && 'dark']"
      @click="open_modal()"
    >
      <div class="content">
        <i class="fi fi-ss-headset icon-label"></i>
        <span class="label">Chat</span>

        <div class="icon-close" v-if="notifications > 0">
          {{ notifications }}
        </div>
      </div>
    </div>
  </template>
</template>
<script>
// Methods
import get_pending_messages_to_read from "../../methods/get_pending_messages_to_read";

export default {
  computed: {
    darkmode() {
      return this.$store.state.template.darkmode;
    },
  },

  watch: {
    opened() {
      if (!this.opened) {
        this.interval = setInterval(() => {
          this.get_pending_messages_to_read();
        }, 1000 * 60);
      }

      if (this.opened) {
        this.interval && clearInterval(this.interval);
      }
    },
  },

  data() {
    return {
      notifications: 0,
      opened: false,
      interval: null,
    };
  },

  mounted() {
    setTimeout(() => {
      this.opened = true;
      this.get_pending_messages_to_read();
    }, 1000 * 5);
  },

  methods: {
    get_pending_messages_to_read,

    open_modal() {
      this.opened = false;

      this.$router.replace({
        query: {
          route_id: "9629e287-5ae6-4daf-b27a-000000000000",
        },
      });

      this.$store.dispatch("modals/toggle", {
        id: "modal_tickets",
        close_others_modal: false,
      });

      setTimeout(() => {
        this.opened = true;
      }, 1000 * 60 * 30);
    },
  },
};
</script>
