export default async function ({ page = 1, limit = 3, callback = null }) {
  const alert = this.alert;
  const route = new this.controllers.Route();

  this.loading = true;
  this.notifications = [];

  if (this.screen_resolution(this) == "mobile") {
    limit = 2;
  }

  const { status, response } = await route.recommended({
    page,
    limit,
    mode: "suggestions",
  });

  if (response.notifications && response.notifications.length > 0) {
    this.notifications = response.notifications;
  }

  if (response.routes && response.routes.length > 0) {
    this.routes = response.routes;
  }

  setTimeout(() => {
    callback && callback(response);
    this.loading = false;
  }, 1000);
}
