import Request from "../helpers/request";

export default class {
  #request = new Request();

  async version(body) {
    return await this.#request.post({
      path: "/api/version",
      body,
    });
  }

  async get_settings(body) {
    return await this.#request.post({
      path: "/api/version/settings",
      body,
    });
  }
}
