export default function ({ commit }, { id, media, format, vm }) {
  const player = new Howl({
    src: [media],
    format: [format],
    autoplay: false,
    html5: true,
    buffer: true,
    preload: true,

    xhr: {
      withCredentials: false,
    },

    onload: () => {
      vm.is_player_ready = true;
    },

    onplay: () => {
      vm.is_playing = true;
    },

    onpause: () => {
      vm.is_playing = false;
    },

    onstop: () => {
      vm.is_playing = false;
    },

    onend: () => {
      vm.is_playing = false;
    },
  });

  commit("SET_PLAYER", {
    id,
    player,
  });
}
