import { API } from "../configs/domains";
import Repository from "../repositories/route";

export default class {
  #repository = new Repository();

  async my_route_assigned() {
    const { status, response } = await this.#repository.my_route_assigned();

    if (response.route) {
      response.route = this.serialize({
        by: "route",
        input: response.route,
      });
    }

    return { status, response };
  }

  async recommended(body) {
    const { status, response } = await this.#repository.recommended(body);

    if (response.routes && typeof response.routes == "object") {
      response.routes = Object.values(response.routes);
    }

    if (response.routes && response.routes.length > 0) {
      response.routes.forEach((route, index) => {
        response.routes[index] = this.serialize({
          by: "route",
          input: route,
        });
      });
    }

    return { status, response };
  }

  async search_by(body) {
    const { status, response } = await this.#repository.search_by(body);

    if (response.routes && response.routes.length > 0) {
      response.routes.forEach((route, index) => {
        response.routes[index] = this.serialize({
          by: "route",
          input: route,
        });
      });
    }

    return { status, response };
  }

  async send_question(body) {
    return await this.#repository.send_question(body);
  }

  async get_num_finished_routes(body) {
    return await this.#repository.get_num_finished_routes(body);
  }

  async get_by_id({ id }) {
    const { status, response } = await this.#repository.get_by_id({ id });

    if (response.route) {
      response.route = this.serialize({
        by: "route",
        input: response.route,
      });
    }

    return { status, response };
  }

  async get_by_slug({ slug }) {
    const { status, response } = await this.#repository.get_by_slug({ slug });

    if (response.route) {
      response.route = this.serialize({
        by: "route",
        input: response.route,
      });
    }

    return { status, response };
  }

  async get_public_routes(body) {
    const { status, response } = await this.#repository.get_public_routes(body);

    if (response.routes && response.routes.length > 0) {
      response.routes.forEach((route, index) => {
        response.routes[index] = this.serialize({
          by: "route",
          input: route,
        });
      });
    }

    return { status, response };
  }

  async get_units({ route_id }) {
    let { status, response } = await this.#repository.units_by_route({
      route_id,
    });

    let units = response.units;

    if (units) {
      units.forEach((unit, index) => {
        units[index] = this.serialize({
          input: units[index],
          by: "unit",
        });
      });

      response[units] = units;
    }

    return { status, response };
  }

  async get_unit_by_id({ id }) {
    const { status, response } = await this.#repository.get_unit({ id });

    if (response.unit) {
      response.unit = this.serialize({
        input: response.unit,
        by: "unit",
      });
    }

    return { status, response };
  }

  async get_categories(body) {
    const { status, response } = await this.#repository.get_categories(body);

    if (response.categories) {
      response.categories.forEach((category, index) => {
        response.categories[index] = this.serialize({
          input: category,
          by: "category",
        });
      });
    }

    return { status, response };
  }

  async update_progress({ route_id, route_unit_id, progress }) {
    const { status, response } = await this.#repository.update_progress({
      route_id,
      route_unit_id,
      progress,
    });

    return { status, response };
  }

  serialize({ by, input = {} }) {
    switch (by) {
      case "unit":
        let triggers = [];

        if (input.image) {
          input.image = `${API}${input.image}`;
        }

        if (input.media) {
          input.media = `${API}${input.media}`;
        }

        if (input.recorded_by_user) {
          input.recorded_by_user.avatar = `${API}${input.recorded_by_user.avatar}`;
        }

        if (input.goals) {
          input.goals = JSON.parse(input.goals);
        }

        if (input.symptoms) {
          input.symptoms = JSON.parse(input.symptoms);
        }

        if (input.triggers && input.triggers.length > 0) {
          triggers = input.triggers[0];
          triggers = JSON.parse(triggers.setting);
          let questions = triggers.questions;

          if (questions && questions.length > 0) {
            questions.forEach((question, index) => {
              if (
                question.trigger_settings &&
                question.trigger_settings.show_on_time
              ) {
                let time = question.trigger_settings.show_on_time;
                time = time.split(":");
                time = parseInt(time[0]) * 60 + parseFloat(time[0]);
                triggers.questions[index].trigger_settings.show_on_time = time;
              }
            });
          }
        }

        input.triggers = triggers;
        break;

      case "route":
        if (input.image) {
          input.image = `${API}${input.image}`;
        }

        if (input.media) {
          input.media = `${API}${input.media}`;
        }

        if (input.preview && input.preview.media) {
          input.preview.media = `${API}${input.preview.media}`;
        }

        if (input.preview && input.preview.image) {
          input.preview.image = `${API}${input.preview.image}`;
        }

        break;

      case "category":
        if (input.image) {
          input.image = `${API}${input.image}`;
        }

        break;
    }

    return input;
  }
}
