<template>
  <component-modal
    ref="modal"
    id="modal_stepsrequired"
    :dark="true"
    :clean="false"
    :permanent_mode="false"
    :with_close_button="true"
    :classes_modal="['center', 'p-2']"
    :classes_wrapper="['p-4']">
    <div class="content-modal">
      <div class="container p-0">
        <div class="row">
          <div class="col-12">
            <div class="block-title text-center d-flex flex-column gap-2">
              <h4 class="m-0">
                Hey antes de ir con esta ruta debes saber que...
              </h4>
              <p class="description m-0 mt-1 text-muted">
                Nos complace que estés dando este paso. Para acceder a tu ruta
                diagnóstica,
                <b
                  >por favor visita la sección "explora", completa 1 ruta y
                  asegúrate de rellenar los síntomas.</b
                >
                Estas acciones nos permiten entender mejor tus necesidades y
                personalizar tu experiencia. Una vez hecho, tu ruta diagnóstica
                se desbloqueará automáticamente.
              </p>
            </div>

            <div class="block-network mt-3 d-flex gap-3">
              <component-button
                :class="['d-flex', 'w-100']"
                :classes="['btn', 'btn-block', 'btn-secondary']"
                type="button"
                @click="
                  () => {
                    this.$router.push({
                      query: {
                        ...this.$route.query,
                        ...{ tab: 'suggestions' },
                      },
                    });

                    this.$refs.modal.close();
                  }
                "
                :loading="false">
                <i class="fi fi-rr-paper-plane"></i>
                <span>Da inicio a tu aventura</span>
              </component-button>
            </div>

            <div class="block-footer mt-3">
              <p class="description m-0 mt-1 text-muted">
                Recuerda, estamos aquí para brindarte apoyo. No dudes en
                contactarnos de la manera que te resulte más cómoda. ¡Esperamos
                con ansias ayudarte!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component-modal>
</template>

<script>
  // Others elements
  import { defineComponent } from "vue";
  export default defineComponent({});
</script>
