<template>
  <ion-content class="page__register">
    <div
      :class="[
        'wrapper-container',
        'wrapper',
        'wrapper-spacing',
        'light-pattern',
        'd-flex',
        'flex-column',
        'align-items-center',

        'p-4',

        'justify-content-end',
        'justify-content-sm-center',
      ]">
      <div class="section-form d-flex flex-column">
        <!-- Mobile -->
        <component-title v-if="$windowWidth <= 768" />

        <div class="box d-flex flex-column p-3">
          <component-title v-if="$windowWidth > 768" />

          <div
            class="d-flex flex-column gap-2"
            v-if="!mode">
            <component-button
              :classes="['btn', 'btn-block', 'w-100', 'btn-light', 'btn-left']"
              type="button"
              @click="this.mode = 'email'">
              <i class="fi fi-rr-at"></i>
              <span>Registrate con correo</span>
            </component-button>

            <hr class="hr m-0" />

            <component-button
              type="button"
              :loading="with_google_loading || loading"
              :disabled="with_google_loading || loading"
              @click="with_google()"
              :classes="[
                'btn',
                'btn-block',
                'w-100',
                'btn-left',
                'btn-google',
              ]">
              <i class="lh-1 fi fi-brands-google"></i>
              <span>Registrate con Google</span>
            </component-button>

            <component-button
              type="button"
              v-if="current_device() == 'ios'"
              :loading="with_apple_loading"
              :disabled="with_apple_loading"
              @click="with_apple()"
              :classes="[
                'btn',
                'btn-block',
                'w-100',
                'btn-primary',
                'btn-left',
              ]">
              <i class="lh-1 fi fi-brands-apple"></i>
              <span>Registrate con Apple</span>
            </component-button>

            <div class="d-flex gap-2">
              <div class="checkbox-wrapper-2">
                <input
                  type="checkbox"
                  class="sc-gJwTLC ikxBAC"
                  v-model="i_have_18" />
              </div>

              <span class="text-muted">
                Tengo la mayoría de edad, y entiendo los
                <span
                  class="text-bold"
                  @click="open_browser(`/terms`)"
                  >términos y condiciones</span
                >
                de uso de memeonlyme.
              </span>
            </div>
          </div>

          <div
            class="d-flex flex-column"
            v-if="mode == 'email'">
            <form class="d-flex flex-column section-inputs">
              <div class="row">
                <div class="col-md-4">
                  <component-input
                    type="text"
                    label="¿Tu nombre?"
                    placeholder="Nombre"
                    class="w-100 mt-2 mt-sm-0"
                    :default="forms.register.inputs.name.value"
                    :error="forms.register.inputs.name.error"
                    :loading="forms.register.loading"
                    :onupdate="(x) => (forms.register.inputs.name.value = x)" />
                </div>

                <div class="col-md-4">
                  <component-input
                    type="text"
                    label="¿Correo?"
                    placeholder="Correo electronico"
                    class="w-100 mt-2 mt-sm-0"
                    :default="forms.register.inputs.email.value"
                    :error="forms.register.inputs.email.error"
                    :loading="forms.register.loading"
                    :onupdate="
                      (x) => (forms.register.inputs.email.value = x)
                    " />
                </div>

                <div class="col-md-4">
                  <component-input
                    type="password"
                    label="¿Clave?"
                    placeholder="Clave"
                    class="w-100 mt-2 mt-sm-0"
                    :default="forms.register.inputs.password.value"
                    :error="forms.register.inputs.password.error"
                    :loading="forms.register.loading"
                    :onupdate="
                      (x) => (forms.register.inputs.password.value = x)
                    " />
                </div>
              </div>
            </form>

            <div class="row mt-2">
              <div class="col-12">
                <div class="d-flex gap-2">
                  <div class="checkbox-wrapper-2">
                    <input
                      type="checkbox"
                      class="sc-gJwTLC ikxBAC"
                      v-model="i_have_18" />
                  </div>

                  <span class="text-muted">
                    Tengo la mayoría de edad, y entiendo los
                    <span
                      class="text-bold"
                      @click="open_browser(`/terms`)"
                      >términos y condiciones</span
                    >
                    de uso de memeonlyme.
                  </span>
                </div>
              </div>
            </div>

            <div
              class="row mt-2"
              v-if="forms.register.error">
              <div class="col-12">
                <div class="alert primary m-0">{{ forms.register.error }}</div>
              </div>
            </div>

            <div class="d-flex justify-content-end section-buttons mt-2">
              <div class="row">
                <div class="col-12 d-flex gap-2">
                  <component-button
                    type="button"
                    :loading="with_google_loading || loading"
                    :disabled="with_google_loading || loading"
                    @click="with_google()"
                    :classes="[
                      'btn',
                      'btn-block',
                      'btn-transparent',
                      'text-dark',
                    ]">
                    <i class="lh-1 fi fi-brands-google"></i>
                  </component-button>

                  <component-button
                    type="button"
                    v-if="current_device() == 'ios'"
                    :loading="with_apple_loading"
                    :disabled="with_apple_loading"
                    @click="with_apple()"
                    :classes="[
                      'btn',
                      'btn-block',
                      'btn-transparent',
                      'text-dark',
                    ]">
                    <i class="lh-1 fi fi-brands-apple"></i>
                  </component-button>

                  <component-button
                    :classes="['btn', 'btn-block', 'btn-primary']"
                    type="button"
                    :execute="{ callback: submit, arguments: {} }"
                    :disabled="forms.register.loading"
                    :loading="forms.register.loading">
                    <i class="fi fi-rr-arrow-right"></i>
                    <span>Registrate</span>
                  </component-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 d-flex justify-content-end">
          <component-button
            :classes="['btn', 'btn-nopadding', 'btn-transparent', 'btn-left']"
            type="button"
            :execute="{
              callback: go,
              arguments: { name: 'login' },
            }">
            <span>¿Ya tienes cuenta?</span>
          </component-button>
        </div>

        <div class="section-footer mt-2 mt-sm-4">
          <component-footer
            address="true"
            :links="false"
            :terms="true" />
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
  // Others elements
  import { defineComponent } from "vue";

  // Components
  import pageTitleComponent from "./components/page-title.vue";

  // Methods
  import build from "./methods/build";
  import submit from "./methods/submit";
  import with_google from "./methods/with_google";
  import with_apple from "./methods/with_apple";
  import go from "./methods/go";

  export default defineComponent({
    components: {
      "component-title": pageTitleComponent,
    },

    data() {
      return {
        mode: null,
        loading: true,
        i_have_18: false,
        with_google_loading: false,
        with_apple_loading: false,

        forms: {
          register: {
            loading: false,
            error: null,

            inputs: {
              name: {
                value: "",
                error: null,
              },

              email: {
                value: "",
                error: null,
              },

              password: {
                value: "",
                error: null,
              },
            },
          },
        },
      };
    },

    mounted() {
      this.build();
    },

    methods: {
      go,
      submit,
      build,
      with_google,
      with_apple,
    },
  });
</script>
