export default function ({ state }, { id, dark, callbacks = {} }) {
  if (dark) {
    state.modals[id]?.set_dark_mode(true);
  }

  if (callbacks) {
    state.modals[id]?.set_callbacks(callbacks);
  }

  state.modals[id]?.close();
}
