import { v4 as uuidv4 } from "uuid";

export default () => [
  {
    type: "lang",
    regex: /route:([0-9a-fA-F\-]+)/g,
    replace: function (match, id) {
      const key = `${uuidv4()}:${id}`;

      window.dispatchEvent(
        new CustomEvent("RoutePreview", {
          detail: {
            id: key,
          },
        })
      );

      return `<div class="route-preview box dark d-inline-flex p-2 gap-2 border-radius-2 text-light w-fit" data-route-id="${key}"><div class="loader-simple dark"></div></div>`;
    },
  },
];
