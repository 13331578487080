export default async function () {
  const ticket = new this.controllers.Ticket();
  const { status, response } = await ticket.pending_to_be_read();

  if (!status === "SUCCESS") {
    return;
  }

  if (response.pending_to_be_read > 0) {
    this.opened = true;
    this.notifications = response.pending_to_be_read;
  }
}
