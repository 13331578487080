export default async function () {
  const user = new this.controllers.User();
  const alert = this.alert;

  this.forms.profile.error = null;
  this.forms.profile.loading = true;

  const { status, response } = await user.update_profile({
    name: this.forms.profile.inputs.name.value,
    email: this.forms.profile.inputs.email.value,
    password: this.forms.profile.inputs.password.value,
    whatsapp: this.forms.profile.inputs.whatsapp.value,
  });

  Object.keys(this.forms.profile.inputs).forEach((key) => {
    this.forms.profile.inputs[key].error = null;
  });

  if (status == "SERVER_ERROR" || status == "ERROR") {
    if (response.errors) {
      Object.keys(response.errors).forEach((input) => {
        if (this.forms.profile.inputs[input]) {
          this.forms.profile.inputs[input].error =
            this.forms.profile.inputs[input] && response.errors[input][0];
        }
      });
    }

    this.forms.profile.loading = false;
    return;
  }

  if (response.user) {
    this.$store.dispatch("auth/login", {
      user: response.user,
    });
  }

  alert.success(
    "¡Genial, tus datos están al día, gracias por tomarte el tiempo!"
  );

  setTimeout(() => {
    this.forms.profile.loading = false;
  }, 1500);
}
