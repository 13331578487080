import Request from "../helpers/request";

export default class {
  #request = new Request();

  async add(body) {
    const x = {};

    Object.keys(body).forEach((key) => {
      if (typeof body[key] == "boolean") {
        x[key] = body[key];
      }

      if (typeof body[key] == "string" && body[key].length > 0) {
        x[key] = body[key];
      }
    });

    return await this.#request.post({
      path: "/api/track",
      body: x,
    });
  }
}
