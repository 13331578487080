import Request from "../helpers/request";

export default class {
  #request = new Request();

  async my_route_assigned() {
    return await this.#request.post({
      path: "/api/routes/assigned",
    });
  }

  async recommended(body) {
    return await this.#request.post({
      path: "/api/routes/recommended",
      body,
    });
  }

  async get_num_finished_routes(body) {
    return await this.#request.post({
      path: "/api/routes/get_num_finished_routes",
      body,
    });
  }

  async search_by(body) {
    return await this.#request.post({
      path: "/api/routes/search",
      body,
    });
  }

  async units_by_route(body) {
    return await this.#request.post({
      path: "/api/route/get_units",
      body,
    });
  }

  async get_by_id(body) {
    return await this.#request.post({
      path: "/api/route/get_by_id",
      body,
    });
  }

  async get_by_slug(body) {
    return await this.#request.post({
      path: "/api/route/get_by_slug",
      body,
    });
  }

  async get_unit(body) {
    return await this.#request.post({
      path: "/api/route/get_unit",
      body,
    });
  }

  async update_progress(body) {
    return await this.#request.post({
      path: "/api/route/update_progress",
      body,
    });
  }

  async get_categories(body) {
    return await this.#request.post({
      path: "/api/routes/categories",
      body,
    });
  }

  async get_public_routes(body) {
    return await this.#request.post({
      path: "/api/routes/public",
      body,
    });
  }

  async send_question(body) {
    return await this.#request.post({
      path: "/api/route/unit/send_question",
      body,
    });
  }
}
