import { createRouter, createWebHistory } from "@ionic/vue-router";
import middleware_bootstrap from "../middlewares/index";

import view_login from "@/views/pages/login/index.vue";
import view_recover from "@/views/pages/login/recover/index.vue";
import view_register from "@/views/pages/register/v1/index.vue";
import view_index from "@/views/pages/index/index.vue";
import view_onboarding from "@/views/pages/onboarding/index.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { 
      title: 'Inicio' 
    },
    redirect: () => {
      const first_open = localStorage.getItem("first_open") ? false : true;

      if (first_open) {
        localStorage.setItem("first_open", "0");

        return {
          name: "onboarding",
        };
      }

      return {
        name: "login",
      };
    },
  },

  {
    path: "/onboarding",
    name: "onboarding",
    component: view_onboarding,
    meta: {
      title: "Onboarding",
      login_required: false,
    },
  },

  {
    path: "/login",
    name: "login",
    component: view_login,
    meta: {
      title: "Login",
      login_required: false,
    },
  },

  {
    path: "/index",
    name: "index",
    component: view_index,
    meta: {
      title: "Index",
      login_required: true,
    },
  },

  {
    path: "/recover",
    name: "recover",
    component: view_recover,
    meta: {
      title: "Recuperar clave",
      login_required: false,
    },
  },

  {
    path: "/register",
    name: "register",
    component: view_register,
    meta: {
      title: "Registro",
      login_required: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(middleware_bootstrap);

export default router;
