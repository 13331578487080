import Repository from "../repositories/appointment";

export default class {
  #repository = new Repository();

  async store(body) {
    return await this.#repository.store(body);
  }

  async best_spaces_to_reserve(body) {
    return await this.#repository.best_spaces_to_reserve(body);
  }

  async get_notifications(body) {
    return await this.#repository.get_notifications(body);
  }

  async confirm(body) {
    return await this.#repository.confirm(body);
  }

  async cancel(body) {
    return await this.#repository.cancel(body);
  }
}
