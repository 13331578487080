import { PushNotifications } from "@capacitor/push-notifications";

export default async ({ vm }) => {
  if (vm.current_device() == "web") {
    return;
  }

  const permission = await PushNotifications.requestPermissions();
  const user = new vm.controllers.User();

  if (permission.receive === "granted") {
    await PushNotifications.register();

    PushNotifications.addListener("registration", async (token) => {
      const token_id = token.value;

      user.update_profile({
        push_id: token_id,
      });
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification) => {
        const body = notification?.notification?.body;

        if (body) {
          vm.alert.info(body);
        }
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        const data = notification.notification.data;

        if (data.path) {
          vm.$router.push(data.path);
        }

        if (data.modal) {
          vm.$store.dispatch("modals/toggle", {
            id: data.modal,
            force: "open",
            close_others_modal: true,
          });
        }
      }
    );
  }
};
