export default function () {
  if (this.spaces.length === 0) {
    return [];
  }

  if (!this.selected_month) {
    return [];
  }

  let hours = this.spaces[this.selected_month];

  const offset =
    this.pagination.hour.current_page * this.pagination.hour.per_page;
  const end = offset + this.pagination.hour.per_page;

  return hours.slice(offset, end);
}
