import { App } from "@capacitor/app";

export default function addUrlListener() {
  const vm = this;

  App.addListener("appUrlOpen", (data) => {
    const url = new URL(data.url);

    const utm_params = [
      "utm_campaign",
      "utm_version",
      "utm_segmentation",
      "utm_testing",
      "utm_medium",
      "utm_content",
      "utm_term",
      "utm_device",
      "utm_source",
      "utm_referred",
    ];

    utm_params.reduce((acc, param) => {
      const value = url.searchParams.get(param);
      if (value) {
        localStorage.setItem(param, value);
        acc[param] = value;
      }
      return acc;
    }, {});

    if (!vm.$store.state.auth.user) {
      localStorage.setItem("redirect_after_login", url.pathname + url.search);
      vm.$router.push({ name: "login" });
    } else {
      const query_params = Array.from(url.searchParams.entries()).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {}
      );

      vm.$router.push({
        path: url.pathname,
        query: query_params,
      });
    }
  });
}
