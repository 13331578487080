import check_if_the_unit_is_valid from "../helpers/check_if_the_unit_is_valid";

export default function ({ commit, state }, unit) {
  const units = state.units;

  const error = check_if_the_unit_is_valid({
    units,
    unit,
  });

  if (error) {
    commit("ADD_ERRORS", {
      message: error,
      type: "error",
    });

    return;
  }

  commit("SET_UNIT", {
    action: "update",
    unit,
  });
}
