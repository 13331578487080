<template>
  <div
    class="fullalert p-3"
    v-show="show">
    <div class="container w-100 p-0">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-11 col-sm-6 text-center">
          <h4 class="title">
            ¡Hola!
            <emoji file="clapping_hands.png" />
          </h4>
          <p class="description text-muted">
            Parece que eres nuevo por aquí! Estás a punto de descubrir
            contenidos diseñados para ayudarte a triunfar en la plataforma. Solo
            sigue los tips y
            <b class="text-light"
              >responde las preguntas mientras avanzas para que tengas los
              mejores resultados.</b
            >
          </p>
          <div class="buttons d-flex gap-2">
            <button
              class="btn btn-block w-100 btn-light"
              @click="this.show = false">
              <i class="fi fi-rr-arrow-right"></i>
              <span>Continuar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},

    computed: {
      user() {
        return this.$store.state.auth.user;
      },
    },

    data() {
      return {
        show: true,
      };
    },

    mounted() {
      const player_was_opened = localStorage.getItem("player_was_opened");

      if (player_was_opened) {
        this.show = false;
      }

      localStorage.setItem("player_was_opened", true);
    },
  };
</script>
