import { API } from "../configs/domains";
import Repository from "../repositories/user";

export default class {
  #repository = new Repository();

  async authenticate(body) {
    const { status, response } = await this.#repository.auth(body);

    if (response.user) {
      response.user = this.serialize({
        by: "user",
        input: response.user,
      });
    }

    return { status, response };
  }

  async logout(body = {}) {
    return await this.#repository.logout(body);
  }

  async get_by({
    page,
    notifications,
    limit = 10,
    search_by = "",
    order_by = "created_at,desc",
  }) {
    return await this.#repository.get_by({
      page,
      notifications,
      limit,
      search_by: JSON.stringify(search_by),
      order_by,
    });
  }

  async get_my_points(body) {
    return await this.#repository.get_my_points(body);
  }

  async get_phone_code(body) {
    return await this.#repository.get_phone_code(body);
  }

  async get_my_points_progress(body) {
    return await this.#repository.get_my_points_progress(body);
  }

  async store(body) {
    const { status, response } = await this.#repository.store(body);

    if (response.user) {
      response.user = this.serialize({
        by: "user",
        input: response.user,
      });
    }

    return { status, response };
  }

  async get_session(body) {
    const { status, response } = await this.#repository.session(body);

    if (response.user) {
      response.user = this.serialize({
        by: "user",
        input: response.user,
      });
    }

    return { status, response };
  }

  async store_symptoms(body) {
    return await this.#repository.store_symptoms(body);
  }

  async get_symptoms(body) {
    return await this.#repository.get_symptoms(body);
  }

  async get_symptoms_categories(body) {
    return await this.#repository.get_symptoms_categories(body);
  }
  async get_symptoms_disclaimer(body) {
    return await this.#repository.get_symptoms_disclaimer(body);
  }
  async get_status_tasks(body) {
    return await this.#repository.get_status_tasks(body);
  }

  async recover_password_get_link(body) {
    return await this.#repository.recover_password_get_link(body);
  }

  async delete_account(body) {
    return await this.#repository.delete_account(body);
  }

  async recover_password_set_password(body) {
    return await this.#repository.recover_password_set_password(body);
  }

  async temporary_code(body) {
    return await this.#repository.get_temporary_code(body);
  }

  async get_auth_by_temporary_code(body) {
    const { status, response } = await this.#repository.check_temporary_code(
      body
    );

    if (response.user) {
      response.user = this.serialize({
        by: "user",
        input: response.user,
      });
    }

    return { status, response };
  }

  async update_profile(body) {
    const { status, response } = await this.#repository.update_profile(body);

    if (response.user) {
      response.user = this.serialize({
        by: "user",
        input: response.user,
      });
    }

    return { status, response };
  }
  async update_utm_variables(body) {
    return await this.#repository.update_utm_variables(body);
  }

  serialize({ by, input = {} }) {
    switch (by) {
      case "user":
        input.avatar = `${API}${input.avatar}`;
        break;
    }

    return input;
  }
}
