export default function ({ commit, state }, info = {}) {
  const player = state.player.instance;

  let progress = state.player.info.progress;
  let seconds_played = state.player.info.seconds_played;
  let duration = state.player.info.duration;
  let is_playing = false;

  if (player.playing()) {
    progress = parseInt((player.seek() / player.duration()) * 100);
    seconds_played = parseInt(player.seek());
    is_playing = true;

    if (isNaN(progress)) {
      progress = 0;
    }
  }

  duration = parseInt(player.duration());

  commit("SET_PLAYER_INFO", {
    progress,
    seconds_played,
    duration,
    is_playing,
    ...info,
  });
}
