import Repository from "../repositories/ticket";

export default class {
  #repository = new Repository();

  async tickets(body = {}) {
    return await this.#repository.tickets(body);
  }

  async remove(body = {}) {
    return await this.#repository.remove(body);
  }

  async store(body = {}) {
    return await this.#repository.store(body);
  }

  async channels(body = {}) {
    return await this.#repository.channels(body);
  }
  
  async pending_to_be_read(body = {}) {
    return await this.#repository.pending_to_be_read(body);
  }
}
