import Request from "../helpers/request";

export default class {
  #router = null;
  #request = new Request();

  async save_notification_push_token({ body }) {
    return await this.#request.post({
      path: "/api/notification/push/save_token",
      body,
    });
  }

  inject({ router }) {
    this.#router = router ? { ...this.#router, ...router } : this.#router;

    // Injections
    if (this.#router) {
      this.#request.inject({ router: this.#router });
    }
  }
}
