import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import store from "./helpers/store.js";
import login from "./helpers/login.js";

export default async function () {
  const anonymous_user_id = localStorage.getItem("anonymous_user_id");
  let temporal_id = localStorage.getItem("temporal_id");
  let google_response = {};

  // Google login
  this.login_with_google_loading = true;
  this.forms.register.inputs.email.value = "";
  this.forms.register.inputs.password.value = "";
  this.forms.register.inputs.name.value = "";

  if (!this.i_have_18) {
    this.alert.error(
      "Confirma que eres mayor de edad y que has leído y aceptado los términos y condiciones de uso."
    );
    this.login_with_google_loading = false;
    return;
  }

  if (this.loading) {
    this.login_with_google_loading = false;
    return;
  }

  try {
    google_response = await GoogleAuth.signIn();
  } catch (error) {
    if (error.error == "popup_closed_by_user" || error.code == "12501") {
      this.with_google_loading = false;
      return;
    }

    console.error(error);
    this.sentry_track_error(error);
    this.login_with_google_loading = false;
    this.mode = "email";
    this.alert.info(
      "Tenemos un problema para procesar tu solicitud, si este problema persiste contáctanos."
    );
    return;
  }

  const imageUrl = google_response.imageUrl;
  const name = google_response.name;
  const email = google_response.email;
  const id = google_response.id;

  // Storing
  const response = await store({
    vm: this,
    form: this.forms.register,

    email,
    name,
    google_id: id,
    google_image: imageUrl,
    temporal_id,

    anonymous_user_id,
    save_on_sendpulse: 1,
    autologin: 1,
  });

  this.login_with_google_loading = false;

  if (response) {
    login({
      vm: this,
      user: response.user,
      authorization: response.authorization,
    });

    this.$router.push({
      name: "index",
      query: {
        tab: "index",
        anonymous_user: anonymous_user_id ? 1 : 0,
      },
    });

    this.ab({
      vm: this,
      rt: "register",
      fn: "onregister",
    });
  }
}
